<div>
  <div class="example-container ">
      <mat-table mat-table [dataSource]='content' class="mat-elevation-z8">
          <ng-container matColumnDef="type">
              <mat-header-cell *matHeaderCellDef>{{'lm-journey-details.sections.lm-journey-timeline.cells.req_ope' | translate}}</mat-header-cell>
              <mat-cell *matCellDef="let element">{{element.type}}
                </mat-cell>
          </ng-container>

          <ng-container matColumnDef="initialStatus">
            <mat-header-cell *matHeaderCellDef>{{'lm-journey-details.sections.lm-journey-timeline.cells.initial_status' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <div *ngIf="element.type==='REQ' && element.endStatus; else operationEndDiv" style="border-radius: 5px; padding: 5px;" 
              [ngStyle]="{'color': element.requestStatusEnd.property.text_color, 
              'background': element.requestStatusEnd.property.background_color + '20', 
              'border': '1px solid' + element.requestStatusEnd.property.background_color}">
                {{element.requestStatusEnd.description}}
              </div>
              <ng-template #operationEndDiv>
                <div *ngIf="element.endStatus" style="border-radius: 5px; padding: 5px;" 
                [ngStyle]="{'color': element.operationStatusEnd.propertyStatus.text_color, 
                'background': element.operationStatusEnd.propertyStatus.background_color + '20', 
                'border': '1px solid' + element.operationStatusEnd.propertyStatus.background_color}">
                  {{element.operationStatusEnd.description}}
                </div>
              </ng-template>
            </mat-cell>
        </ng-container>

        <ng-container class="spacing" matColumnDef="endStatus">
            <mat-header-cell *matHeaderCellDef>{{'lm-journey-details.sections.lm-journey-timeline.cells.final_status' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <div *ngIf="element.type==='REQ'; else operationStartDiv" style="border-radius: 5px; padding: 5px;" 
              [ngStyle]="{'color': element.requestStatusInitial.property.text_color, 
              'background': element.requestStatusInitial.property.background_color + '20', 
              'border': '1px solid' + element.requestStatusInitial.property.background_color}">
                {{element.requestStatusInitial.description}}
              </div>
              <ng-template #operationStartDiv>
                <div *ngIf="element.initialStatus" style="border-radius: 5px; padding: 5px;" 
                [ngStyle]="{'color': element.operationStatusInitial.propertyStatus.text_color, 
                'background': element.operationStatusInitial.propertyStatus.background_color + '20', 
                'border': '1px solid' + element.operationStatusInitial.propertyStatus.background_color}">
                  {{element.operationStatusInitial.description}}
                </div>
              </ng-template>
            </mat-cell>
        </ng-container>
        
        <ng-container class="spacing" matColumnDef="statusStartDate">
            <mat-header-cell *matHeaderCellDef>{{'lm-journey-details.sections.lm-journey-timeline.cells.start_date' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.statusStartDate | date: "yyyy-MM-dd HH:mm:ss"}}<br />
                {{element.requestId}} </mat-cell>
        </ng-container>
        <ng-container class="spacing" matColumnDef="statusEndDate">
            <mat-header-cell *matHeaderCellDef>{{'lm-journey-details.sections.lm-journey-timeline.cells.end_date' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.statusEndDate | date: "yyyy-MM-dd HH:mm:ss"}}<br />
                {{element.requestId}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="responsible">
            <mat-header-cell *matHeaderCellDef>{{'lm-journey-details.sections.lm-journey-timeline.cells.changed_role' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.responsible?.role}} - {{element.responsible?.email}}
              </mat-cell>
        </ng-container>
        <ng-container matColumnDef="comments">
            <mat-header-cell *matHeaderCellDef>{{'lm-journey-details.sections.lm-journey-timeline.cells.comments' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.comments}}
              </mat-cell>
        </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
      <mat-paginator [length]="totalElements" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25, 100]"
          (page)="getPaginatorData($event)">
      </mat-paginator>
  </div>
</div>
