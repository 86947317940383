import { ProfessionalBasicData, ProfessionalStatus, StatusType } from './../models/lm-professional.model';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Utilities } from 'src/app/shared/utilities/utilities';
import { ProfessionalCampaigns } from '../../lm-transfers/models/lm-transfers.model';
import { TransferService } from '../../lm-transfers/services/transfers.service';
import { ConfirmDialogComponent } from '../../modals/confirm-dialog/confirm-dialog.component';
import { IFiltersData, IRatingAverage, IRatingData, IRatingsFilters } from '../models/lm-professional.model';
import { ProfessionalService } from '../services/professional.service';
import { MatTabGroup } from '@angular/material/tabs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-professional-details',
  templateUrl: './professional-details.component.html',
  styleUrls: ['./professional-details.component.scss']
})
export class ProfessionalDetailsComponent implements OnInit {
  @ViewChild('tabGroup') tab: MatTabGroup;

  professionalId;
  loading: boolean = false;
  data: ProfessionalBasicData
  campaigns: ProfessionalCampaigns[] = [];
  displayedColumns: string[] = ['nombre', 'fill', 'actions'];
  dialogRef: MatDialogRef<ConfirmDialogComponent>;
  professionalName: string = '';
  isUserLeroy: boolean;
  isEnabled: boolean = false;
  filtersData: IFiltersData;
  ratingData: IRatingData;
  rating: IRatingAverage;
  ratingFilters: IRatingsFilters;
  hasRating: boolean = false;
  tabStatusHistory: number = 5;
  tabProfessionalDocument = 6;
  professionalStatusResponse: ProfessionalStatus[];
  currentStatus: StatusType;
  editPaycometDocuments: boolean;

  constructor(
    private _route: ActivatedRoute,
    private _professionalService: ProfessionalService,
    private _transferService: TransferService,
    public dialog: MatDialog,
    private _translateService: TranslateService
  ) { }

  ngOnInit() {
    this.isUserLeroy = Utilities.isUserLeroy();
    this.professionalId = this._route.snapshot.paramMap.get("professionalId");
    this.getProfessionalById(this.professionalId);
    this.getProfessionalHistoricalRatings(this.professionalId);
  }

  /**
   * Retrieve professional information
   * @param professionalId      - ProfessionalId
   */
  getProfessionalById(professionalId: number) {
    this.loading = true
    this._professionalService.getProfessionalById(professionalId).then(res => {
      this.data = res.data;
      this.filtersData = {
        professionalId: professionalId,
        professionalEmail: res['data']['email'],
        professionalObject: res['data']
      };
      this.professionalName = this.data['name'] + ' ' + this.data['lastName'];
      let professionalStatus = this.data['professionalStatus']['code'];
      this.loadStatusType(professionalStatus)
      this.loading = false;
      if (this.data['professionalCampaigns'] != null) {
        this.getCampaigns();
      }
      this.getRatingData(this.data);
    }).catch(err => {
      this.loading = false
    })
  }

  /**
   * Load status type
   * @param statusCode      StatusCode
   */
  loadStatusType(statusCode: string) {
    this._professionalService.getProfessionalStatusAllowedByCode(statusCode)
      .subscribe(res => {
        this.professionalStatusResponse = res['data'];
        this.currentStatus = this.professionalStatusResponse[0].professionalStatusStartRest;
      })
  }

  getCampaigns() {
    this._transferService.getAllCampaigns().subscribe(res => {
      this.campaigns = res.data.map(element => {
        return {
          ...element,
          active: false,
          enabled: false,
          isHogamiDisabled: false
        };
      });
      this.campaigns.push({
        id: 0,
        descriptionCampaign: '',
        codeCampaign: 'WEB',
        active: true,
        enabled: true,
        isHogamiDisabled: false
      });
      this.campaigns.sort((a, b) => a.id - b.id);
      this.matchCampaignsWithProfessionals();
    });
  }

  matchCampaignsWithProfessionals() {
    this.campaigns.forEach(element => {
      if (!element.enabled) {
        if (this.data['professionalCampaigns'] != null) {
          this.data['professionalCampaigns'].forEach(dat => {
            if (dat['campaignId'] === element.id) {
              element.active = true;
              element.enabled = true;
            }
          });
        }
      }
    });
  }

  changeActive(element: any) {
    if (this.isUserLeroy) {
      if (element.id != 0) {
        let payload = {
          campaignCode: element['codeCampaign'],
          professionalIds: [this.professionalId]
        }
        if (element.active) {
          this.confirmDialogDelete(payload);
        } else {
          this.confirmDialogRegister(payload);
        }
      }
    }
  }

  confirmDialogDelete(payload: any) {
    const dialogMessage = this._translateService.instant('lm-professional.professional-details.dialog_messages');
    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '600px',
      height: 'auto',
      disableClose: true,
      data: {
        value: dialogMessage['delete_campaign'] + payload['campaignCode'] + dialogMessage['professional'] + this.professionalName
      }
    });

    this.dialogRef.afterClosed().subscribe(result => {
      console.debug('The dialog was closed');
    });

    this.dialogRef.componentInstance.submitClicked.subscribe(result => {
      if (result === 'submit') {
        this._professionalService.deleteProfessionalCampaig(payload).subscribe(res => {
          this.getProfessionalById(this.professionalId);
          this.dialogRef.close();
        });
      } else {
        this.getProfessionalById(this.professionalId);
        this.dialogRef.close();
      }
    });
  }

  confirmDialogRegister(payload: any) {
    const dialogMessage = this._translateService.instant('lm-professional.professional-details.dialog_messages');
    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '600px',
      height: 'auto',
      disableClose: true,
      data: {
        value: dialogMessage['register_campaign'] + payload['campaignCode'] + dialogMessage['professional'] + this.professionalName
      }
    });

    this.dialogRef.afterClosed().subscribe(result => {
      console.debug('The dialog was closed');
    });

    this.dialogRef.componentInstance.submitClicked.subscribe(result => {
      if (result === 'submit') {
        this._professionalService.addProfessionalCampaig(payload).subscribe(res => {
          this.getProfessionalById(this.professionalId);
          this.dialogRef.close();
        });
      } else {
        this.getProfessionalById(this.professionalId);
        this.dialogRef.close();
      }
    });
  }

  getRatingData(data: any) {
    this.ratingData = {
      profesionalId: data.id,
      professionaName: data.name,
      professionalLastName: data.lastName,
      totalRating: 3,
      avatar: data.avatar
    }
  }


  getProfessionalHistoricalRatings(profesionalId: number) {

    this.ratingFilters = {
      professionalIds: [profesionalId],
      offeredServiceId: 0,
      offeredServiceCode: 0
    }
    this._professionalService.getProfessionalHistoricalRatings(this.ratingFilters).subscribe({
      next: this.handleResponse.bind(this),
      error: this.handleError.bind(this)
    });
  }

  handleResponse(res: any): void {
    if (res.data.length > 0 && res.data != null && res.data != undefined && res.data[0].accumulatedAverage != null && res.data[0].accumulatedAverage) {
      this.hasRating = true;
      this.rating = res.data[0].accumulatedAverage;
    }
  }

  handleError(error: any): void {
    this.hasRating = false;
  }

  statusHistory() {
    this.tab.selectedIndex = this.tabStatusHistory;
  }

  openProfessionalDocuments($event) {
    if ($event) {
      this.editPaycometDocuments = true;
      this.tab.selectedIndex = this.tabProfessionalDocument;
    }
  }

  closeProfessionalDocuments($event) {
    if (!$event) {
      this.editPaycometDocuments = false;
      this.tab.selectedIndex = 0;
    }
  }

}
