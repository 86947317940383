<div class="content">
  <mat-card class="small-card"  *ngIf="isUserLeroy">
    <mat-card-header>
      <mat-card-title>{{'lm-transfer-sepa.title' | translate}}</mat-card-title>
      <mat-card-subtitle>{{'lm-transfer-sepa.subTitle' | translate}}</mat-card-subtitle>
    </mat-card-header>
    <form (ngSubmit)="transferSepa()" [formGroup]="form" class="form">
    <mat-card-content>
      <mat-form-field class="full-width">
        <mat-label>{{'lm-transfer-sepa.placeholders.total_operations_cost' | translate}}</mat-label>
        <input matInput formControlName="totalCost" name="totalCost" placeholder="{{'lm-transfer-sepa.placeholders.total_operations_cost' | translate}}" type="text"
          (input)="changeValue($event,totalCost)" (blur)="onBlur($event,totalCost)" [value]="totalCost.value" onPaste="return false"/>
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>{{'lm-transfer-sepa.placeholders.total_paid' | translate}}</mat-label>
        <input matInput formControlName="totalPaid" name="totalPaid" placeholder="{{'lm-transfer-sepa.placeholders.total_paid' | translate}}" type="text"
        (input)="changeValue($event,totalPaid)" (blur)="onBlur($event,totalPaid)" [value]="totalPaid.value" onPaste="return false"/>
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>{{'lm-transfer-sepa.placeholders.sepa_amount' | translate}}</mat-label>
        <input matInput formControlName="amountSEPA" name="amountSEPA" placeholder="{{'lm-transfer-sepa.placeholders.sepa_amount' | translate}}" type="text" maxlength="10"
          (input)="changeValue($event,amountSEPA)" (blur)="onBlur($event,amountSEPA)" [value]="amountSEPA.value" onPaste="return false"/>
      </mat-form-field>
      <div *ngIf="submitted && f.amountSEPA.errors" class="redTextLeft">
        <div *ngIf="f.amountSEPA.errors.required">{{'lm-transfer-sepa.messages_error.sepa_amount_required' | translate}}</div>
      </div>

      <mat-form-field class="full-width">
        <mat-label>{{'lm-transfer-sepa.placeholders.operations' | translate}}</mat-label>
        <input matInput formControlName="operationNumber" name="operationNumber" placeholder="{{'lm-transfer-sepa.placeholders.operations' | translate}}" type="text"
          (keypress)="onChangeOperationNumber($event)" (blur)="onBlurOperation($event, operationNumber)"/>
      </mat-form-field>
      <div *ngIf="submitted && f.operationNumber.errors" class="redTextLeft">
        <div *ngIf="f.operationNumber.errors.required">{{'lm-transfer-sepa.messages_error.operation_field_required' | translate}}</div>
      </div>

      <mat-form-field class="full-width">
        <mat-label>{{'lm-transfer-sepa.placeholders.email' | translate}}</mat-label>
        <input matInput formControlName="professionalEmail" name="professionalEmail" placeholder="{{'lm-transfer-sepa.placeholders.email' | translate}}" type="text" />
      </mat-form-field>
      <div *ngIf="submitted && f.professionalEmail.errors" class="redTextLeft">
        <div *ngIf="f.professionalEmail.errors.required">{{'lm-transfer-sepa.messages_error.email_required' | translate}}</div>
        <div *ngIf="f.professionalEmail.errors.email">{{'lm-transfer-sepa.messages_error.email_no_valid' | translate}}</div>
      </div>

      <mat-form-field class="full-width">
        <mat-label>{{'lm-transfer-sepa.placeholders.concept' | translate}}</mat-label>
        <input matInput formControlName="operationConcept" name="operationConcept" placeholder="{{'lm-transfer-sepa.placeholders.concept' | translate}}" type="text" maxlength="130"/>
      </mat-form-field>
      <div *ngIf="submitted && f.operationConcept.errors" class="redTextLeft">
        <div *ngIf="f.operationConcept.errors.required">{{'lm-transfer-sepa.messages_error.concept_field_required' | translate}}</div>
      </div>
      
      <div class="m-t-b">
        <mat-checkbox class="example-margin" formControlName="completed">{{'lm-transfer-sepa.checkbox.update_operation_status_to_completed' | translate}}</mat-checkbox>
      </div>
      <div class="m-t-b"  class="full-width">
        <mat-checkbox class="example-margin" formControlName="endTime">{{'lm-transfer-sepa.checkbox.update_end_time_to_current_date' | translate}}</mat-checkbox>
      </div>

    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button type="submit" color="primary" class="button_submit" [disabled]="isDisabled"><mat-icon>payments</mat-icon> {{'lm-transfer-sepa.button_submit' | translate}}</button>
      <div *ngIf="isError" class="redText">{{ message }}</div>
    </mat-card-actions>
    </form>
  </mat-card>



  <mat-card class="small-card" *ngIf="!isUserLeroy">
    <div class="divPermissionDenied">
      <mat-card-header>
        <div></div>
        <mat-card-title>{{'lm-transfer-sepa.title' | translate}}</mat-card-title>
        <mat-card-subtitle>{{'lm-transfer-sepa.subTitle' | translate}}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div class="divLeroy" >
          <div class="divCenter">
            <h2>{{'lm-transfer-sepa.message_error_no_authorized_perform_action' | translate}}</h2>
          </div>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button type="button" (click)="goBack()" color="primary" class="redText"> {{'lm-transfer-sepa.button_accepter' | translate}} </button>
      </mat-card-actions>
    </div>
  </mat-card>
</div>