import { Observable } from 'rxjs/internal/Observable';
import { distinctUntilChanged } from 'rxjs/operators';
import { ElementRef } from '@angular/core';
import { isEmpty as _isEmpty, isEqual as _isEqual } from 'lodash';
import { get as _get } from 'lodash';
import * as Base64 from './base64';
import jwt_decode from 'jwt-decode';

export class Utilities {

	/**
	 *  Patterns definition
	 */
	static readonly patterns = {
		// tslint:disable-next-line:max-line-length
		email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
		phone: /^[0-9]{9}$/,
		format: /^[a-zA-ZáñÁéÉíÍóÓúÚüÜs ]*$/
	};

	static DNI_REGEX = /^(\d{8})([A-Z])$/;
	static CIF_REGEX = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/;
	static NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/;
	
	static ONLY_POSITIVE_NUMBERS_AND_DECIMALS = /^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/;

	/**
	 *  Method that verify if data passed is empty
	 * @param data Any data for check if is emty
	 */
	static isEmpty( data: any ) {
		if ( typeof data === 'number' ) {
			return false;
		}
		return _isEmpty( data );
	}

	/**
	 * Method that check if the email match whit the right pattern
	 * @param email String to check
	 */
	static isValidEmail( email: string ): boolean {
		return Utilities.patterns.email.test( email.toLowerCase() );
	}

	/**
	 * Method that check if the phone match whit the right pattern
	 * @param phone String to check
	 */
	static isValidPhone( phone: string ): boolean {
		return Utilities.patterns.phone.test( phone.toLowerCase() );
	}

	/**
	 * Method that prevent to emmit duplicated events
	 * @param observer Observable that need to apply filter
	 */
	static preventDuplicateEvents<T>( observer: Observable<T> ): Observable<T> {
		return observer.pipe( distinctUntilChanged( ( oldValue, newValue ) => _isEqual( oldValue, newValue ) ) );
	}

	/**
	 * Method that do scroll into the view
	 * @param element Target element
	 * @param smooth Enable smooth movement
	 */
	static doScroll( element: ElementRef, smooth: boolean = false ): void {
		const htmlElement = element.nativeElement as HTMLElement;
		htmlElement.scrollIntoView( {
			block: 'start',
			...( smooth ? { behavior: 'smooth' } : {} )
		} );
	}

	/**
	 * Static method to convert string into base64
	 * @param phrase String to convert
	 */
	static encodeBase64( phrase: string ): string {
		return Base64.encode( phrase );
	}

	/**
   * Format Date Start / End
   */
	static getDateActual() {
		const date = new Date();
		const day = this.toTwodigit(date.getDate());
		const month = this.toTwodigit(date.getMonth() + 1);
		const year = date.getFullYear();
		const hh = this.toTwodigit(date.getHours());
		const mm = this.toTwodigit(date.getMinutes());
		const ss = this.toTwodigit(date.getSeconds());

		return `${year}-${month}-${day}_${hh}-${mm}-${ss}`;
	}

	static getDate(elem) {
		const date = new Date(elem);
		return date.toISOString().replace("T", " ").substring(0, 19);
	}

	static getDateEnd(elem) {
		const date = new Date(elem);
		const day = this.toTwodigit(date.getDate());
		const month = this.toTwodigit(date.getMonth()+1);
		const year = date.getFullYear();
		const hh = this.toTwodigit(23);
		const mm = this.toTwodigit(59);
		const ss = this.toTwodigit(59);
		return new Date(`${year}-${month}-${day} ${hh}:${mm}:${ss}`).toISOString().replace("T", " ").substring(0, 19);
	}

	static toTwodigit(n: number) {
		return ('00' + n).slice(-2);
	}

	static base64toBlob(base64Data, contentType) {
		contentType = contentType || '';
		let sliceSize = 1024;
		let byteCharacters = atob(base64Data);
		let bytesLength = byteCharacters.length;
		let slicesCount = Math.ceil(bytesLength / sliceSize);
		let byteArrays = new Array(slicesCount);
		for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
		let begin = sliceIndex * sliceSize;
		let end = Math.min(begin + sliceSize, bytesLength);

		let bytes = new Array(end - begin);
		for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
			bytes[i] = byteCharacters[offset].charCodeAt(0);
		}
		byteArrays[sliceIndex] = new Uint8Array(bytes);
		}
		return new Blob(byteArrays, { type: contentType });
	}

	static getTimeZone(){
		return Intl.DateTimeFormat().resolvedOptions().timeZone;
	}

    static isLoggedIn() {
		if ( !!!localStorage.getItem('token') ) {
			return false;
		} else {
			return true;
		}
    }

	static isUserHogami(): boolean {
		let token = localStorage.getItem('token');
		let tokendecode = this.getDecodedAccessToken(token);
		let username = tokendecode.user_name;
		var isUserHogami: boolean = false;
		if(username.includes('@leroymerlin.es') || username.includes('@everis')) {
			isUserHogami = true;
		}
		return isUserHogami;
	}

	static isUserLeroy(): boolean {
		let token = localStorage.getItem('token');
		let tokendecode = this.getDecodedAccessToken(token);
		let username = tokendecode.user_name;
		var isUserHogami: boolean = false;
		if(username.includes('@leroymerlin.es')) {
			isUserHogami = true;
		}
		return isUserHogami;
	}

	static getDecodedAccessToken(token: string): any {
		try{
			return jwt_decode(token);
		}
		catch(Error){
			return null;
		}
	  }

	/* function for grouping arrays */
    static groupBy(a, keyFunction) {
        const groups = {};
        a.forEach(function(el) {
          const key = keyFunction(el);
          if (key in groups === false) {
            groups[key] = [];
          }
          groups[key].push(el);
        });
        return groups;
	}
	
	static decodeTextBase64(base64Text: string): any {
		const decodedString = window.atob(base64Text);
		return decodedString.replace(/\\n/g, '\n');
	} 

}
