import { Observable } from 'rxjs/internal/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class LmOfferedServiceService {

	constructor(private _http: HttpClient) { }

	getServices(): Promise<any> {
		const head = new HttpHeaders(
			{}
		);
		return this._http.get(`${environment.apiHosts}/backoffice-server/api/v1/services/servicesDetails`,
			{ headers: head }).toPromise();
	}

	getServiceByLandingPage(landingPage: string): Promise<any> {
		const head = new HttpHeaders(
			{}
		);
		return this._http.get(`${environment.apiHosts}/backoffice-server/api/v1/services/servicesDetails/${landingPage}`,
			{ headers: head }).toPromise();
	}

	editUpdateVisibleContent(data: any = {}): Promise<any> {
		const head = new HttpHeaders(
			{}
		);
		return this._http.post(`${environment.apiHosts}/backoffice-server/api/v1/services/visible-content`,
			data,
			{ headers: head }).toPromise();
	}

	deleteVisibleContent(offeredServiceId: number, provinceId: number) {
		const head = new HttpHeaders(
			{}
		);
		return this._http.delete(`${environment.apiHosts}/backoffice-server/api/v1/services/visible-content/${offeredServiceId}/${provinceId}`,
			{ headers: head }).toPromise();
	}

	retrieveOfferedServiceTypes(): Promise<any> {
		const head = new HttpHeaders(
			{}
		);
		return this._http.get(`${environment.apiHosts}/backoffice-server/api/v1/services/types`,
			{ headers: head }).toPromise();
	}

	retrieveOffereServiceByLevel(level: number): Promise<any> {
		const head = new HttpHeaders(
			{}
		);
		return this._http.get(`${environment.apiHosts}/backoffice-server/api/v1/services/level?levelList=${level}`,
			{ headers: head }).toPromise();
	}

	retrieveGroupOfferedServices(filter: any): Promise<any> {
		const head = new HttpHeaders(
			{}
		);
		return this._http.post(`${environment.apiHosts}/backoffice-server/api/v1/services`,
			filter,
			{ headers: head }).toPromise();
	}

	updateOrderOfferedService(body: any): Observable<any> {
		const head = new HttpHeaders({});
		return this._http.put<any>(`${environment.apiHosts}/backoffice-server/api/v1/services/order`,
			body,
			{ headers: head },
		);
	}
}
