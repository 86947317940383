import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { TransferService } from 'src/app/pages/lm-transfers/services/transfers.service';
import { ICoverageData, IFilters, IServiceCoverageFilters } from '../../models/lm-professional.model';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-lm-professional-service-coverage-details',
  templateUrl: './lm-professional-service-coverage-details.component.html',
  styleUrls: ['./lm-professional-service-coverage-details.component.scss']
})
export class LmProfessionalServiceCoverageDetailsComponent implements OnInit, OnDestroy {
  _translationSubscription: Subscription;
  _messages: { [key: string]: string };
  data: any = []
  page: number = 0;
  totalElements: number = 0;
  pageSize: number = 10;
  loading: boolean = false;
  filterType: string = "professionalServiceCoverageDetails";
  loadExcel: boolean = false;
  filters: IFilters = {};
  serviceCoverageFilters: IServiceCoverageFilters;
  buttonShowMore: boolean;
  stringShowMore: string;
  displayedColumns: string[] = ['country', 'comunity', 'selectedProvince', 'zone', 'zipCode', 'firstPrice', 'additionalPrice', 'tax', 'currency', 'actions'];
  @Input() coverageData: ICoverageData;

  constructor(private _transferService: TransferService, public dialog: MatDialog, private _translateService: TranslateService) {
    this.buttonShowMore = false;
    this.stringShowMore = 'Ver todos';
    this._translationSubscription = this._translateService
      .get('lm-professional.lm-professional-service-coverage-details.logic')
      .subscribe((res: { [key: string]: string }) => {
        this._messages = res;
      });
    this.stringShowMore = this._messages['see_all'];
  }
  ngOnDestroy(): void {
    this._translationSubscription.unsubscribe();
  }
  ngOnInit() {
    if (this.filters !== null && this.filters.isFilterBuilt == true) {
      this.getServiceCoverageDetails();
    }
  }

  getServiceCoverageDetails() {
    this.serviceCoverageFilters = this.filters.serviceCoverageFilters;
    this.loading = true;
    this.data = []
    this.totalElements = 0;
    this._transferService.getServiceCoverageDetails(this.coverageData, this.page, this.pageSize, this.serviceCoverageFilters).subscribe({
      next: (response) => {
        this.data = response.data;
        if (this.data.totalElements === 0) {
          alert(this._messages['no_result_found']);
          this.loading = false;
          this.data = []
        } else {
          this.loading = false;
          this.data = response.data.content;
          this.totalElements = response.data.totalElements
        }
      },
      error: () => {
        alert(this._messages['no_result_found']);
        this.loading = false;
        this.data = []
      }
    });

  }

  getServiceCoverageFilters() {
    this.getServiceCoverageDetails();
  }

  getFiltersToServiceCoverage(filtersApply) {
    this.filters = filtersApply;
    this.page = 0;
    this.getServiceCoverageDetails();
  }

  getPaginatorData(event) {
    this.page = event['pageIndex'];
    this.pageSize = event['pageSize'];
    this.getServiceCoverageDetails();
  }


  isEmpty(str) {
    return (!str || str.length === 0);
  }

  showMore() {
    this.buttonShowMore ? this.stringShowMore = this._messages['see_all'] : this.stringShowMore = this._messages['hide_all'];
    this.buttonShowMore = !this.buttonShowMore;
  }


}
