import {
  Component,
  Input,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { RequestService } from "../../../../pages/lm-transfers/services/request.service";
import {
  IRejectionMotives,
  IRequestRequirement,
  IRequestRequirementPropertyRest,
  IRequestStatusAllowed,
  IRequestStatusEnd,
  IRequestStatusRequirement,
  IStatusChange,
} from "../../models/lm-journey.model";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-lm-journey-status',
  templateUrl: './lm-journey-status.component.html',
  styleUrls: ['./lm-journey-status.component.scss', '../../../utils/dropdown.scss', '../../../utils/form-search.scss'],
})
export class LmJourneyStatusComponent implements OnInit, OnDestroy {
  @Input() requestId: number;
  @Input() status: string;

  @Input() statusDataData: boolean;
  @Output() statusDataDataChange = new EventEmitter<boolean>();
  @Output() statusDataDataEvent = new EventEmitter<boolean>();

  form: FormGroup;
  requestStatusAllowed: IRequestStatusAllowed;
  requestStatusEnds: IRequestStatusEnd[];
  requestStatusRequirement: IRequestStatusRequirement;
  requestRequirements: IRequestRequirement[];
  requirementProperties: IRequestRequirementPropertyRest[] = [];
  rejectionMotives: IRejectionMotives[];
  statusSelected: string;
  motiveDescription: string;
  loading: boolean;

  constructor(
    private requestService: RequestService,
    private _snackBar: MatSnackBar,
    private _translateService: TranslateService
  ) {}

  ngOnDestroy(): void {}

  ngOnInit(): void {
    this.createForm();
    this.retrieveStatusAllowed();
  }

  /**
   * Create form
   */
  createForm(): void {
    this.form = new FormGroup({
      newStatus: new FormControl("", [Validators.required]),
    });
  }

  /**
   * Submit information to cancelate
   */
  submitForm(): void {
    if (this.form.valid) {
    this.loading = true;
      const statusChange: IStatusChange = {
        requestId: this.requestId,
        newStatus: this.statusSelected,
        comment: this.form.controls["clientMessage"].value,
        requestStatusChangePropertyRest: {
          rejectionMotiveId: this.form.controls["rejectionMotiveId"].value,
          rejectionMessage: this.motiveDescription,
        },
      };
      this.requestService.statusChange(statusChange).subscribe({
        next: () => {
          this.loading = false;
          this.closeTab();
          this._snackBar.open(this._translateService.instant('lm-journey-details.sections.lm-journey-status.form.statusChanged'), 'OK', { duration: 1500 });
        },
        error: () => {
          this.loading = false;
          console.error("Error to register motive cancelation");
        },
      });
    } else {
      this._snackBar.open(this._translateService.instant('lm-journey-details.sections.lm-journey-status.form.formEmpty'), 'OK', { duration: 1500 });
    }
  }

  /**
   * Retrieve Status Allowed
   */
  retrieveStatusAllowed(): void {
    this.requestService
      .retrieveRequestStatus(this.status)
      .then((response) => {
        const { data } = response;
        if (data) {
          this.requestStatusAllowed = data;
          this.requestStatusEnds = data.requestStatusEndRest;
        } else {
          console.error("Invalid response structure:", response);
        }
      })
      .catch((error) => {
        console.error("Error retrieving request status:", error);
      });
  }

  /**
   * Retrieve Rejection Motives
   */
  retrieveRejectionMotives(): void {
    this.requestService
      .retrieveRejectionsMotive("VIEW_BO")
      .then((response) => {
        this.rejectionMotives = response.data;
        this.form.addControl(
          "rejectionMotiveId",
          new FormControl("", [Validators.required])
        );
      })
      .catch((error) => {
        console.error("Error retrieving rejection motives", error);
      });
  }

  /**
   * Select status to change
   * @param event 
   */
  selectStatus(event): void {
    this.requirementProperties = [];
    this.statusSelected = event;
    this.requestService
      .retrieveRequirementByStatus(this.status, event)
      .then((response) => {
        const { data } = response;
        this.requestStatusRequirement = data;
        this.requestRequirements = data.requestRequirements;
        this.requestRequirements.forEach((item) => {
          item.requestRequirementPropertyRests.forEach((property) => {
            this.requirementProperties.push(property);
          });
          if (item.code === "REJECTION_MOTIVE") {
            this.retrieveRejectionMotives();
          }
        });

        this.requirementProperties.forEach((req) => {
          switch (req.typeInput) {
            case "textArea":
              if (!this.form.get(req.nameField)) {
                this.form.addControl(
                  req.nameField,
                  new FormControl(null, [
                    Validators.required,
                    Validators.maxLength(req.maximumValue),
                    Validators.minLength(req.minimumValue),
                  ])
                );
              }
              break;
            case "radio":
              if (!this.form.get(req.nameField)) {
                this.form.addControl(
                  req.nameField,
                  new FormControl(null, [Validators.required])
                );
              }
              break;
          }
          this.form.updateValueAndValidity();
          this.formIsValid();
        });
      })
      .catch((error) => {
        console.error("Error retrieving requeriments by status", error);
      });
  }

  /**
   * Select motive and save in variable
   * @param event 
   */
  selectMotive(event): void {
    this.motiveDescription = event;
  }

  /**
   * Close current tab
   */
  closeTab() {
    this.statusDataData = false;
    this.statusDataDataEvent.emit(false);
  }

  /**
   * Check if form is valid
   * @returns 
   */
  formIsValid(): boolean {
    this.form.updateValueAndValidity();
    return this.form.valid;
  }

  /**
   * Convert number to string
   * @param number 
   * @returns 
   */
  toString(number: number): string {
    return number.toString();
  }
}
