import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { RequestService } from '../../lm-transfers/services/request.service';
import { MatTabGroup } from '@angular/material/tabs';
import { IWorkDetail } from '../models/lm-journey.model';

@Component({
  selector: "app-lm-journey-details",
  templateUrl: "./lm-journey-details.component.html",
  styleUrls: ["./lm-journey-details.component.scss"],
})
export class LmJourneyDetailsComponent implements OnInit, OnDestroy {
  @ViewChild("tabGroup") tab: MatTabGroup;

  statusDataData: boolean;

  _translationSubscription: Subscription;

  editRatingOperation: boolean;
  tabUpdateRatingOperation = 4;

  loading: boolean = false;
  data: IWorkDetail;
  id: any;
  status: string;
  operationId: any;
  rating: any;

  constructor(
    private _requestService: RequestService,
    private _route: ActivatedRoute
  ) { }

  ngOnDestroy(): void {
  }

  ngOnInit() {
    this.id = this._route.snapshot.paramMap.get("requestId");
    this.getMainInformation(this.id);
  }

  getMainInformation(id: number) {
    this._requestService
      .getMainInformation(id)
      .then((res) => {
        if (res.data) {
          this.data = res.data;
          this.status = this.data.requestStatusRest.name;
          this.operationId = this.data.operationId;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  openEditRating($event): void {
    if ($event != null) {
      this.rating = $event;
      this.editRatingOperation = true;
      this.tab.selectedIndex = this.tabUpdateRatingOperation;
    }
  }

  closeEditRating($event): void {
    if ($event == null) {
      this.editRatingOperation = false;
      this.tab.selectedIndex = 0;
    }
  }

  closeStatusDataData($event) {
    if (!$event) {
      this.statusDataData = false;
      this.tab.selectedIndex = 0;
    }
  }
}
