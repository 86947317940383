import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-sepa',
  templateUrl: './modal-sepa.component.html',
  styleUrls: ['./modal-sepa.component.scss']
})
export class ModalSepaComponent implements OnInit {

  operation: any;
  professional: string;
  professionalEmail: string;
  importe: string;
  concepto: string;
  inProgress: boolean = false;
  transferDiv: boolean = false;
  transferMsg: string;
  completed: boolean = false;
  endTime: boolean = false;

  @Output() submitClicked = new EventEmitter<any>();

  constructor(public dialogRef: MatDialogRef<ModalSepaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.operation = this.data['operation'];
    this.professional = this.operation['professionalDetails']['name'] + ' ' + this.operation['professionalDetails']['lastName'];
    this.professionalEmail = this.data['bodySepa']['emailProf'];
    this.importe = this.data['bodySepa']['amount'];
    this.concepto = this.data['bodySepa']['operationConcept'];
    this.inProgress = this.data['progress'];
    this.transferDiv = this.data['transferDiv'];
    this.transferMsg = this.data['transferMsg'];
    this.completed = this.data['bodySepa']['completed'];
    this.endTime = this.data['bodySepa']['endTime'];
  }

  dismiss() {
    this.inProgress = false;
    this.dialogRef.close();
  }

  submit() {
    this.inProgress = true;
    this.submitClicked.emit('submit');
  }

  goContinue() {
    this.submitClicked.emit('continue');
  }

  myf(d, f, m) {
    this.inProgress = d['progress'];
    this.transferDiv = f['transferDiv'];
    this.transferMsg = m['transferMsg'];
  }

}
