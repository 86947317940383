import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CouponVersion } from '../../models/lm-transfers.model';

@Component({
  selector: 'app-coupon-dialog',
  templateUrl: './coupon-dialog.component.html',
  styleUrls: ['./coupon-dialog.component.scss', '../../../utils/mat-table.scss']
})
export class CouponDialogComponent implements OnInit, OnDestroy {

  couponVersion: CouponVersion;
  couponType: string;
  couponScope: string;
  couponTarget: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any){}

  ngOnInit(): void {
    this.couponVersion = this.data.coupon;

    if(this.couponVersion.couponTypeDto && this.couponVersion.couponTypeDto.couponTypeDescriptions.length > 0) {
      this.couponType = this.couponVersion.couponTypeDto.couponTypeDescriptions[0].description;
    }

    if(this.couponVersion.couponScopeDto && this.couponVersion.couponScopeDto.couponScopeDescriptionDtos.length > 0) {
      this.couponScope = this.couponVersion.couponScopeDto.couponScopeDescriptionDtos[0].description;
    }

    if(this.couponVersion.couponTargetTypeDto && this.couponVersion.couponTargetTypeDto.couponTargetTypeDescriptionDtos.length > 0) {
      this.couponTarget = this.couponVersion.couponTargetTypeDto.couponTargetTypeDescriptionDtos[0].description;
    }
  }

  ngOnDestroy(): void {

  }
}