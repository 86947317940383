import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs';
import { environment } from "src/environments/environment";
import { Utilities } from 'src/app/shared/utilities/utilities';

@Injectable({
  providedIn: "root",
})
export class RequestService {
  private BASE_URL = environment.apiHosts;

  constructor(private _http: HttpClient) {}

  getRequests(
    page: number = 0,
    pageSize: number = 0,
    payload: any = {}
  ): Observable<any> {
    const head = new HttpHeaders({});
    return this._http.post<any>(
      `${this.BASE_URL}/backoffice-server/api/v1/requests?page=${page}&size=${pageSize}&sort=createDate,desc`,
      payload,
      { headers: head }
    );
  }

  exportRequestToExcel(payload: any): Observable<any> {
    const params = {
      timeZone: Utilities.getTimeZone(),
    };
    const head = new HttpHeaders({});
    return this._http.post(
      `${environment.apiHosts}/backoffice-server/api/v1/requests/excel`,
      payload,
      { headers: head, params }
    );
  }

  getRequestStatus(): Observable<any> {
    const head = new HttpHeaders({});
    return this._http.get(
      `${environment.apiHosts}/backoffice-server/api/v1/requests/status`,
      { headers: head }
    );
  }

  getSearchServices(): Observable<any> {
    const head = new HttpHeaders({
      "Accept-Language": "es-ES",
    });
    return this._http.get(
      `${environment.apiWebSearch}/search/v1/services?level=2&level=3`,
      { headers: head }
    );
  }

  getProvinces(): Observable<any> {
    const head = new HttpHeaders({});
    return this._http.get(`${environment.apiWebSearch}/search/v1/province`, {
      headers: head,
    });
  }

  //ChatMessages
  findAllMessagesByChatId(chatId: string): Observable<any> {
    const head = new HttpHeaders({});
    return this._http.get(
      `${environment.apiHosts}/backoffice-server/api/v1/chat/${chatId}`,
      { headers: head }
    );
  }

  getMainInformation(requestId: number): Promise<any> {
    const head = new HttpHeaders({});
    return this._http
      .get(
        `${environment.apiHosts}/backoffice-server/api/v1/journey-details/${requestId}`,
        { headers: head }
      )
      .toPromise();
  }

  getBasicData(requestId: number): Promise<any> {
    const head = new HttpHeaders({});
    return this._http
      .get(
        `${environment.apiHosts}/backoffice-server/api/v1/journey-details/${requestId}/basic-data`,
        { headers: head }
      )
      .toPromise();
  }

  getTimelineData(
    page: number = 0,
    pageSize: number = 0,
    requestId: number
  ): Promise<any> {
    const head = new HttpHeaders({});
    return this._http
      .get(
        `${environment.apiHosts}/backoffice-server/api/v1/journey-details/${requestId}/timeline?page=${page}&size=${pageSize}`,
        { headers: head }
      )
      .toPromise();
  }

  retrieveRequestStatus(oldStatus: string): Promise<any> {
    const head = new HttpHeaders({});
    return this._http
      .get(
        `${environment.apiHosts}/backoffice-server/api/v1/status/${oldStatus}/status-allowed?role=role-backoffice`,
        { headers: head }
      )
      .toPromise();
  }

  retrieveRequirementByStatus(
    statusStart: string,
    statusEnd: string
  ): Promise<any> {
    const head = new HttpHeaders({});
    return this._http
      .get(
        `${environment.apiHosts}/backoffice-server/api/v1/status/requirement?statusStart=${statusStart}&statusEnd=${statusEnd}`,
        { headers: head }
      )
      .toPromise();
  }

  retrieveRejectionsMotive(codeShowView: string): Promise<any> {
    const head = new HttpHeaders({});
    return this._http
      .get(
        `${environment.apiHosts}/backoffice-server/api/v1/request-rejection-motive?author=backoffice-api&codeShowView=${codeShowView}`,
        { headers: head }
      )
      .toPromise();
  }

  statusChange(payload): Observable<any> {
    const head = new HttpHeaders({});
    return this._http.post(
      `${environment.apiHosts}/backoffice-server/api/v1/status/change`,
      payload, { headers: head }
    );
  }
}

