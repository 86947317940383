import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { JWTTokenService } from './jwt-token.service';
import { LocalStorageService } from './local-storage.service';
import { Router } from '@angular/router';

@Injectable( {
	providedIn: 'root'
} )
export class AuthenticationService {

	public token = new Subject<string>();
	private storageSub= new Subject<String>();

	constructor(
		private _http: HttpClient,
        private localStorageService: LocalStorageService,
        private jwtService: JWTTokenService,
        private router: Router
	) {}

	login( user: string, pass: string ): Observable<any> {
		const body = {
			username: user,
			password: pass
		};
		const head = new HttpHeaders( { 'Content-Type': 'application/json; charset=utf-8' } );
		return this._http.post( `${environment.apiHosts}/backoffice-server/api/v1/login`,
		body, { headers: head } );
	}

	logout( ): Observable<any> {
		const head = new HttpHeaders(
			{ }
		);
		return this._http.delete( `${environment.apiHosts}/backoffice-server/api/v1/logout`,
		{ headers: head } );
	}

	getToken() {
	   return this.localStorageService.get('token');
	}

	isTokenExpired() {
	   this.jwtService.setToken(this.localStorageService.get('token'));
	   return this.jwtService.isTokenExpired();
	}

    isLoggedIn() {
		this.jwtService.setToken(this.localStorageService.get('token'));
        if ( this.localStorageService.get('token') && !this.jwtService.isnewTokenExpired() ) {
			sessionStorage.removeItem('token');
            return true;
        } else {
            return false;
        }
    }

	isNewTokenExpired() {
		this.jwtService.setToken(this.localStorageService.get('token'));
		if(this.jwtService.isnewTokenExpired()) {
			sessionStorage.removeItem('token');
			this.router.navigate(['/login']);
		}
	}

	isSessionExpired(): boolean {
		return this.jwtService.isnewTokenExpired();
	}

    isUserHogami(): boolean {
        let username =  this.jwtService.getUser();
        var isUserHogami: boolean = false;
        if(username.includes('@leroymerlin.es') || username.includes('@everis')) {
            isUserHogami = true;
        }
        return isUserHogami;
    }
  
	watchStorage(): Observable<any> {
		return this.storageSub.asObservable();
	}

	setSessionExpire(data: any) {
		localStorage.setItem('sessionExpired', data);
		this.storageSub.next(data);
	}
}
