<div style="display:inline !important;">
  <app-lm-filters-billing
          [loading]="loading"
          [loadExcel]="loadExcel"
          (filtersSend)="getFilteredOperations($event)"
          (filterstoExcel)="exportToExcel($event)"
          (sendExcelEmail)="sendExcelEmail($event)"></app-lm-filters-billing>
</div>

<div class="content">
  <div class="example-container">
    <mat-table  [dataSource]="content">

      <ng-container matColumnDef="id" sticky>
        <th mat-header-cell *matHeaderCellDef>{{'lm-transfers.billing.cells.id' | translate}}</th>
        <nav>
          <td mat-cell *matCellDef="let element">
              <a [routerLink]="['/work-details', element.requestId]"><strong>Op: {{element.operationId}}</strong><br /></a>
          </td>
        </nav>
        <td mat-cell *matCellDef="let element"><strong></strong></td>
      </ng-container>

      <ng-container matColumnDef="operationStatus" class="spacing">
        <th mat-header-cell *matHeaderCellDef>{{'lm-transfers.billing.cells.status' | translate}}</th>
        <td mat-cell *matCellDef="let element">
          <div style="border-radius: 5px; padding: 5px;" 
          [ngStyle]="{'color': element.operationStatusRest.propertyStatus.text_color, 
          'background': element.operationStatusRest.propertyStatus.background_color + '20', 
          'border': '1px solid' + element.operationStatusRest.propertyStatus.background_color}">
            {{element.operationStatusRest.description}}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="isExternal" class="spacing">
        <th mat-header-cell *matHeaderCellDef>{{'lm-transfers.billing.cells.interna' | translate}}</th>
        <td mat-cell *matCellDef="let element"> {{element.operationInternal ? "Si": "No"}} </td>
      </ng-container>


      <ng-container matColumnDef="professionalDetails" class="spacing">
        <th mat-header-cell *matHeaderCellDef>{{'lm-transfers.billing.cells.professional' | translate}}</th>
        <td mat-cell *matCellDef="let element">
          <div>
            <span class="professional_name">{{element.supplierProfessional}}</span>
          </div>
          <div class="professional_email">{{element.emailProfessional}}</div>
          <div class="professional_document">{{element.nifProfessional}}</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="entTime">
        <th mat-header-cell *matHeaderCellDef>{{'lm-transfers.billing.cells.final_date' | translate}}</th>
        <td mat-cell *matCellDef="let element"> {{getDateWithTimeZone(element.dateClosedOperation)}} </td>
      </ng-container>


      <ng-container matColumnDef="movements" class="spacing">
        <th mat-header-cell *matHeaderCellDef>{{'lm-transfers.billing.cells.summary' | translate}}</th>
        <td mat-cell *matCellDef="let element">
          <table style="width: 100%;">
            <tr>
              <td>
                {{'lm-transfers.billing.summary.services' | translate}}
              </td>
              <td>
                <div *ngFor="let p of element.offeredServiceOperationRest">
                  <div class="currency">{{p?.priceOriginal}}</div>
                </div>
              </td>
            </tr>
            <tr>
              <td>{{'lm-transfers.billing.summary.products' | translate}}</td>
              <td class="currency">{{getLocalNumber(element.amountPriceProduct)}}</td>
            </tr>
            <tr>
              <td>{{'lm-transfers.billing.summary.discount' | translate}}
                  (<a class="coupon-code-link" *ngFor="let c of element.operationCoupons" (click)="showCouponVersion(c)" >{{'lm-journey-details.sections.lm-journey-basic-data.coupon.coupon' | translate}} {{c?.couponCode}}</a>)
              </td>
              <td class="currency" *ngIf="element?.amountDiscount == 0">0</td>
              <td class="currency" *ngIf="element?.amountDiscount != 0">-{{element?.amountDiscount}}</td>
            </tr>
            <tr>
              <td class="underline"></td>
              <td class="underline"></td>
            </tr>
            <tr>
              <td><strong>{{'lm-transfers.billing.summary.total_paid' | translate}}</strong></td>
              <td class="currency" *ngIf="!element.isCanceledLessTwentyFourHours"> {{getLocalNumber(element.paymentClient)}} </td>
              <td class="currency" *ngIf="element.isCanceledLessTwentyFourHours"> {{getLocalNumber(element.paymentClient)}} (*)</td>
            </tr>
            <tr>
              <td class="">{{'lm-transfers.billing.summary.taxable_income' | translate}}</td>
              <td class="currency">{{ getLocalNumber(element.taxBase) }}</td>
            </tr>
            <tr>
              <td class="">{{'lm-transfers.billing.summary.taxes' | translate}}  ({{element.taxRate}}% )</td>
              <td class="currency">{{getLocalNumber(element.taxes)}}</td>
            </tr>
            <tr>
              <td class="">{{'lm-transfers.billing.summary.commission' | translate}}</td>
              <td class="currency">{{getLocalNumber(element.hogamiComission)}}</td>
            </tr>
            <tr *ngIf="element.isCanceledLessTwentyFourHours">
              <td class="">{{'lm-transfers.billing.summary.cancelation_fee' | translate}}</td>
              <td class="currency">{{ getIsCanceled(element.isCanceledLessTwentyFourHours) }}</td>
            </tr>
          </table>
        </td>
      </ng-container>
      <ng-container matColumnDef="region" class="spacing">
        <th mat-header-cell *matHeaderCellDef>{{'lm-transfers.billing.cells.region' | translate}}</th>
        <td mat-cell *matCellDef="let element"> {{element.region}} </td>
      </ng-container>


      <ng-container matColumnDef="operationOfferedServices">
        <th mat-header-cell *matHeaderCellDef>{{'lm-transfers.billing.cells.services' | translate}}</th>
        <td mat-cell *matCellDef="let element">
          <div *ngFor="let p of element.offeredServiceOperationRest ; first as isFirst">
            <span *ngIf="isFirst">
                <div><span class="service_code">{{p?.code}}</span> : <span class="service_name">{{p?.title}}</span></div>
                <div class="service_price">{{'lm-transfers.billing.services.price' | translate}} {{p?.price}}  {{p?.currency}} ({{'lm-transfers.billing.services.margin' | translate}} {{p?.profitMargin}} %)</div>
                <div class="service_tax">{{p?.taxDetails?.taxRate}}  {{p?.taxDetails?.taxType}}</div>
                <div class="service_date">{{'lm-transfers.billing.services.added_on' | translate}} {{p?.createDate}}</div>
            </span>
          </div>
          <div *ngIf="element.operationOfferedServices?.length > 1">
            <button mat-stroked-button (click)="showMore()">{{stringShowMore}}</button>
            <div  *ngIf="buttonShowMore">
              <div *ngFor="let p of element.offeredServiceOperationRest | slice:1; let i = index;" class="upperline">
                <div><span class="service_code">{{p['code']}}</span> : <span class="service_name">{{p['title']}}</span></div>
                <div class="service_price">{{'lm-transfers.billing.services.price' | translate}} {{p['price']}}  {{p['currency']}} ({{'lm-transfers.billing.services.margin' | translate}} {{p['profitMargin']}} %)</div>
                <div class="service_tax">{{p['taxDetails']['taxRate']}}  {{p['taxDetails']['taxType']}}</div>
                <div class="service_date">{{'lm-transfers.billing.services.added_on' | translate}} {{p['createDate']}}</div>
              </div>
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="paycometAssociate" class="spacing" style="color:royalblue">
        <th mat-header-cell *matHeaderCellDef>{{'lm-transfers.billing.cells.id' | translate}}<br>{{'lm-transfers.billing.cells.paycomet' | translate}}</th>
        <td mat-cell *matCellDef="let element">
          <div *ngFor="let p of element.listPaycometInformation">
            <p>{{p.paycometIDAssociate}}</p>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="paycometOperationName" class="spacing" style="color:royalblue">
        <th mat-header-cell *matHeaderCellDef>{{'lm-transfers.billing.cells.transaction' | translate}}<br>{{'lm-transfers.billing.cells.paycomet' | translate}}</th>
        <td mat-cell *matCellDef="let element"> {{element.paycometOperationName}}
          <div *ngFor="let p of element.listPaycometInformation">
            <p>{{p.paycometOperationName}}</p>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="paycometAmount" class="spacing" style="color:royalblue">
        <th mat-header-cell *matHeaderCellDef>{{'lm-transfers.billing.cells.value' | translate}}<br>{{'lm-transfers.billing.cells.paycomet' | translate}} </th>
        <td mat-cell *matCellDef="let element">
          <div *ngFor="let p of element.listPaycometInformation">
            <p>{{getLocalNumber(p.paymentAmount, 2)}}</p>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="transferType" class="spacing">
        <th mat-header-cell *matHeaderCellDef>{{'lm-transfers.billing.cells.remarks_sepa' | translate}}</th>
        <td mat-cell *matCellDef="let element">
          <div *ngFor="let p of element.listStatusTransferType">
            <p>{{p}}</p>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="campaigns" class="spacing">
        <th mat-header-cell *matHeaderCellDef>{{'lm-transfers.billing.cells.campaign' | translate}}</th>
        <td mat-cell *matCellDef="let element">
          <div *ngIf="element.listOperationCampaigns.length > 0">
            <div *ngFor="let p of element.listOperationCampaigns">
              <p>{{p?.campaignCode}}</p>
            </div>
          </div>
          <div *ngIf="element.listOperationCampaigns.length <= 0">
            <p>{{'lm-transfers.billing.campaign.web' | translate}}</p>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="storeName" class="spacing">
        <th mat-header-cell *matHeaderCellDef>{{'lm-transfers.billing.cells.shop' | translate}}</th>
        <td mat-cell *matCellDef="let element">
          <div *ngIf="element.storeRest">{{element.storeRest?.name}} ({{element.storeRest?.leroyId}})</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="comercialId" class="spacing">
        <th mat-header-cell *matHeaderCellDef>{{'lm-transfers.billing.cells.comercial_id' | translate}}</th>
        <td mat-cell *matCellDef="let element">
          <div>{{element.commercialId}}</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="fill">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </mat-table>
  <mat-paginator [length]="totalElements" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25, 100]"
                 (page)="getPaginatorData($event)">
  </mat-paginator>
</div>
</div>
