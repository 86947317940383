<div class="content">
    <mat-card class="card">
        <mat-card-header>
            <mat-card-title>{{'lm-professional.lm-professional-register.title' | translate}}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="step_one">
                <div class="step_one__stepone">
                    <p><strong>{{'lm-professional.lm-professional-register.step_one' | translate}}</strong>{{'lm-professional.lm-professional-register.step_one_texts.summary' | translate}}</p>
                    <mat-icon class="material-symbols-outlined" (click)="downloadTemplate()">
                        download
                    </mat-icon>
                </div>
                <p><strong>{{'lm-professional.lm-professional-register.step_two' | translate}}</strong>{{'lm-professional.lm-professional-register.step_two_texts.summary' | translate}}</p>
                <p><strong>{{'lm-professional.lm-professional-register.step_three' | translate}}</strong>{{'lm-professional.lm-professional-register.step_three_texts.summary' | translate}}</p>
                <div class="step_three">
                    <div class="step_three__inner" appDnd (fileDropped)="onFileDropped($event)" 
                    ondragenter="event.target.classList.add('step_three__inner__dragover')" 
                    ondragleave="event.target.classList.remove('step_three__inner__dragover')" 
                    ondrop="event.target.classList.remove('step_three__inner__dragover')"
                    onclick="document.getElementById('fileDropRef').click();">
                        <input class="step_three__inner__file_input" type="file" [disabled]="fileSelected"
                        #fileDropRef id="fileDropRef" (change)="fileBrowseHandler($event.target['files'])" 
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>
                        <p>{{'lm-professional.lm-professional-register.step_three_texts.drag_drop' | translate}}</p>
                        <p>{{'lm-professional.lm-professional-register.step_three_texts.o' | translate}}</p>
                        <button type="button" for="fileDropRef" class="button" [ngClass]="{'button-disabled': fileSelected}">
                            {{'lm-professional.lm-professional-register.step_three_texts.btn_choose_file' | translate}}
                        </button>
                    </div>
                </div>
            </div>
            <div class="step_four">
                <p><strong>{{'lm-professional.lm-professional-register.step_four' | translate}}</strong>{{'lm-professional.lm-professional-register.step_four_texts.summary' | translate}}</p>
                <div class="step_four__item">
                    <button mat-raised-button [disabled]="!fileSelected || isProgress" color="primary" class="button-process" (click)="validateTemplate()">
                        {{'lm-professional.lm-professional-register.step_four_texts.btn_proccess_template' | translate}}
                    </button>
                    <p *ngIf="fileSelected">{{fileSelected.name}}</p>
                    <button mat-raised-button *ngIf="fileSelected" color="accent" class="button-delete" (click)="deleteFileSelected()">
                        <mat-icon class="material-symbols-outlined">delete</mat-icon>
                    </button>
                </div>
                <mat-progress-bar *ngIf="isProgress" mode="indeterminate" value="140"></mat-progress-bar>
                <div class="step_four__div" *ngIf="professionalList && professionalList.length > 0; else errorValidation">
                    <p>{{'lm-professional.lm-professional-register.step_four_texts.title_result' | translate}}</p>
                    <p>{{'lm-professional.lm-professional-register.step_four_texts.information_is_ok' | translate}}</p>
                    <p>{{'lm-professional.lm-professional-register.step_four_texts.you_can_proccess' | translate}}</p>
                </div>
                <ng-template #errorValidation>
                    <div class="step_four__div" *ngIf="errorValidation && validationError">
                        <p><strong>{{'lm-professional.lm-professional-register.step_four_texts.error.title' | translate}}</strong></p>
                        <p><strong>{{'lm-professional.lm-professional-register.step_four_texts.error.code' | translate}}</strong> {{validationError.code}}</p>
                        <p><strong>{{'lm-professional.lm-professional-register.step_four_texts.error.message' | translate}}</strong> {{validationError.message}}</p>
                    </div>
                </ng-template>
            </div>
            <div class="step_five">
                <p><strong>{{'lm-professional.lm-professional-register.step_five' | translate}}</strong>{{'lm-professional.lm-professional-register.step_five_texts.summary' | translate}}</p>
                <button mat-raised-button [disabled]="!informationIsValid" color="primary" class="button-process" (click)="registerProfessionals()">
                    {{'lm-professional.lm-professional-register.step_five_texts.btn_proccess_template' | translate}}
                </button>
                <mat-progress-bar *ngIf="isProgressRegistration" mode="indeterminate" value="140"></mat-progress-bar>
                <div class="step_five__div" *ngIf="isResultSuccesfully; else errorRegistration">
                    <p>{{'lm-professional.lm-professional-register.step_five_texts.title_result' | translate}}</p>
                    <p>{{'lm-professional.lm-professional-register.step_five_texts.template_is_ok' | translate}}</p>
                    <p *ngIf="type==='ADD'">{{'lm-professional.lm-professional-register.step_five_texts.proccess_ok' | translate}}</p>
                    <pre *ngIf="type==='ADD'">{{registrationMsg}}</pre>
                </div>
                <ng-template #errorRegistration>
                    <div class="step_five__div" *ngIf="errorRegistration && registrationError">
                        <p><strong>{{'lm-professional.lm-professional-register.step_five_texts.error.title' | translate}}</strong></p>
                        <p><strong>{{'lm-professional.lm-professional-register.step_five_texts.error.code' | translate}}</strong> {{registrationError.code}}</p>
                        <p><strong>{{'lm-professional.lm-professional-register.step_five_texts.error.message' | translate}}</strong> {{registrationError.message}}</p>
                    </div>
                </ng-template>
            </div>
        </mat-card-content>
    </mat-card>
</div>