<div class="content">
    <mat-progress-bar *ngIf="loading" mode="indeterminate" value="140"></mat-progress-bar>
    <form action="" [formGroup]="paycometForm" enctype="multipart/form-data" (ngSubmit)="submitForm()">
    <div class="content_form">
        <mat-form-field>
            <mat-label>{{'lm-professional.lm-paycomet-documents.document_type' | translate}}</mat-label>
            <mat-select name="documentType" formControlName="documentType" (selectionChange)="retrieveHintSepaDocumentType($event.value)">
                <mat-option *ngFor="let documentType of sepaDocumentTypeDropdown"
                    [value]="documentType.id">{{documentType.id}} - {{documentType.name}}</mat-option>
            </mat-select>
            <mat-error *ngIf="paycometForm.get('documentType')?.hasError('required')">
                {{'lm-professional.lm-paycomet-documents.error.documentType' | translate}}
            </mat-error>
        </mat-form-field>
        <mat-icon aria-hidden="false" aria-label="information tips" class="edit" fontIcon="info" *ngIf="sepaDocumentTypeHints">info</mat-icon>    
        <div class="content_form_tips" *ngIf="sepaDocumentTypeHints">
            <div class="content_form_item_tips">
                <label *ngFor="let sepaDocumentTypeHint of sepaDocumentTypeHints">{{sepaDocumentTypeHint.hint}}</label>
            </div>
        </div>
    </div>
    <div class="content_img">
        <div class="content_img_form">
            <div>
                <mat-form-field>
                    <mat-label>{{'lm-professional.lm-paycomet-documents.form_file' | translate}}</mat-label>
                    <input matInput type="text" readonly [value]="fileName" (click)="f_input.click()" formControlName="fileSelected" name="fileSelected">
                    <button mat-icon-button matPrefix (click)="f_input.click()" type="button" color="primary">
                        <mat-icon>image</mat-icon>
                    </button>
                    <input type="file" id="file" hidden placeholder="file" #f_input name="file"
                        accept=".jpeg,.jpg,.png,.pdf" (change)="fileChangeEvent($event)" />
                    <mat-error *ngIf="paycometForm.get('fileSelected')?.hasError('required')">
                        {{'lm-professional.lm-paycomet-documents.error.fileContent' | translate}}
                    </mat-error>
                </mat-form-field>
            </div>
            <div *ngIf="!pdfChangedEvent && imageChangedEvent">
                <button mat-raised-button (click)="rotateLeft()" type="button">
                    <mat-icon>rotate_left</mat-icon> {{'lm-professional.lm-paycomet-documents.button_turn_left' | translate}}
                </button>
                <button mat-raised-button (click)="rotateRight()" type="button">
                    <mat-icon>rotate_right</mat-icon> {{'lm-professional.lm-paycomet-documents.button_turn_right' | translate}}
                </button>
            </div>
            <div *ngIf="pdfChangedEvent && !imageChangedEvent">
                <mat-form-field>
                    <mat-label>{{'lm-professional.lm-paycomet-documents.form_size' | translate}}</mat-label>
                    <input matInput type="text" readonly name="size" [value]="size" >
                </mat-form-field>
                <mat-form-field>
                    <mat-label>{{'lm-professional.lm-paycomet-documents.form_pages' | translate}}</mat-label>
                    <input matInput type="text" readonly name="size" [value]="pages" >
                </mat-form-field>
            </div>
            <div class="content_img_form">
                <div>
                    <button mat-raised-button color="primary" type="submit" [disabled]="loading" class="button_submit">
                        <mat-icon>save</mat-icon> {{'lm-professional.lm-paycomet-documents.buttons.save' | translate}}
                    </button>
                    <button mat-raised-button (click)="closeTab()">
                        <mat-icon>close</mat-icon> {{'lm-professional.lm-paycomet-documents.buttons.cancel' | translate}}
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="content_img_selected" *ngIf="!pdfChangedEvent && imageChangedEvent">
        <div class="content_img_selected_cropped">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>{{'lm-professional.lm-paycomet-documents.original_image' | translate}}</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="false"
                        [aspectRatio]="4 / 3" [resizeToWidth]="1024" [cropperMinWidth]="128" [onlyScaleDown]="true"
                        [roundCropper]="false" format="png" outputType="base64"
                        (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
                        (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"
                        [style.display]="showCropper ? null : 'none'"></image-cropper>
                    <hr>
                    <label>{{'lm-professional.lm-paycomet-documents.form_size' | translate}}: {{size}}</label>
                    <br>
                    <label>{{'lm-professional.lm-paycomet-documents.form_dimensions' | translate}}: {{width}} x {{height}}</label>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="content_img_selected_result">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>{{'lm-professional.lm-paycomet-documents.final_image' | translate}}</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <img [src]="croppedImage" />
                    <hr>
                    <label>{{'lm-professional.lm-paycomet-documents.form_size' | translate}}: {{sizeFinal}}</label>
                    <br>
                    <label>{{'lm-professional.lm-paycomet-documents.form_dimensions' | translate}}: {{finalWidth}} x {{finalHeight}}</label>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
    <div class="content_pdf" *ngIf="pdfChangedEvent && !imageChangedEvent">
        <pdf-viewer *ngIf="!!pdfSource && pdfChangedEvent" [src]="pdfSource" [render-text]="true" [fit-to-page]="true" [page]="2"
            style="display: block; height: 550px; border: 0.5px solid;" (after-load-complete)="callBackFn($event)"></pdf-viewer>
    </div>
    </form>
</div>