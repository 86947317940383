import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../services/auth.service';
import { Router } from '@angular/router';

@Injectable({
	providedIn: 'root'
})
export class TransferInterceptor implements HttpInterceptor {

    constructor(
        private router: Router,
        private _auth: AuthenticationService
    ) { }
    private readonly _unauthorizedCodes = [
        401, 403
    ];

      intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        var token = this._auth.getToken();
        var authRquest;

        if (token != null && this._auth.isTokenExpired()) {
          alert("La sessión ha finalizado, por favor ingrese de nuevo");
          this._auth.logout()
          this.router.navigate(['/login'])
          return throwError("Session Timed Out")
        } else {
            //Check for url. If it is login url then return
            if (req.url.includes('/login')) {
             authRquest = req.clone({
               params: req.params.append('key', environment.authKey)
               })
            } else {
              authRquest = req.clone({
                setHeaders: {
                  Authorization: 'Bearer ' + token,
                  'Accept-Language': 'es-ES',
                },
                params: req.params.append('key', environment.authKey)
                })
            }
          return next.handle(authRquest)
            .pipe(
              tap(event => {
              }, error => {
                    if (error instanceof HttpErrorResponse && this._unauthorizedCodes.indexOf(error.status) > -1) {
                        localStorage.removeItem('token');
                        this._auth.setSessionExpire("true");
                    }
                    return throwError(error);
              })
            )
        }
    }
}