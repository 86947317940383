<h2 mat-dialog-title>{{couponVersion?.couponDto?.code}}</h2>

<mat-dialog-content class="mat-typography">
    <div class="grid">
        <label style="cursor: default;" class="grid-input"><strong>{{'modals.coupon-dialog.label_id' | translate}}</strong></label>
        <label style="cursor: default;" class="grid-texts"> {{couponVersion?.id}}</label>
    </div>
    <div class="grid">
        <label style="cursor: default;" class="grid-input"><strong>{{'modals.coupon-dialog.label_type' | translate}}</strong></label>
        <label style="cursor: default;" class="grid-texts"> {{couponType}}</label>
    </div>
    <div class="grid">
        <label style="cursor: default;" class="grid-input"><strong>{{'modals.coupon-dialog.label_scope' | translate}}</strong></label>
        <label style="cursor: default;" class="grid-texts"> {{couponScope}}</label>
    </div>
    <div class="grid">
        <label style="cursor: default;" class="grid-input"><strong>{{'modals.coupon-dialog.label_target' | translate}}</strong></label>
        <label style="cursor: default;" class="grid-texts"> {{couponTarget}}</label>
    </div>
    <div class="grid">
        <label style="cursor: default;" class="grid-input"><strong>{{'modals.coupon-dialog.label_create' | translate}}</strong></label>
        <label style="cursor: default;" class="grid-texts"> {{couponVersion?.createDate | date: "yyyy-MM-dd HH:mm:ss"}}</label>
    </div>
    <div class="grid">
        <label style="cursor: default;" class="grid-input"><strong>{{'modals.coupon-dialog.label_startdate' | translate}}</strong></label>
        <label style="cursor: default;" class="grid-texts"> {{couponVersion?.startDate | date: "yyyy-MM-dd HH:mm:ss"}}</label>
    </div>
    <div class="grid">
        <label style="cursor: default;" class="grid-input"><strong>{{'modals.coupon-dialog.label_enddate' | translate}}</strong></label>
        <label style="cursor: default;" class="grid-texts"> {{couponVersion?.endDate | date: "yyyy-MM-dd HH:mm:ss"}}</label>
    </div>
    <div class="grid">
        <label style="cursor: default;" class="grid-input"><strong>{{'modals.coupon-dialog.label_value' | translate}}</strong></label>
        <label style="cursor: default;" class="grid-texts"> {{couponVersion?.value}} 
            <span *ngIf="couponVersion?.couponTypeDto?.code === 'PERCENTAGE'">%</span>
            <span *ngIf="couponVersion?.couponTypeDto?.code === 'FIXED_AMOUNT'">EUR</span>
        </label>
    </div>
    <div class="grid">
        <label style="cursor: default;" class="grid-input"><strong>{{'modals.coupon-dialog.label_usagelimit' | translate}}</strong></label>
        <label style="cursor: default;" class="grid-texts"> {{couponVersion?.usageLimit}}</label>
    </div>
    <div class="grid">
        <label style="cursor: default;" class="grid-input"><strong>{{'modals.coupon-dialog.label_minamount' | translate}}</strong></label>
        <label style="cursor: default;" class="grid-texts"> {{couponVersion?.minimumAmount}}</label>
    </div>
    <div class="grid">
        <label style="cursor: default;" class="grid-input"><strong>{{'modals.coupon-dialog.label_maxamount' | translate}}</strong></label>
        <label style="cursor: default;" class="grid-texts"> {{couponVersion?.maximumAmount}}</label>
    </div>
    <div class="grid">
        <label style="cursor: default;" class="grid-input"><strong>{{'modals.coupon-dialog.label_cumulative' | translate}}</strong></label>
        <label style="cursor: default;" class="grid-texts" *ngIf="couponVersion?.cumulative"> Si</label>
        <label style="cursor: default;" class="grid-texts" *ngIf="!couponVersion?.cumulative"> No</label>
    </div>
    <div class="grid">
        <label style="cursor: default;" class="grid-input"><strong>{{'modals.coupon-dialog.label_description' | translate}}</strong></label>
        <div>
            <div *ngFor="let d of couponVersion?.couponVersionDescriptionDtos">
                <label style="cursor: default;" class="grid-texts"> {{d['description']}}</label>
            </div>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>{{'modals.coupon-dialog.btn_ok' | translate}}</button>
</mat-dialog-actions>