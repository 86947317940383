import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MaintenanceService } from 'src/app/core/services/mant.service';
import { Utilities } from 'src/app/shared/utilities/utilities';
import { get as _get } from 'lodash';
import { ModalPaybyComponent } from '../../modals/modal-payby/modal-payby.component';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../../../core/services/auth.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-lm-transfer-payby',
  templateUrl: './lm-transfer-payby.component.html',
  styleUrls: ['./lm-transfer-payby.component.scss', '../../utils/transfers.scss']
})
export class LmTransferPaybyComponent implements OnInit, OnDestroy {
  _translateSubscription: Subscription;
  form: FormGroup;
  message: string;
  isError: boolean = false;
  isDisabled: boolean = false;
  submitted: boolean = false;
  isUserLeroy: boolean;
  classButton: string;
  operation: any;
  lastValidImporte: string = ""
  maxAmmount: number = 99999999.99;
  _messages: { [key: string]: string };
  dialogRef: MatDialogRef<ModalPaybyComponent>;

  constructor(
    private _mant: MaintenanceService,
    private _router: Router,
    public dialog: MatDialog,
    private _translateService: TranslateService,
    private _auth: AuthenticationService
  ) {
    this._translateSubscription = this._translateService
      .get('lm-transfer-payby.logic')
      .subscribe((data: { [key: string]: string }) => {
        this._messages = data;
      });
  }
  ngOnDestroy(): void {
    this._translateSubscription.unsubscribe();
  }
  ngOnInit() {
    this.form = new FormGroup({
      operacion: new FormControl(null, Validators.required),
      importe: new FormControl(null, [Validators.required, Validators.pattern(/(^[0-9]{1,6}$)|(^[0-9]{1,6}\,\d{0,2}$)/)]),
      referenciaPaycomet: new FormControl(null, Validators.required),
    });
    this.isUserLeroy = Utilities.isUserHogami();
    this.classButton = 'form__button';

    this._auth.isNewTokenExpired();
  }
  get f() { return this.form.controls; }
  get importe() { return this.form.controls.importe; }
  get operacion() { return this.form.controls.operacion; }
  get referenciaPaycomet() { return this.form.controls.referenciaPaycomet; }

  async transferPayBy() {
    this.submitted = true;
    this.message = "";

    const { operacion, importe, referenciaPaycomet } = this.form.value;
    const order = operacion + "-" + referenciaPaycomet;

    if (importe == '' || importe == null) {
      this.showError(this._messages['enter_amount']);
      return;
    }

    if (importe == '0') {
      this.showError(this._messages['enter_positive_amount']);
      return;
    }

    if (operacion == '' || operacion == null || !/^\d+$/.test(operacion)) {
      this.showError(this._messages['enter_valid_operation']);
      this.form.controls.operacion.setValue('');
      return;
    }

    const t = (parseFloat(this.proccesAmmounts(importe)) * 100).toFixed();
    if (parseFloat(t) < 0) {
      this.showError(this._messages['enter_positive_amount']);
      return;
    }

    if (referenciaPaycomet == '' || referenciaPaycomet == null) {
      this.showError(this._messages['enter_valid_reference']);
      return;
    }

    if (this.operation === null || this.operation === undefined) {
      this.showError(this._messages['operation_id_no_valid']);
      return;
    }

    if (!this.form.valid) {
      return;
    }

    this.openDialog(order.toUpperCase(), importe);
  }

  private showError(message: string) {
    this.isDisabled = false;
    this.isError = true;
    this.message = message;
  }

  processTransferPayBy(order, importe) {
    this._mant.transferPayBy({ order, amount: (parseFloat(this.proccesAmmounts(importe)) * 100).toFixed() }).subscribe({
      next: (response) => {
        this.submitted = false;
        if (response.data.errorCode === 0) {
          this.message = this._messages['operation_performed'];
          this.isError = false
          this.isDisabled = false;
          this.dialogRef.componentInstance.myf({ progress: false }, { transferDiv: true }, { transferMsg: this.message });
        } else {
          this.message = this._messages['error_operations'] + response.data.errorCode + "-" + response.data.response;
          this.isError = true
          this.isDisabled = false;
          this.dialogRef.componentInstance.myf({ progress: false }, { transferDiv: false }, { transferMsg: this.message });
        }
      },
      error: (error) => {
        this.isDisabled = false;
        this.isError = true
        this.message = this._messages['operation_not_correctly_performed'];
        var errorCode = error.error.code;
        if (errorCode === 'SBACK-401-4') {
          this.message = this._messages['paycomet_reference_longer'];
        }
        this.dialogRef.componentInstance.myf({ progress: false }, { transferDiv: false }, { transferMsg: this.message });
      }
    });
  }

  openDialog(order, importe) {
    const ammountValid = (parseFloat(this.proccesAmmounts(importe.trim())) * 100).toFixed();
    if (parseInt(ammountValid) > this.maxAmmount) {
      this.isError = true
      this.message = this._messages['amount_exceeds_maximum_allowed'];
      return;
    }

    this.dialogRef = this.dialog.open(ModalPaybyComponent, {
      width: '600px',
      height: 'auto',
      disableClose: true,
      data: {
        operation: this.operation,
        reference: order,
        importe: importe,
        progress: false,
        transferDiv: false
      }
    });

    this.dialogRef.afterClosed().subscribe(result => {
      console.debug('The dialog was closed');
      this.message = '';
    });

    this.dialogRef.componentInstance.submitClicked.subscribe(result => {
      if (result === 'submit') {
        this.processTransferPayBy(order, importe);
      } else {
        this.dialogRef.close();
        window.location.reload();
      }
    });
  }

  goBack() {
    this._router.navigate(["/home"]);
  }

  onChangeOperationNumber(e) {
    const key = e.key;
    if (key < '0' || key > '9') {
      e.preventDefault();
    }
  }

  changeValue(e, inputField) {
    const value = e.target.value;
    if (!value) {
      return;
    }
    if (e.target.name === "importe") {
      const regex = /(^[0-9]{1,6}$)|(^[0-9]{1,6}\,\d{0,2}$)/
      if (regex.test(value)) {
        inputField.setErrors({ 'pattern': false });
        inputField.setValue(value);
        this.lastValidImporte = value
      } else {
        inputField.setErrors({ 'pattern': true });
        inputField.setValue(this.lastValidImporte)
      }
      return
    }
    const reg = /((^[0-9]{1,}$)|\d+(\,\d{0,2})$)/
    if (reg.test(value)) {
      inputField.setValue(value)
    } else {
      inputField.setValue(value.substring(0, value.length - 1))
    }
  }

  changeValueReference(e, inputField) {
    const value = e.target.value;
    if (!value) {
      return;
    }
    const reg = /^[a-zA-Z0-9_]*$/
    if (reg.test(value)) {
      inputField.setValue(value)
    } else {
      inputField.setValue(value.substring(0, value.length - 1))
    }
  }

  onBlur(event, inputField) {
    const value = event.target.value
    if (value && value.slice(-1) == ",") {
      inputField.setValue(value.substring(0, value.length - 1))
    }
  }

  onBlurOperation(event, inputField) {
    const value = event.target.value
    if (value && value.slice(-1) == ",") {
      inputField.setValue(value.substring(0, value.length - 1))
    }
    if (value != '') {
      this.classButton = 'form__button_back';
      this.isDisabled = true;
      this.isError = false
      this.message = '';

      this._mant.findOperation(value).subscribe({
        next: (res) => {
          this.operation = res.data;
          this.classButton = 'form__button';
          this.isDisabled = false;
          this.isError = false
        },
        error: (error) => {
          this.classButton = 'form__button';
          this.isDisabled = false;
          this.isError = true
          this.message = this._messages['operation_not_found'];
          if (error.error.code === "SBACK-401-2") {
            localStorage.removeItem('token');
          }
        }
      });
    }
  }

  proccesAmmounts(number: string): string {
    var numberOutput = "0";
    if (number !== undefined && number !== null && number != "") {
      if (number.includes(",")) {
        numberOutput = number.toString().replace(/,/g, ".");
      } else {
        numberOutput = number;
      }
      return numberOutput;
    } else {
      return "0";
    }
  }
}
