import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Utilities } from '../../../../shared/utilities/utilities';
import { Campaigns, Stores } from '../../models/lm-transfers.model';
import { TransferService } from '../../services/transfers.service';

@Component({
  selector: 'app-lm-filters-billing',
  templateUrl: './lm-filters-billing.component.html',
  styleUrls: ['../../../utils/form-search.scss','./lm-filters-billing.component.scss', '../../../utils/dropdown.scss']
})
export class LmFiltersBillingComponent implements OnInit, OnDestroy {
  _translationSubscription: Subscription;
  _messages: { [key: string]: string };
  selected: string = null;
	isUserLeroy: boolean;
  form: FormGroup;
  campaigns: Campaigns[] = [];
  stores: Stores[] = [];
  chosenType: string = null
  dropdownSettingCampaigns = {};
  dropdownSettingStores = {};
  @Input() loading: boolean = false
  @Input() loadExcel: boolean;
  @Output() filterstoExcel: EventEmitter<any> = new EventEmitter<any>();
  @Output() sendExcelEmail: EventEmitter<any> = new EventEmitter<any>();
  @Output() filtersSend: EventEmitter<any> = new EventEmitter<any>();
  checkboxItems: any = [
    {
      label: "Facturables",
      value:"facturables",
      checked: false
    },
    {
      label: "No facturables",
      value:"no_facturables",
      checked: false
    }
  ]

  constructor(
    private fb: FormBuilder,
    private _transferService: TransferService,
    private _translateService: TranslateService
    ) { 
      this._translationSubscription = this._translateService
      .get('lm-transfers.sections.lm-filters-billing.logic')
      .subscribe((res: { [key: string]: string }) => {
        this._messages = res;
        this.checkboxItems = [
          {
            label: res['billable'],
            value:"facturables",
            checked: false
          },
          {
            label: res['no_billable'],
            value:"no_facturables",
            checked: false
          }
        ]
      });
    }
  ngOnDestroy(): void {
    this._translationSubscription.unsubscribe();
  }

  ngOnInit() {
    this.createForm();
    this.loadCampaigns();
    this.loadStores();
    this.loadDropdownCampaigns();
    this.loadDropdownStores();
		this.isUserLeroy = Utilities.isUserHogami();
  }

  createForm(){
    this.form = this.fb.group({
      startTimeStart: [],
      endTimeStart: [],
      startTimeEnd: [],
      endTimeEnd: [],
      operationId: [],
      operationType: [],
      operationSubType: [],
      campaignSelected: [],
      storeSelected: [],
      commercialId: [],
      couponCode: null
    })
  }

  loadCampaigns() {
    this._transferService.getAllCampaigns().subscribe(res => {
      this.campaigns = res.data;
      this.campaigns.push({
        id:0,
        descriptionCampaign: '',
        codeCampaign: 'WEB'
      });
      this.campaigns.sort((a,b) => a.id.toString().localeCompare(b.id.toString()));
    });
  }

  loadStores() {
    this._transferService.getAllStores().subscribe(res => {
      this.stores = res.data;
      this.stores.forEach(element => {
        let fullName = element.leroyId + ' - ' + element.name;
        element.fullName = fullName;
      });
      this.stores.sort((a,b) => a.leroyId - b.leroyId);
    });
  }

  get f(){
    return this.form.value
  }

  onSelectedChange(e){
    e.preventDefault();
    setTimeout(()=>{
      const index = this.checkboxItems.findIndex(item => item.value === e.target.value)
      this.checkboxItems.map((item, i)=> {
        index !== i ? item.checked = false : item.checked
      })
    }, 100)
  }

  onChangeOperactionId( e ) {
		const key = e.key;
		if ( key < '0' || key > '9' ) {
			e.preventDefault();
		}
	}
  
  operationTypeChange(e){
    if (e.value !== this.chosenType) {
      this.chosenType = e.value
    } else {
      setTimeout(() => {
        this.chosenType = ""
        this.form.controls.operationType.setValue('')
        this.form.controls.operationSubType.setValue('')
      }, 100)
    }
  }

  /**
   * This function processes the array of campaigns to construct an array of integers.
   * @param arrayCampaign 
   */
  proccessCampaigns(arrayCampaign: any) {
    let finalCampaigns = [];
    arrayCampaign.forEach(element => {
      finalCampaigns.push(element.id);
    });
    return finalCampaigns;
  }

  /**
   * This function processes the array of stores to construct an array of integers.
   * @param arrayCampaign 
   */
  proccessStores(arrayStores: any) {
    let finalStores = [];
    arrayStores.forEach(element => {
      finalStores.push(element.leroyId);
    });
    return finalStores;
  }
  
  exportToExcel(){
    const filters = this.getValues()
    this.filterstoExcel.emit(filters);
  }

  sendExcel(){
    const filters = this.getValues()
    this.sendExcelEmail.emit(filters);
  }

  submitForm(){
    const filters = this.getValues()
    this.filtersSend.emit(filters)
  }

  getValues(): any{
    const values = this.form.value
    const filters = {
      startTimeStart: values.startTimeStart ? Utilities.getDate(values.startTimeStart) : null,
      startTimeEnd: values.startTimeEnd ? Utilities.getDateEnd(values.startTimeEnd) : null,
      endTimeStart: values.endTimeStart ? Utilities.getDate(values.endTimeStart) : null,
      endTimeEnd: values.endTimeEnd ? Utilities.getDateEnd(values.endTimeEnd) : null,
      operationId: values.operationId ? parseInt(values.operationId) : null,
      operationBillable: values.operationType ? values.operationType === "facturables": null,
      operationNoBillable: values.operationType ? values.operationType === "no_facturables": null,
      operationInternal: values.operationSubType ? values.operationSubType === "interna": null,
      operationExternal: values.operationSubType ? values.operationSubType === "externa": null,

      campaigns: ( this.form.controls[ 'campaignSelected' ].value == null || this.form.controls[ 'campaignSelected' ].value == "") ? [] : this.proccessCampaigns(this.form.controls[ 'campaignSelected' ].value),
      stores: ( this.form.controls[ 'storeSelected' ].value == null || this.form.controls[ 'storeSelected' ].value == "") ? [] : this.proccessStores(this.form.controls[ 'storeSelected' ].value),
      commercialId: ( this.form.controls[ 'commercialId' ].value ==  null || this.form.controls[ 'commercialId' ].value == "") ? null : this.form.controls[ 'commercialId' ].value ,
      couponCode: values.couponCode ? values.couponCode : null,
    }
    return filters
  }

  resetFilters(){
   this.form.reset();
  }

  /**
   * This function validate string in input phone
   * @param e 
   */
   onChangePhoneClent(e: any) {
    const key = e.key;
    if (key < "0" || key > "9") {
      e.preventDefault();
    }
  }

  /**
   * This function loads the transactions types of paycomet.
   */
  loadDropdownCampaigns() {
    this.dropdownSettingCampaigns = {
			singleSelection: false,
			idField: 'id',
			textField: 'codeCampaign',
			selectAllText: 'Seleccionar todos',
			unSelectAllText: 'Deseleccionar todos',
			itemsShowLimit: 3,
			allowSearchFilter: true
    }
  }

  loadDropdownStores() {
    this.dropdownSettingStores = {
			singleSelection: false,
			idField: 'leroyId',
			textField: 'fullName',
			selectAllText: 'Seleccionar todos',
			unSelectAllText: 'Deseleccionar todos',
			itemsShowLimit: 3,
			allowSearchFilter: true
    }
  }

}
