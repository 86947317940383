import { Subscription } from 'rxjs';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { TransferService } from 'src/app/pages/lm-transfers/services/transfers.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-lm-update-rating',
  templateUrl: './lm-update-rating.component.html',
  styleUrls: ['./lm-update-rating.component.scss']
})
export class LmUpdateRatingComponent implements OnInit {

  _translationSubscription: Subscription;

  @Input() rating: any;
  @Input() operationId: number
  @Input() editRatingOperation: boolean;
  @Output() editRatingOperationChange = new EventEmitter<boolean>();
  @Output() editRatingOperationEvent = new EventEmitter<any>();

  loading: boolean = false;
  ratingValues = [0, 1, 1.5, 2., 2.5, 3, 3.5, 4, 4.5, 5];
  formRating: FormGroup;
  _messages: { [key: string]: string };

  constructor(
    private _transferService: TransferService,
    private _snackBar: MatSnackBar,
    private _translateService: TranslateService
  ) { }

  ngOnInit() {
    this._translationSubscription = this._translateService
      .get('lm-journey-details.sections.lm-journey-update-rating.form.message')
      .subscribe((res: { [key: string]: string }) => {
        this._messages = res;
      });
    this.createRatingForm();
  }

  get f() {
    return this.formRating.controls;
  }

  /**
   * Create Form for update rating
   */
  createRatingForm(): void {
    this.formRating = new FormGroup({
      id: new FormControl('', Validators.required),
      generalRating: new FormControl(0, Validators.pattern(/^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/)),
      punctual: new FormControl(0, [Validators.required, Validators.pattern(/^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/)]),
      behavior: new FormControl(0, Validators.pattern(/^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/)),
      satisfied: new FormControl(0, Validators.pattern(/^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/)),
      clean: new FormControl(0, Validators.pattern(/^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/)),
      opinion: new FormControl(''),
    });
    this.setFormRatintOperation();
  }

  /**
   * Set information in form
   */
  setFormRatintOperation(): void {
    this.formRating.controls['id'].setValue(this.rating['id']);
    this.formRating.controls['generalRating'].setValue(this.rating['generalRating']);
    this.formRating.controls['punctual'].setValue(this.rating['punctual']);
    this.formRating.controls['behavior'].setValue(this.rating['behavior']);
    this.formRating.controls['satisfied'].setValue(this.rating['satisfied']);
    this.formRating.controls['clean'].setValue(this.rating['clean']);
    this.formRating.controls['opinion'].setValue(this.rating['opinion']);
  }

  /**
   * Submit form to update rating
   */
  submitForm(): void {
    if (this.formRating.valid) {
      this.loading = true;
      let ratingId = this.formRating.controls['id'].value;
      let ratingUpdate = {
        id: ratingId,
        generalRating: this.formRating.controls['generalRating'].value,
        punctual: this.formRating.controls['punctual'].value,
        behavior: this.formRating.controls['behavior'].value,
        satisfied: this.formRating.controls['satisfied'].value,
        clean: this.formRating.controls['clean'].value,
        opinion: this.formRating.controls['opinion'].value
      };
      let professionalId = this.rating['professionalId'];

      this._transferService.updateRatingOperation(ratingUpdate, this.operationId, professionalId, ratingId)
        .subscribe(res => {
          this.loading = false;
          if (res['code'] === '200') {
            this._snackBar.open(this._messages['success'],
            this._messages['ok'], { duration: 1500 });
            this.closeTab();
          } else {
            this._snackBar.open(this._messages['error'],
            this._messages['ok'], { duration: 1500 });
          }
        }, error => {
          this.loading = false;
          this._snackBar.open(this._messages['error'],
          this._messages['ok'], { duration: 1500 });
        });
    }
  }

  /**
   * Close current tab
   */
  closeTab() {
    this.editRatingOperation = false;
    this.editRatingOperationEvent.emit(null);
  }
}
