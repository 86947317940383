import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Utilities } from 'src/app/shared/utilities/utilities';
import { ClientService } from '../services/client.service';
import { IClientInformation, IFiltersDataClient } from '../models/lm-clients.model';
import { MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'app-client-details',
  templateUrl: './client-details.component.html',
  styleUrls: ['./client-details.component.scss']
})
export class ClientDetailsComponent implements OnInit {

  @ViewChild('tabGroup') tab: MatTabGroup;
  
  editClientData: boolean;
  tabProfessionalDocument = 1;

  clientInformation: IClientInformation;
  clientId: string;
  loading: boolean = false;
  isUserLeroy: boolean;
  currentStatus: string = 'Inactivo';
  filtersData: IFiltersDataClient;

  constructor(
    private _route: ActivatedRoute,
    private _clientService: ClientService
  ) { }

  ngOnInit() {
    this.isUserLeroy = Utilities.isUserLeroy();
    this.clientId = this._route.snapshot.paramMap.get('clientId');
    this.getClientById();
  }

  /**
   * Retrieve client information
   */
  getClientById(): void {
    this.loading = true
    this._clientService.getClientById(parseInt(this.clientId))
      .then(res => {
        this.clientInformation = res.data;
        if (this.clientInformation.isActive) {
          this.currentStatus = 'Activo'
        }
        this.filtersData= {
          clientId: Number(this.clientId),
          clientEmail: this.clientInformation.email,
          clientObject: this.clientInformation
        };
      });
  }

  openClientData($event) {
    if($event) {
      this.editClientData = true;
      this.tab.selectedIndex = this.tabProfessionalDocument;
    }
  }

  closeClientData($event) {
    if(!$event) {
      this.editClientData = false;
      this.tab.selectedIndex = 0;
      this.getClientById();
    }
  }
}
