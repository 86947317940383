<div class="basic-data-content">
  <div class="basic-data-content__card">
    <div class="card-title">
      <h4>{{'lm-journey-details.sections.lm-journey-basic-data.titles.dates' | translate}}</h4>
    </div>
    <div class="card-content">
      <div ><strong>{{'lm-journey-details.sections.lm-journey-basic-data.dates.date_creation' | translate}} </strong>{{data.creationDate | date: "yyyy-MM-dd HH:mm:ss"}}</div>
      <div><strong>{{'lm-journey-details.sections.lm-journey-basic-data.dates.date_reservation' | translate}} </strong>{{data.reservationDate | date: "yyyy-MM-dd HH:mm:ss"}}</div>
      <div><strong>{{'lm-journey-details.sections.lm-journey-basic-data.dates.date_finalization' | translate}} </strong>{{data.endDate | date: "yyyy-MM-dd HH:mm:ss"}}</div>
    </div>
  </div>

  <div class="basic-data-content__card">
    <div class="card-title">
      <h4>{{'lm-journey-details.sections.lm-journey-basic-data.titles.client' | translate}}</h4>
    </div>
    <div class="card-content">
      <div><strong>{{'lm-journey-details.sections.lm-journey-basic-data.client.name' | translate}} </strong> {{data.clientDataRest?.name}} {{data.clientDataRest?.lastName}}</div>
      <div><strong>{{'lm-journey-details.sections.lm-journey-basic-data.client.email' | translate}} </strong> {{data.clientDataRest?.email}}</div>
      <div><strong>{{'lm-journey-details.sections.lm-journey-basic-data.client.dni' | translate}} </strong> {{data.clientDataRest?.documentNumber}}</div>
      <div *ngFor="let phone of data.clientDataRest?.phones">
        <strong>{{'lm-journey-details.sections.lm-journey-basic-data.client.tel' | translate}} </strong>
        <ng-container *ngIf="phone.countryCode">({{phone.countryCode}})</ng-container>
        {{phone.number}}
      </div>


    </div>
  </div>

  <div class="basic-data-content__card">
    <div class="card-title">
      <h4>{{'lm-journey-details.sections.lm-journey-basic-data.titles.professional' | translate}}</h4>
    </div>
    <div class="card-content">
      <div><strong>{{'lm-journey-details.sections.lm-journey-basic-data.professional.name' | translate}} </strong> {{data.professionalDetails?.name}} {{data.professionalDetails?.lastName}}</div>
      <div><strong>{{'lm-journey-details.sections.lm-journey-basic-data.professional.email' | translate}} </strong> {{data.professionalDetails?.email}}</div>
      <div *ngFor="let phone of data.professionalDetails?.contactphones">
        <strong>{{'lm-journey-details.sections.lm-journey-basic-data.professional.tel' | translate}} </strong>
        <ng-container *ngIf="phone['countryCode']">({{phone['countryCode']}})</ng-container>
        {{phone.telephone}}
      </div>


    </div>
  </div>


  <div class="basic-data-content__card">
    <div class="card-title">
      <h4>{{'lm-journey-details.sections.lm-journey-basic-data.titles.service_management' | translate}}</h4>
    </div>
    <div class="card-content">
      <div><strong>{{'lm-journey-details.sections.lm-journey-basic-data.service_management.service' | translate}} </strong> {{data.requestOfferedServiceRest?.title}}</div>
      <div *ngIf="data.requestOfferedServiceRest && data.requestOfferedServiceRest.unit" ><strong>{{'lm-journey-details.sections.lm-journey-basic-data.service_management.unit' | translate}} </strong>{{data.requestOfferedServiceRest?.duration}} {{data.requestOfferedServiceRest?.unit}}</div>
      <div><strong>{{'lm-journey-details.sections.lm-journey-basic-data.service_management.type' | translate}}</strong> {{data.requestOfferedServiceRest?.typeDescription}} </div>
      <div><strong>{{'lm-journey-details.sections.lm-journey-basic-data.service_management.address' | translate}} </strong>{{data.requestAddressRest?.address}}
        {{data.requestAddressRest?.complementAddress}} {{data.requestAddressRest?.city}}
        {{data.requestAddressRest?.zipCode}} {{data.requestAddressRest?.province}}</div>

      <div *ngFor="let address of data.clientDataRest?.addresses">

        <div><strong>{{'lm-journey-details.sections.lm-journey-basic-data.service_management.address_fiscal' | translate}} </strong>{{address?.address}}
          {{address?.complementAddress}} {{address?.city}}
          {{address?.zipCode}} {{address?.province}}</div>

      </div>
      
      <div *ngIf="rejectObject">
        <strong>{{'lm-journey-details.sections.lm-journey-basic-data.service_management.rejectDate' | translate}}
        </strong>{{rejectObject?.createDate | date: "yyyy-MM-dd HH:mm:ss"}}
      </div>
      <div *ngIf="rejectObject && rejectObject?.requestRejectionMotiveRest">
        <strong>{{'lm-journey-details.sections.lm-journey-basic-data.service_management.rejectMessage' | translate}}          
        </strong> {{rejectObject?.requestRejectionMotiveRest?.motive}}
      </div>
      <div *ngIf="rejectObject && rejectObject?.operationRejectionMotiveRest">
        <strong>{{'lm-journey-details.sections.lm-journey-basic-data.service_management.rejectMessage' | translate}}          
        </strong> {{rejectObject?.operationRejectionMotiveRest?.motive}}
      </div>

    </div>
  </div>


  <div class="basic-data-content__card">
    <div class="card-title">
      <h4>{{'lm-journey-details.sections.lm-journey-basic-data.titles.price' | translate}}</h4>
    </div>
    <div *ngIf="data.operationPaymentBilling!=null" class="card-content">
      <table style="width: 100%;">
        <tr>
          <td>
            {{'lm-journey-details.sections.lm-journey-basic-data.price.services' | translate}}
          </td>
          <td>
            <div>
              <div class="currency"> {{data.operationPaymentBilling?.totalCostOriginal!=null ? getLocalNumber(data.operationPaymentBilling?.totalCostOriginal - data.operationPaymentBilling?.amountPriceProduct): 0}}</div>
            </div>
          </td>
        </tr>
        <tr>
          <td>{{'lm-journey-details.sections.lm-journey-basic-data.price.products' | translate}}</td>
          <td class="currency"> {{data.operationPaymentBilling?.amountPriceProduct!=null ? getLocalNumber(data.operationPaymentBilling?.amountPriceProduct): 0}} </td>
        </tr>
        <tr>
          <td>{{'lm-journey-details.sections.lm-journey-basic-data.coupon.discount' | translate}} 
            (<a *ngIf="couponData?.couponCode" class="coupon-code-link" (click)="showCouponVersion(couponData)">{{'lm-journey-details.sections.lm-journey-basic-data.coupon.coupon' | translate}}  {{couponData.couponCode}}</a>)
          </td>
          <td class="currency">
            <ng-container *ngIf="data.operationPaymentBilling?.amountDiscount; else noDiscountTemp">
              - {{data.operationPaymentBilling?.amountDiscount}}
            </ng-container>
            <ng-template #noDiscountTemp>0</ng-template>
          </td>
        </tr>
        <tr>
          <td class="underline"></td>
          <td class="underline"></td>
        </tr>
        <tr>
          <td><strong>{{'lm-journey-details.sections.lm-journey-basic-data.price.total_paid' | translate}}</strong></td>
          <td class="currency" *ngIf="!data.operationPaymentBilling['isCanceledLessTwentyFourHours']">
            {{data.operationPaymentBilling?.paymentClient!=null ? getLocalNumber(data.operationPaymentBilling?.paymentClient): 0}}</td>
          <td class="currency" *ngIf="data.operationPaymentBilling['isCanceledLessTwentyFourHours']">
            {{data.operationPaymentBilling?.paymentClient!=null ? getLocalNumber(data.operationPaymentBilling?.paymentClient): 0}} (*)</td>
        </tr>
        <tr>
          <td class="">{{'lm-journey-details.sections.lm-journey-basic-data.price.taxable_income' | translate}}</td>
          <td class="currency"> {{data.operationPaymentBilling?.taxBase!=null ? getLocalNumber(data.operationPaymentBilling?.taxBase): 0}}</td>
        </tr>
        <tr>
          <td class="">{{'lm-journey-details.sections.lm-journey-basic-data.price.taxes' | translate}} ({{data.operationPaymentBilling?.taxRate!=null ? data.operationPaymentBilling?.taxRate: 0}}%)</td>
          <td class="currency">{{getLocalNumber(data.operationPaymentBilling?.taxes)}}</td>
        </tr>
        <tr>
          <td class="">{{'lm-journey-details.sections.lm-journey-basic-data.price.commission' | translate}}</td>
          <td class="currency">{{data.operationPaymentBilling?.hogamiComission!=null ? getLocalNumber(data.operationPaymentBilling?.hogamiComission): 0}}</td>
        </tr>
        <tr *ngIf="data.operationPaymentBilling['isCanceledLessTwentyFourHours']">
          <td class="">{{'lm-journey-details.sections.lm-journey-basic-data.price.cancellation_fee' | translate}}</td>
          <td class="currency"> {{data.operationPaymentBilling['isCanceledLessTwentyFourHours']!=null ? getIsCanceled(data.operationPaymentBilling['isCanceledLessTwentyFourHours']): 0}} </td>
        </tr>
      </table>
    </div>

    <div  *ngIf="data.operationPaymentBilling==null" class="card-content">
      <table style="width: 100%;">
        <tr>
          <td>
            {{'lm-journey-details.sections.lm-journey-basic-data.price.services' | translate}}
          </td>
          <td>
            <div>
              <div class="currency"> {{data.requestBillingDataRest?.totalPriceOriginal!=null ? getLocalNumber(data.requestBillingDataRest?.totalPriceOriginal - data.requestBillingDataRest?.amountPriceProduct): 0}}</div>
            </div>
          </td>
        </tr>
        <tr>
          <td>{{'lm-journey-details.sections.lm-journey-basic-data.price.products' | translate}}</td>
          <td class="currency"> {{data.requestBillingDataRest?.amountPriceProduct!=null ? getLocalNumber(data.requestBillingDataRest?.amountPriceProduct): 0}} </td>
        </tr>
        <tr>
          <td>{{'lm-journey-details.sections.lm-journey-basic-data.coupon.discount' | translate}} 
            (<a *ngIf="couponData?.couponCode" class="coupon-code-link" (click)="showCouponVersion(couponData)">{{'lm-journey-details.sections.lm-journey-basic-data.coupon.coupon' | translate}}  {{couponData.couponCode}}</a>)
          </td>
          <td class="currency">
            <ng-container *ngIf="data.requestBillingDataRest?.amountDiscount; else noDiscountTemp">
              - {{data.requestBillingDataRest?.amountDiscount}}
            </ng-container>
            <ng-template #noDiscountTemp>0</ng-template>
          </td>
        </tr>
        <tr>
          <td class="underline"></td>
          <td class="underline"></td>
        </tr>
        <tr>
          <td><strong>{{'lm-journey-details.sections.lm-journey-basic-data.price.total_paid' | translate}}</strong></td>
          <td class="currency"> 0</td>
        </tr> 
        <tr>
          <td class="">{{'lm-journey-details.sections.lm-journey-basic-data.price.taxable_income' | translate}}</td>
          <td class="currency"> {{data.requestBillingDataRest?.taxBase!=null ? getLocalNumber(data.requestBillingDataRest?.taxBase): 0}}</td>
        </tr>
        <tr>
          <td class="">{{'lm-journey-details.sections.lm-journey-basic-data.price.taxes' | translate}} ({{data.requestBillingDataRest?.taxRate!=null ? data.requestBillingDataRest?.taxRate: 0}}%)</td>
          <td class="currency">{{getLocalNumber(data.requestBillingDataRest?.taxes)}}</td>
        </tr>
        <tr>
          <td class="">{{'lm-journey-details.sections.lm-journey-basic-data.price.commission' | translate}}</td>
          <td class="currency">{{data.requestBillingDataRest?.hogamiComission!=null ? getLocalNumber(data.requestBillingDataRest?.hogamiComission): 0}}</td>
        </tr>
      </table>
    </div>
  </div>



  <div class="basic-data-content__card">
    <div class="card-title">
      <h4>{{'lm-journey-details.sections.lm-journey-basic-data.titles.campaign' | translate}}</h4>
    </div>
    <div class="card-content" *ngIf="data.listRequestCampaigns===null">
      <div><strong>{{'lm-journey-details.sections.lm-journey-basic-data.campaign.title' | translate}} </strong>{{'lm-journey-details.sections.lm-journey-basic-data.campaign.web' | translate}}</div>
    </div>
    <div *ngFor="let campaign of data.listRequestCampaigns" class="card-content">

      <div><strong>{{'lm-journey-details.sections.lm-journey-basic-data.campaign.title' | translate}} </strong>{{campaign.campaignCode}}</div>
      <div><strong>{{'lm-journey-details.sections.lm-journey-basic-data.campaign.store' | translate}} </strong> {{campaign.storeName}}</div>
      <div><strong>{{'lm-journey-details.sections.lm-journey-basic-data.campaign.ldap' | translate}} </strong> {{campaign.user}}</div>

    </div>


  </div>

  <div class="basic-data-content__card">
    <div class="card-title">
      <h4>{{'lm-journey-details.sections.lm-journey-basic-data.titles.review' | translate}}</h4>
      <mat-icon aria-hidden="false" class="edit" *ngIf="isUserLeroy && data.ratingRest" 
        (click)="openEditRating()" title="{{'lm-journey-details.sections.lm-journey-basic-data.review.edit' | translate}}">edit</mat-icon>
    </div>
    <div class="card-content" *ngIf="data.ratingRest">
      <div class="card-content--grid">
        <div class="card-content--grid--left">
          <strong>{{'lm-journey-details.sections.lm-journey-basic-data.review.general' | translate}} </strong>
        </div>
        <div class="card-content--grid--right">
          <p class="card-content--grid--right--average">{{ data.ratingRest?.generalRating }}/5</p>
          <div class="card-content--grid--right--start">
            <figure class="card-content--grid--right--start--fig" *ngFor="let item of arrayGeneralRating">
              <img src="{{item.img}}" alt="" class="grid__item">
            </figure>
          </div>
        </div>
      </div>
      <hr>

      <div class="card-content--grid">
        <div class="card-content--grid--left">
          <strong>{{'lm-journey-details.sections.lm-journey-basic-data.review.punctual' | translate}} </strong>
        </div>
        <div class="card-content--grid--right">
          <p class="card-content--grid--right--average">{{ data.ratingRest?.punctual }}/5</p>
          <div class="card-content--grid--right--start">
            <figure class="card-content--grid--right--start--fig" *ngFor="let item of arrayPunctualRating">
              <img src="{{item.img}}" alt="" class="grid__item">
            </figure>
          </div>
        </div>
      </div>

      <div class="card-content--grid">
        <div class="card-content--grid--left">
          <strong>{{'lm-journey-details.sections.lm-journey-basic-data.review.behavior' | translate}} </strong>
        </div>
        <div class="card-content--grid--right">
          <p class="card-content--grid--right--average">{{ data.ratingRest?.behavior }}/5</p>
          <div class="card-content--grid--right--start">
            <figure class="card-content--grid--right--start--fig" *ngFor="let item of arrayBehaviorRating">
              <img src="{{item.img}}" alt="" class="grid__item">
            </figure>
          </div>
        </div>
      </div>

      <div class="card-content--grid">
        <div class="card-content--grid--left">
          <strong>{{'lm-journey-details.sections.lm-journey-basic-data.review.clean' | translate}} </strong>
        </div>
        <div class="card-content--grid--right">
          <p class="card-content--grid--right--average">{{ data.ratingRest?.clean }}/5</p>
          <div class="card-content--grid--right--start">
            <figure class="card-content--grid--right--start--fig" *ngFor="let item of arrayCleanRating">
              <img src="{{item.img}}" alt="" class="grid__item">
            </figure>
          </div>
        </div>
      </div>

      <div class="card-content--grid">
        <div class="card-content--grid--left">
          <strong>{{'lm-journey-details.sections.lm-journey-basic-data.review.satisfied' | translate}} </strong>
        </div>
        <div class="card-content--grid--right">
          <p class="card-content--grid--right--average">{{ data.ratingRest?.satisfied }}/5</p>
          <div class="card-content--grid--right--start">
            <figure class="card-content--grid--right--start--fig" *ngFor="let item of arraySatisfiedRating">
              <img src="{{item.img}}" alt="" class="grid__item">
            </figure>
          </div>
        </div>
      </div>

      <div class="card-content--grid">
        <div class="card-content--grid--left2">
          <strong>{{'lm-journey-details.sections.lm-journey-basic-data.review.comment' | translate}} </strong>
        </div>
        <div class="card-content--grid--right">
          <p class="card-content--grid--right--opinion">{{ data.ratingRest?.opinion }}</p>
        </div>
      </div>

      <div class="card-content--grid">
        <div class="card-content--grid--left">
          <strong>{{'lm-journey-details.sections.lm-journey-basic-data.review.date' | translate}} </strong>
        </div>
        <div class="card-content--grid--right">
          {{ getDateWithTimeZone(data.ratingRest?.createDateShow) }}
        </div>
      </div>

      <div class="card-content--grid" *ngIf="data.ratingRest.updateDateShow">
        <div class="card-content--grid--left">
          <strong>{{'lm-journey-details.sections.lm-journey-basic-data.review.date_update' | translate}} </strong>
        </div>
        <div class="card-content--grid--right">
          {{ getDateWithTimeZone(data.ratingRest?.updateDateShow) }}
        </div>
      </div>
    </div>

    <div class="card-content" *ngIf="!data.ratingRest">
      <p>{{'lm-journey-details.sections.lm-journey-basic-data.review.review_empty' | translate}}</p>
    </div>
  </div>
</div>
