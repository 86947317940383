import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'RoundUtilPipe'
})
export class RoundUtilPipe implements PipeTransform {

    transform(value: number, digits: number = 0): number {
        const round = Math.ceil;
        return round(value * (10 ** digits)) / (10 ** digits);
    }

}
