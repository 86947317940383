
<div *ngIf="formRating">
  <mat-progress-bar *ngIf="loading" mode="indeterminate" value="140"></mat-progress-bar>
  <form action="" [formGroup]="formRating" (ngSubmit)="submitForm()">
    <div class="basic-data-content">
      <div class="basic-data-content__card">

        <div class="card-content">
          <div class="card-content--grid">
            <div class="card-content--grid--left">
              <strong>{{'lm-journey-details.sections.lm-journey-update-rating.review.general' | translate}} </strong>
            </div>
            <div class="card-content--grid--right">
              <mat-form-field>
                <mat-label>{{'lm-journey-details.sections.lm-journey-update-rating.form.general' | translate}}</mat-label>
                <mat-select formControlName="generalRating" name="generalRating">
                  <mat-option *ngFor="let value of ratingValues" [value]="value">{{value}}</mat-option>
                </mat-select>
                <mat-error *ngIf="f.generalRating.errors?.pattern">
                  {{'lm-journey-details.sections.lm-journey-update-rating.form.error.message' | translate}}
                </mat-error>
              </mat-form-field>
            </div>
          </div><br>

          <div class="card-content--grid">
            <div class="card-content--grid--left">
              <strong>{{'lm-journey-details.sections.lm-journey-update-rating.review.punctual' | translate}} </strong>
            </div>
            <div class="card-content--grid--right">
              <mat-form-field>
                <mat-label>{{'lm-journey-details.sections.lm-journey-update-rating.form.punctual' | translate}}</mat-label>
                <mat-select formControlName="punctual" name="punctual">
                  <mat-option *ngFor="let value of ratingValues" [value]="value">{{value}}</mat-option>
                </mat-select>
                <mat-error *ngIf="f.punctual.errors?.pattern">
                  {{'lm-journey-details.sections.lm-journey-update-rating.form.error.message' | translate}}
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="card-content--grid">
            <div class="card-content--grid--left">
              <strong>{{'lm-journey-details.sections.lm-journey-update-rating.review.behavior' | translate}} </strong>
            </div>
            <div class="card-content--grid--right">
              <mat-form-field>
                <mat-label>{{'lm-journey-details.sections.lm-journey-update-rating.form.behavior' | translate}}</mat-label>
                <mat-select formControlName="behavior" name="behavior">
                  <mat-option *ngFor="let value of ratingValues" [value]="value">{{value}}</mat-option>
                </mat-select>
                <mat-error *ngIf="f.behavior.errors?.pattern">
                  {{'lm-journey-details.sections.lm-journey-update-rating.form.error.message' | translate}}
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="card-content--grid">
            <div class="card-content--grid--left">
              <strong>{{'lm-journey-details.sections.lm-journey-update-rating.review.clean' | translate}} </strong>
            </div>
            <div class="card-content--grid--right">
              <mat-form-field>
                <mat-label>{{'lm-journey-details.sections.lm-journey-update-rating.form.clean' | translate}}</mat-label>
                <mat-select formControlName="clean" name="clean">
                  <mat-option *ngFor="let value of ratingValues" [value]="value">{{value}}</mat-option>
                </mat-select>
                <mat-error *ngIf="f.clean.errors?.pattern">
                  {{'lm-journey-details.sections.lm-journey-update-rating.form.error.message' | translate}}
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="card-content--grid">
            <div class="card-content--grid--left">
              <strong>{{'lm-journey-details.sections.lm-journey-update-rating.review.satisfied' | translate}} </strong>
            </div>
            <div class="card-content--grid--right">
              <mat-form-field>
                <mat-label>{{'lm-journey-details.sections.lm-journey-update-rating.form.satisfied' | translate}}</mat-label>
                <mat-select formControlName="satisfied" name="satisfied">
                  <mat-option *ngFor="let value of ratingValues" [value]="value">{{value}}</mat-option>
                </mat-select>
                <mat-error *ngIf="f.satisfied.errors?.pattern">
                  {{'lm-journey-details.sections.lm-journey-update-rating.form.error.message' | translate}}
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="card-content--grid">
            <div class="card-content--grid--left2">
              <strong>{{'lm-journey-details.sections.lm-journey-update-rating.form.comment' | translate}} </strong>
            </div>
            <div class="card-content--grid--right">
              <mat-form-field class="textarea">
                <mat-label>{{'lm-journey-details.sections.lm-journey-update-rating.form.comment' | translate}}</mat-label>
                <textarea matInput rows="5" cols="15" formControlName="opinion" name="opinion" maxlength="500"
              placeholder="{{'lm-journey-details.sections.lm-journey-update-rating.form.comment' | translate}}"></textarea>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="footer-data">
          <div class="section">
            <button mat-raised-button color="primary" type="submit" [disabled]="loading" class="button_submit">
              <mat-icon>save</mat-icon> {{'lm-journey-details.sections.lm-journey-update-rating.form.buttons.submit' | translate}}
            </button>
          </div>
          <div class="section">
            <button mat-raised-button (click)="closeTab()">
              <mat-icon>cancel</mat-icon> {{'lm-journey-details.sections.lm-journey-update-rating.form.buttons.cancel' | translate}}
            </button>
          </div>
          <div class="section">
          </div>
        </div>
      </div>
    </div>
  </form>
</div>