import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { IClientFilterData, IClientInformation } from "../models/lm-clients.model";
import { Observable } from "rxjs";


@Injectable({
    providedIn: 'root'
})

export class ClientService {

    private BASE_URL = environment.apiHosts;

    constructor(private _http: HttpClient) { }

    getClients(page: number = 0, pageSize: number = 0, payload: IClientFilterData): Observable<any> {
        const head = new HttpHeaders({});
        return this._http.post<any>(`${this.BASE_URL}/backoffice-server/api/v1/clients?page=${page}&size=${pageSize}`,
            payload,
            { headers: head },
        );
    }

    retrieveAllClientTypes(): Promise<any> {
        const head = new HttpHeaders(
            {}
        );
        return this._http.get(`${this.BASE_URL}/backoffice-server/api/v1/client-type`,
            { headers: head }).toPromise();
    }

    retrieveAllClientAddressTypes(): Promise<any> {
        const head = new HttpHeaders(
            {}
        );
        return this._http.get(`${this.BASE_URL}/backoffice-server/api/v1/address-type`,
            { headers: head }).toPromise();
    }

    getClientById(clientId: number): Promise<any> {
        const head = new HttpHeaders(
            {}
        );
        return this._http.get(`${this.BASE_URL}/backoffice-server/api/v1/clients/${clientId}`,
            { headers: head }).toPromise();
    }

    updateClient(clientBody: IClientInformation): Observable<any> {
        const head = new HttpHeaders({});
        return this._http.put<any>(`${this.BASE_URL}/backoffice-server/api/v1/clients/${clientBody.id}`,
            clientBody,
            { headers: head },
        );
    }

    retrieveDocumentTypes(): Promise<any> {
        const head = new HttpHeaders(
            {}
        );
        return this._http.get(`${this.BASE_URL}/backoffice-server/api/v1/clients/documents`,
            { headers: head }).toPromise();
    }

}