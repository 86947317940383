<div [style.display]="(!transferDiv) ? 'block' : 'none'">
  <div class="divCenter">
    <h2>
      <p>
        {{'modals.modal-sepa.title' | translate}}
      </p>
    </h2>
    <p>{{'modals.modal-sepa.subTitle' | translate}}</p>
  </div>

  <div class="grid">
    <label style="cursor: default;" class="grid-input">{{'modals.modal-sepa.labels.import' | translate}} </label>
    <label style="cursor: default;" class="grid-texts"> {{importe}} EUR </label>
  </div>
  <div class="grid">
    <label style="cursor: default;" class="grid-input">{{'modals.modal-sepa.labels.operation' | translate}} </label>
    <label style="cursor: default;" class="grid-texts"> {{operation.id}} &nbsp;&nbsp;&nbsp;&nbsp; Coste: {{operation.totalCost}}</label>
  </div>
  <div class="grid">
    <label style="cursor: default;" class="grid-input">{{'modals.modal-sepa.labels.professional' | translate}} </label>
    <label style="cursor: default;" class="grid-texts"> {{professional}} </label>
  </div>
  <div class="grid">
    <label style="cursor: default;" class="grid-input">{{'modals.modal-sepa.labels.email' | translate}} </label>
    <label style="cursor: default;" class="grid-texts"> {{professionalEmail}} </label>
  </div>
  <div class="grid">
    <label style="cursor: default;" class="grid-input"> {{'modals.modal-sepa.labels.concept' | translate}} </label>
    <label style="cursor: default;" class="grid-texts"> {{concepto}} </label>
  </div>
  <div class="grid">
    <label style="cursor: default;" class="grid-input"> {{'modals.modal-sepa.labels.update_status' | translate}} </label>
    <input type="checkbox" [checked]="completed" class="grid-texts" disabled="disabled"/>
  </div>
  <div class="grid">
    <label style="cursor: default;" class="grid-input"> {{'modals.modal-sepa.labels.update_endtime' | translate}} </label>
    <input type="checkbox" [checked]="endTime" class="grid-texts" disabled="disabled"/>
  </div>
  <hr>
  
  <div class="meter">
    <span style="width:80%;"><span class="progress" [style.display]="inProgress===true ? 'block' : 'none'"></span></span>
  </div>
  <p class="greenText" [style.display]="(transferMsg === '') ? 'none' : 'block'">{{transferMsg}}</p>

  <div>
    <div class="grid">
      <button type="submit" class="grid__button_back" (click)="dismiss()" [disabled]="inProgress">{{'modals.modal-sepa.buttons.cancel' | translate}}</button>
      <button type="submit" class="grid__button" (click)="submit()" [disabled]="inProgress">{{'modals.modal-sepa.buttons.continu' | translate}}</button>
    </div>
  </div>
</div>

<div [style.display]="(transferDiv) ? 'block' : 'none'">
  <div class="divCenter">
    <h1>{{transferMsg}}</h1>
    <br><br>
    <button type="button" (click)="goContinue()" class="button_continue">{{'modals.modal-sepa.buttons.continu' | translate}}</button>
  </div>
</div>