<div *ngIf="!success && !fail">
  <h1 mat-dialog-title>{{'lm-offered-services.lm-delete-offered-service-modal.title' | translate}}</h1>
  <div mat-dialog-content>
    <p>{{'lm-offered-services.lm-delete-offered-service-modal.text_info' | translate}} <b>{{data.title}}</b> de <b>{{data.province.provinceName}}</b></p>
    <div>
      <div class="grid">
        <button type="submit" class="grid__button_back" (click)="dismiss()">{{'lm-offered-services.lm-delete-offered-service-modal.buttons.cancel' | translate}}</button>
        <button type="submit" class="grid__button" (click)="submit()" [disabled]="submitted">{{'lm-offered-services.lm-delete-offered-service-modal.buttons.confirm' | translate}}</button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="success || fail">
  <div mat-dialog-content>
    <p *ngIf="success">{{'lm-offered-services.lm-delete-offered-service-modal.message_success.part1' | translate}} <b>{{data.title}}</b> {{'lm-offered-services.lm-delete-offered-service-modal.message_success.part2' | translate}} <b>{{data.province.provinceName}}</b></p>
    <p *ngIf="fail">{{'lm-offered-services.lm-delete-offered-service-modal.message_success.part3' | translate}} <b>{{data.title}}</b> {{'lm-offered-services.lm-delete-offered-service-modal.message_success.part4' | translate}} <b>{{data.province.provinceName}}</b> {{'lm-offered-services.lm-delete-offered-service-modal.message_success.part5' | translate}}</p>
    <div>
      <div class="grid">
        <button class="grid__button_back" (click)="dismiss()">{{success ? _buttons_label.close:_buttons_label.cancel}}</button>
        <button *ngIf="!success" type="submit" class="grid__button" (click)="submit()">{{'lm-offered-services.lm-delete-offered-service-modal.buttons.retry' | translate}}</button>
      </div>
    </div>
  </div>
</div>