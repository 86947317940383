import { NgModule } from "@angular/core";
import { MessageDialogComponent } from './message-dialog.component';
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
    declarations: [
        MessageDialogComponent
    ],
    imports: [
        TranslateModule
    ],
    exports: [MessageDialogComponent]
})
export class MessageDialogModule { }