import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PaycometOperationsComponent } from './paycomet/paycomet-operations/paycomet-operations.component';
import { BillingComponent } from "./billing/billing.component"
import { RequestsComponent } from './requests/requests.component';
import { OperationsComponent } from './operations/operations.component';

const routes: Routes = [
        {
            path: '',
            pathMatch: 'full',
            redirectTo: 'home'
        },
        {
         path: "requests",
         component: RequestsComponent
        },
        {
           path: "operations",
           component: OperationsComponent
        },
        {
            path: 'paycomet-operations',
            component: PaycometOperationsComponent
        },
        {
            path: "billing",
            component: BillingComponent
        }
];

@NgModule( {
    imports: [ RouterModule.forChild( routes ) ],
    exports: [ RouterModule ]
} )

export class LmTransfersRoutingModule {}
