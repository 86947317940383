<div style="display:inline !important;">
    <app-lm-filters-professional-details 
    [loading]="loading" [filterType]="filterType"
    [filtersData]="filtersData"
    [loading]="loading"
    (filtersApply)="getFiltersToStatus($event)">
    </app-lm-filters-professional-details>
</div>

<div class="content">
    <div class="example-container ">
        <mat-table mat-table [dataSource]='timeLineHistories' class="mat-elevation-z8">

            <ng-container matColumnDef="initialStatus">
                <mat-header-cell *matHeaderCellDef>{{'lm-professional.lm-professional-status.cells.initial_status' | translate}}</mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.endStatus}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="finalStatus" >
                <mat-header-cell *matHeaderCellDef>{{'lm-professional.lm-professional-status.cells.final_status' | translate}}</mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.initialStatus}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="startDate">
                <mat-header-cell *matHeaderCellDef>{{'lm-professional.lm-professional-status.cells.start_date' | translate}}</mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.statusStartDate | date: "yyyy-MM-dd HH:mm:ss"}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="endDate">
                <mat-header-cell *matHeaderCellDef>{{'lm-professional.lm-professional-status.cells.end_date' | translate}}</mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.statusEndDate | date: "yyyy-MM-dd HH:mm:ss"}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="createdBy">
                <mat-header-cell *matHeaderCellDef>{{'lm-professional.lm-professional-status.cells.changed_role' | translate}}</mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.responsible?.role}} - {{element.responsible?.email}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="comments">
                <mat-header-cell *matHeaderCellDef>{{'lm-professional.lm-professional-status.cells.comments' | translate}}</mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.comments}}</mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>

        <mat-paginator [length]="totalElements" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25, 100]"
            (page)="getPaginatorData($event)">
        </mat-paginator>
    </div>
</div>