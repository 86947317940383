import { Component, OnInit, Inject, Optional, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-professional-dialog',
  template: `<router-outlet></router-outlet>`,
  styles: [`

  `]
})
export class ProfessionalModalComponent implements OnInit, OnDestroy {

  constructor(public dialogRef: MatDialogRef<ProfessionalModalComponent>, private router: Router,
  @Optional() @Inject(MAT_DIALOG_DATA) public data:any) {

  }

  ngOnInit() {
    this.router.navigate(['/professionals/' + this.data.professionalId])
  }

  ngOnDestroy(): void {
    this.data = null;
    this.router.navigate(['/transfers/operations'])
  }

}