import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { ClientsComponent } from './clients/clients.component';
import { ClientDetailsComponent } from './client-details/client-details.component';

const routes: Routes = [
    {
        path: "",
        component: ClientsComponent,
        canActivate: [AuthGuard]
    },
    {
        path: ":clientId",
        component: ClientDetailsComponent,
        canActivate: [ AuthGuard ]
    },
    {
        path: '**',
        pathMatch: 'full',
        redirectTo: 'clients'
    }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class LmClientsRoutingModule { }