export interface LmOfferedService {
    id: number;
    parentId?: number;
    description: string;
    code: string,
    level: number;
    order: number;
    landingPage?: string;
    show: number;
    type?: number;
    typeName?: string;
    is_active: boolean;
    parentName?: string;
    parentCode?: string;
    offeredServiceChildren: LmOfferedServiceChildren[];
    metaDescription?: string;
  }
  
  export interface LmOfferedServiceChildren {
    id: number;
    parentId: number;
    description?: string;
    code?: string;
    order: number;
    landingPage?: string;
    level?: number;
    metaDescription?: string;
    show: number;
    type?: number;
    typeName?: string;
    is_active: boolean;
    parentName: string;
    parentCode: string;
    chatQuestion?: string;
    rating?: LmRating;
    valuations?: number;
    no_comments?: number;
  }

  export interface LmRating {
    averageGeneralRating?: number;
    averagePunctual?: number;
    averageBehavior?: number;
    averageSatisfied?: number;
    averageClean?: number;
    totalAverage?: number;
  }
  
  export class Group {
    nivel: number = 0;
    expanded: boolean = false;
    totalCounts: number = 0;
    parentName: string = '';
    id?: number = 0;
    description?: string = '';
    code?: string = '';
    landingPage?: string = '';
    order?: number = 0;
    parentCode?: string = '';
    is_active?: string = 'NO';
    type?: string = '';
  }
  
  export interface LmOfferedServiceType {
    id?: number;
    type?: string;
    description?: string;
  }
  
  export interface LmOfferedServiceLevel {
    id: number;
    code: string;
    description: string;
    level: number;
    landingPage: string;
    show: number;
    is_active: boolean;
    parentId: number;
  }
  
  export class LmOfferedServicesForSort {
    id?: number;
    parentId?: number;
    description?: string;
    code?: string;
    order?: number;
    is_active?: boolean;
    parentCode?: string;
    childrens?: LmOfferedServicesForSort[]
  }