<div class="popup-full" *ngIf="sessionExpired">
    <div class="popup">
        <app-message-dialog-component></app-message-dialog-component>
    </div>
</div>

<mat-toolbar [ngClass]="{'mat-toolbar-z': sessionExpired}" color="primary"  >
    <mat-toolbar-row class="toolbar-logo" [ngClass]="isEnvironmentProd ? 'header_pro_env' : 'header_pre_env'" >
        <span class="top_logo"><img src="assets/img/Hogami_logo_black.svg" alt="Hogami Logo"></span>
        <span><h2>{{'shared.header.title' | translate}} </h2></span> <span *ngIf="!isEnvironmentProd">&nbsp; {{'shared.header.pre_qa' | translate}}</span>
        <span class="spacer"></span>
        <button  *ngIf="isLoggedIn()" mat-button (click)="logout()">
            <mat-icon class="mat-18">logout</mat-icon>
            {{'shared.header.logout' | translate}}
        </button>
    </mat-toolbar-row>
    <mat-toolbar-row *ngIf="isLoggedIn()" class="toolbar-menu">
        <span class="menu-spacer"></span>
        <div  class="menu-buttons-row">
            <a mat-button [routerLink]="'/home'"  routerLinkActive="mat-primary"> {{'shared.header.menu.home' | translate}} </a>
            <a mat-button [routerLink]="'/transfers/requests'"  routerLinkActive="mat-primary"> {{'shared.header.menu.request' | translate}} </a>
            <a mat-button [routerLink]="'/transfers/operations'"  routerLinkActive="mat-primary"> {{'shared.header.menu.operations' | translate}} </a>
            <a mat-button [routerLink]="'/clients'"  routerLinkActive="mat-primary"> {{'shared.header.menu.clients' | translate}} </a>
            <a mat-button [routerLink]="'/professionals'"  routerLinkActive="mat-primary"> {{'shared.header.menu.professional' | translate}} </a>
            <a mat-button *ngIf="isUserHogami()" [routerLink]="'/offered-services'"  routerLinkActive="mat-primary"> {{'shared.header.menu.services' | translate}} </a>
            <a mat-button [routerLink]="'/transfers/billing'"  routerLinkActive="mat-primary"> {{'shared.header.menu.transaction_to_be_invoiced' | translate}} </a>
            <a mat-button [routerLink]="'/transfers/paycomet-operations'"  routerLinkActive="mat-primary"> {{'shared.header.menu.paycomet_payment' | translate}} </a>
            <button mat-button [matMenuTriggerFor]="menu" style="float:right;" (click)="onToolsClick()">{{'shared.header.menu.maintenance_task' | translate}}</button>
            <mat-menu #menu="matMenu">
                <span *ngFor="let item of options">
                    <button mat-menu-item  *ngIf="item.isInternalUser"
                            [routerLink]="item.routerLink"
                            routerLinkActive="mat-primary">
                        <span> {{ item.title }}</span>
                    </button>
                </span>
            </mat-menu>
        </div>
    </mat-toolbar-row>
</mat-toolbar>
