import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LmUnbanProfessionalRoutingModule } from './lm-unban-professional-routing.module';
import { LmUnbanProfessionalComponent } from './lm-unban-professional/lm-unban-professional.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
@NgModule({
  declarations: [LmUnbanProfessionalComponent],
  imports: [
    CommonModule,
    LmUnbanProfessionalRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatCardModule,
    MatIconModule,
    MatSelectModule,
    TranslateModule
  ]
})
export class LmUnbanProfessionalModule { }
