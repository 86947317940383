import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../app/core/guards/auth.guard';
import { ELmSharedPaths } from './shared/models/shared.models';
import { HomeComponent } from './components/home/home.component';
import { LmLoginComponent } from './pages/lm-login/component/lm-login.component';


const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        component: LmLoginComponent,
        canActivate: [ AuthGuard ]
    },
    {
        path: 'login',
        loadChildren: () => import('./pages/lm-login/lm-login.module').then(m => m.LmLoginModule)
    },
    {
        path: 'home',
        pathMatch: 'full',
        component: HomeComponent,
        canActivate: [ AuthGuard ]
    },
	{
		path: 'transfers',
		loadChildren: () => import('./pages/lm-transfers/lm-transfers.module').then(m => m.LmTransferModule),
		canActivate: [ AuthGuard ]
	},
	{
		path: ELmSharedPaths.RESETPASSWORD,
		loadChildren: () => import('./pages/lm-resetpassword/lm-resetpassword.module').then(m => m.LmResetpasswordModule),
		canActivate: [ AuthGuard ]
	},
	{
		path: ELmSharedPaths.CREATE_NEW_USER,
		loadChildren: () => import('./pages/lm-create-user/lm-create-user.module').then(m => m.LmCreateUserModule),
		canActivate: [ AuthGuard ]
	},
	{
		path: ELmSharedPaths.BAN_PROFESSIONAL,
		loadChildren: () => import('./pages/lm-ban-professional/lm-ban-professional.module').then(m => m.LmBanProfessionalModule),
		canActivate: [ AuthGuard ]
	},
	{
		path: ELmSharedPaths.UNBAN_PROFESSIONAL,
		loadChildren: () => import('./pages/lm-unban-professional/lm-unban-professional.module').then(m => m.LmUnbanProfessionalModule),
		canActivate: [ AuthGuard ]
	},
	{
		path: ELmSharedPaths.TRANSFERPAYBY,
		loadChildren: () => import('./pages/lm-transfer-payby/lm-transfer-payby.module').then(m => m.LmTransferPaybyModule),
		canActivate: [ AuthGuard ]
	},
	{
		path: ELmSharedPaths.TRANSFERSEPA,
		loadChildren: () => import('./pages/lm-transfer-sepa/lm-transfer-sepa.module').then(m => m.LmTransferSepaModule),
		canActivate: [ AuthGuard ]
	},
	{
		path: ELmSharedPaths.CANCEL_SEPA,
		loadChildren: () => import('./pages/lm-cancel-sepa/lm-cancel-sepa.module').then(m => m.LmCancelSepaModule),
		canActivate: [ AuthGuard ]
	},
	/*{
		path: ELmSharedPaths.BLOCK_PROFESSIONAL,
		loadChildren: "./pages/lm-block-professional/lm-block-professional.module#LmBlockProfessionalModule",
		canActivate: [ AuthGuard ]
	},*/
	{
		path: ELmSharedPaths.OFFERED_SERVICES,
		loadChildren: () => import('./pages/lm-offered-services/lm-offered-services.module').then(m => m.LmOfferedServicesModule),
		canActivate: [ AuthGuard ]
	},
	{
		path: ELmSharedPaths.CLIENTS,
		loadChildren: () => import('./pages/lm-clients/lm-clients.module').then(m => m.LmClientsModule),
		canActivate: [ AuthGuard ]
	},
	{
		path: ELmSharedPaths.PROFESSIONALS,
		loadChildren: () => import('./pages/lm-professionals/lm-professionals.module').then(m => m.LmProfessionalsModule),
		canActivate: [ AuthGuard ]
	},
	{
        path: "work-details/:requestId",
		loadChildren: () => import('./pages/journey-details/lm-journey-details.module').then(m => m.LmJourneyDetailsModule),
		canActivate: [ AuthGuard ]
    },
	{
		path: '**',
		redirectTo: ''
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
