<div>
    <div class="example-container ">
        <mat-table mat-table [dataSource]='data' class="mat-elevation-z8">
            <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef>{{'lm-journey-details.sections.lm-journey-transactions.cells.id' | translate}}</mat-header-cell>
                <mat-cell *matCellDef="let element"><strong>{{element.operationID}}</strong>
                </mat-cell>
            </ng-container>

            <ng-container class="spacing" matColumnDef="date">
                <mat-header-cell *matHeaderCellDef>{{'lm-journey-details.sections.lm-journey-transactions.cells.date' | translate}}</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{getDateWithTimeZone(element.paycometTimestamp)}}</mat-cell>

            </ng-container>

            <ng-container class="spacing" matColumnDef="concept">
                <mat-header-cell *matHeaderCellDef>{{'lm-journey-details.sections.lm-journey-transactions.cells.concept' | translate}}</mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.paycometConcept}}<br />
                    {{element.requestId}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="operationName" class="spacing">
                <mat-header-cell *matHeaderCellDef>{{'lm-journey-details.sections.lm-journey-transactions.cells.name_of_operation' | translate}}</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.paycometOperationName}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="value" class="spacing">
                <mat-header-cell *matHeaderCellDef>{{'lm-journey-details.sections.lm-journey-transactions.cells.value' | translate}}</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.paycometAmount}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="comission" class="spacing">
                <mat-header-cell *matHeaderCellDef>{{'lm-journey-details.sections.lm-journey-transactions.cells.commission' | translate}}</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.paycometComission}} {{element.currency}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="reference" class="spacing">
                <mat-header-cell *matHeaderCellDef>{{'lm-journey-details.sections.lm-journey-transactions.cells.reference' | translate}}</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.paycometReference}} {{element.currency}} </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <mat-paginator [length]="totalElements" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25, 100]"
            (page)="getPaginatorData($event)">
        </mat-paginator>
    </div>
</div>