<div [style.display]="(!transferDiv) ? 'block' : 'none'">
  <div class="divCenter">
    <h2>
      <p>
        {{'modals.modal-cancel-sepa.title' | translate}}
      </p>
    </h2>
    <p>{{'modals.modal-cancel-sepa.subTitle' | translate}}</p>
  </div>

  <div class="grid">
    <label style="cursor: default;" class="grid-input">{{'modals.modal-cancel-sepa.labels.operation' | translate}} </label>
    <label style="cursor: default;" class="grid-texts"> {{operation.id}} </label>
  </div>
  <div class="grid">
    <label style="cursor: default;" class="grid-input"> {{'modals.modal-cancel-sepa.labels.service' | translate}} </label>
    <label style="cursor: default;" class="grid-texts"> {{service}} </label>
  </div>
  <div class="grid">
    <label style="cursor: default;" class="grid-input"> {{'modals.modal-cancel-sepa.labels.cliente' | translate}} </label>
    <label style="cursor: default;" class="grid-texts"> {{client}} </label>
  </div>
  <div class="grid">
    <label style="cursor: default;" class="grid-input"> {{'modals.modal-cancel-sepa.labels.paycomet_ref' | translate}} </label>
    <label style="cursor: default;" class="grid-texts"> {{paycometReference}} </label>
  </div>
  <div [hidden]="totalPaid==null">
    <label style="cursor: default;" class="grid-input2">{{'modals.modal-cancel-sepa.labels.import' | translate}} {{totalPaid}} EUR</label>
  </div>
  <hr>
  
  <div class="meter">
    <span style="width:80%;"><span class="progress" [style.display]="inProgress===true ? 'block' : 'none'"></span></span>
  </div>
  <p class="greenText" [style.display]="(transferMsg === '') ? 'none' : 'block'">{{transferMsg}}</p>

  <div>
    <div class="grid">
      <button type="submit" class="grid__button_back" (click)="dismiss()" [disabled]="inProgress">{{'modals.modal-cancel-sepa.buttons.cancel' | translate}}</button>
      <button type="submit" class="grid__button" (click)="submit()" [disabled]="inProgress">{{'modals.modal-cancel-sepa.buttons.continu' | translate}}</button>
    </div>
  </div>
</div>

<div [style.display]="(transferDiv) ? 'block' : 'none'">
  <div class="divCenter">
    <h1>{{transferMsg}}</h1>
    <br><br>
    <button type="button"  (click)="goContinue()" class="grid__button">{{'modals.modal-sepa.buttons.continu' | translate}}</button>
  </div>
</div>