import { Component, Input, OnInit } from '@angular/core';
import { IFilters, IFiltersDataClient, IOperationsRequestsFilters } from '../../models/lm-clients.model';
import { RequestService } from 'src/app/pages/lm-transfers/services/request.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { TransferService } from 'src/app/pages/lm-transfers/services/transfers.service';
import { CouponDialogComponent } from 'src/app/pages/lm-transfers/sections/coupon-dialog/coupon-dialog.component';
import { CouponVersion, IRequestContent } from 'src/app/pages/lm-transfers/models/lm-transfers.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-lm-client-requests',
  templateUrl: './lm-client-requests.component.html',
  styleUrls: ['./lm-client-requests.component.scss', '../../../../pages/utils/mat-table.scss']
})
export class LmClientRequestsComponent implements OnInit {

  _translationSubscription: Subscription;
  @Input() filtersData: IFiltersDataClient;

  _messages: { [key: string]: string };
  filterType: string = "clientRequests";
  filters: IFilters = {};
  content: IRequestContent[] = [];
  page: number = 0;
  totalElements: number = 0;
  pageSize: number = 10;
  loading: boolean = false;
  operationsRequestsFilters: IOperationsRequestsFilters;

  couponVersion: CouponVersion;
  dialogRef: MatDialogRef<CouponDialogComponent>;

  displayedColumns: string[] = ['id', 'requestStatus', 'offeredService', 'clientDetails', 'createDate', 'operationDate', 'endDate',
    'professionalDetails', 'requestAddress', 'campaigns', 'storeName', 'comercialId', 'fill'];

  constructor(private _requestService: RequestService,
    private _translateService: TranslateService,
    private _transferService: TransferService,
    public dialog: MatDialog) {
    this._translationSubscription = this._translateService
      .get('lm-client.lm-client-requests.logic')
      .subscribe((res: { [key: string]: string }) => {
        this._messages = res;
      });
  }

  ngOnInit() {
    if (this.filters !== null && this.filters.isFilterBuilt == true) {
      this.getRequests();
    }
  }

  /**
   * Retrieve request by filter
   */
  getRequests() {
    this.operationsRequestsFilters = this.filters.operationsRequestsFilters;
    this.loading = true;
    this.content = [];
    this.totalElements = 0;
    this._requestService.getRequests(this.page, this.pageSize, this.operationsRequestsFilters).subscribe({
      next: this.handleResponse.bind(this),
      error: this.handleError.bind(this)
    }).add(() => {
      this.loading = false;
    });
  }

  handleResponse(response: any): void {
    this.content = response.data.content;
    this.totalElements = response.data.totalElements;
    if (this.totalElements === 0) {
      alert(this._messages['no_result_found']);
    }
  }

  handleError(error: any): void {
    this.content = [];
  }

  getPaginatorData(event) {
    this.page = event['pageIndex'];
    this.pageSize = event['pageSize'];
    this.getRequests();
  }

  getFiltersToRequests(filtersApply) {
    this.filters = filtersApply;
    this.page = 0;
    this.getRequests();
  }

  getDateWithTimeZone(d) {
    if (d) {
      const time = new Date(d).getTime()
      const offset = new Date().getTimezoneOffset();
      return new Date(time - offset * 60000).toLocaleString()
    }
    return d
  }

  isEmpty(str) {
    return (!str || str.length === 0);
  }

  /**
   * Show modal with coupon information
   * @param couponInfo Coupon Info
   */
  showCouponVersion(couponInfo): void {
    this._transferService.getCouponVersion(couponInfo['couponVersionId']).subscribe(res => {
      this.couponVersion = res['data'];
      if (this.dialogRef == null) {
        this.dialogRef = this.dialog.open(CouponDialogComponent, {
          width: '400px',
          height: 'auto',
          data: {
            coupon: this.couponVersion
          }
        });

        this.dialogRef.afterClosed().subscribe(result => {
          this.dialogRef = null;
        });
      }
    });
  }

}
