import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatMenuModule } from '@angular/material/menu';
import { SharedModule } from './shared/shared.module';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { TransferInterceptor } from './core/interceptors/transfer.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { HomeComponent } from './components/home/home.component';
import { LmDeleteOfferedServiceModalComponent } from './pages/lm-offered-services/components/lm-delete-offered-service-modal/lm-delete-offered-service-modal.component';
import { LayoutModule } from '@angular/cdk/layout';
import { CardComponent } from './assets/card/card.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CouponDialogComponent } from './pages/lm-transfers/sections/coupon-dialog/coupon-dialog.component';
import { MessageDialogModule } from './pages/modals/message-dialog/message-dialog.module';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MAT_DATE_LOCALE, MatOptionModule } from '@angular/material/core';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTreeModule } from '@angular/material/tree';
import { LmPagesModule } from './pages/pages.module';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}
@NgModule({
	declarations: [
		AppComponent,
		HeaderComponent,
		FooterComponent,
		LmDeleteOfferedServiceModalComponent,
		HomeComponent,
		CardComponent,
		CouponDialogComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		CommonModule,
		LmPagesModule,
		MatPaginatorModule,
		HttpClientModule,
		TranslateModule.forRoot( {
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [ HttpClient],
			},
		} ),
		CommonModule,
		BrowserAnimationsModule,
		MatToolbarModule,
		MatButtonModule,
		MatDialogModule,
		MatSelectModule,
		MatOptionModule,
		MatMenuModule,
		FormsModule,
        ReactiveFormsModule,
		SharedModule,
		MatCheckboxModule,
		MatGridListModule,
		MatCardModule,
		MatIconModule,
		LayoutModule,
		MatTableModule,
		MatSortModule,
		MatProgressSpinnerModule,
		MatProgressBarModule,
		MatAutocompleteModule,
		MatTreeModule,
		MessageDialogModule
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TransferInterceptor,
			multi: true
		},
		{
			provide: MAT_DATE_LOCALE, 
			useValue: 'es-ES'
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
