<div class="content">

  <mat-card-content>
    <div class="service-details">
      <div class="items">
        <img *ngIf="data.image" src={{data.image}} alt="service-image" />
      </div>
      <div class="items">
        <div class="item">
          <div class="label">{{data.code}}</div>
        </div>
        <div class="item">
          <div class="label">{{data.title}}</div>
        </div>
        <div class="item">
          <div class="label">{{data.parentName}}</div>
        </div>
      </div>
      <div class="items"></div>
      <div class="items"></div>
    </div>
  </mat-card-content>
</div>
<hr>
<mat-progress-bar *ngIf="loading" mode="indeterminate" value="140"></mat-progress-bar>

<div class="content" *ngIf="!hierarchyNotExist">
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
      <div class="mat-tree-node">
        <button *ngIf="node.levelRests" mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.levelInfoRest.levelLabel" >
          <mat-icon class="mat-icon-rtl-mirror" *ngIf="node.levelRests.length>0">
            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
        </button>
        <div class="divChildrens" style="width: 100%;">
          <app-lm-tree-data-hierarchy [node]="node" (updateCostEvent)="updateCost($event)" [(disableEdit)]="disableEdit"></app-lm-tree-data-hierarchy>
        </div>
      </div>

      <div [class.example-tree-invisible]="!treeControl.isExpanded(node)" role="group">
        <ng-container matTreeNodeOutlet></ng-container>
      </div>
    </mat-nested-tree-node>
  </mat-tree>
</div>

<div class="content" *ngIf="hierarchyNotExist">
  {{'lm-professional.lm-professional-services-hierarchy.hierarchy_not_exists' | translate}}
</div>