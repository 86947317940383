import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { AuthenticationService } from '../../core/services/auth.service';
import { Router } from '@angular/router';
import { get as _get } from 'lodash';
import { first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ELmSharedPaths } from "../models/shared.models";
import { Utilities } from '../utilities/utilities';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss','../../assets/styles/popup.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
	_translationSubscription: Subscription;
	_messages: { [key: string]: string };
	token: string;
	username: string;
	isUserLeroy: boolean;
	isEnvironmentProd: boolean;
	options = [];
	sessionExpired: boolean = false;

	constructor(
		private _auth: AuthenticationService,
		private _router: Router,
		private _translateService: TranslateService,
		private elementRef: ElementRef
	) {
		this._translationSubscription = this._translateService
		.get('shared.header.logic')
		.subscribe((res: { [key: string]: string }) => {
		  this._messages = res;
		});
	}
	ngOnDestroy(): void {
		this._translationSubscription.unsubscribe();
	  }
	ngOnInit() {

		if (environment.production){
			this.isEnvironmentProd = true;
		}
		else{
			this.isEnvironmentProd = false;
		}

		  this._auth.watchStorage().pipe().subscribe(res => {
			if(res == 'true' && this._router.url != '/') {
				this.sessionExpired = true;
				this.elementRef.nativeElement.ownerDocument.body.style.overflow = 'hidden';
			} else {
				this._router.navigate(['/login']);
				this.elementRef.nativeElement.ownerDocument.body.style.overflow = 'auto';
			}
		  });

	}

	isLoggedIn(){
	    return Utilities.isLoggedIn();
	}

	isUserHogami(){
	    return Utilities.isUserHogami();
	}

	logout() {
		this.options = [];
		this._auth.logout().pipe( first() ).subscribe(
			() => {
				localStorage.removeItem( 'token' );
				this._auth.token.next( _get( localStorage.getItem( 'token' ), '' ) );
				this._router.navigate( ['/login'] );
			},
			error => {
				this._router.navigate( ['/'] );
			}
		);
	}

	onToolsClick() {
		
        this.options = [
            {
                title: this._messages['password_reset'],
                routerLink: '/reset-password',
                isInternalUser: true
            },
            {
                title: this._messages['create_user'],
                routerLink: ELmSharedPaths.CREATE_NEW_USER,
                isInternalUser: this.isUserHogami()
            },
            {
                title: this._messages['transfer_from_paycomet'],
                routerLink: '/transfer-payby',
                isInternalUser: this.isUserHogami()
            },
            {
                title: this._messages['sepa_transfer_professional'],
                routerLink: '/transfer-sepa',
                isInternalUser: this.isUserHogami()
            },
            {
                title: this._messages['cancel_transfer_professional'],
                routerLink: '/cancel-sepa',
                isInternalUser: this.isUserHogami()
            }/*,
			{
                title: this._messages['professional_block'],
                routerLink: ELmSharedPaths.BLOCK_PROFESSIONAL,
                isInternalUser: this.isUserHogami()
            }*/
            ];
     }

}
