import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProfessionalStatusDialogComponent } from './../professional-status-dialog/professional-status-dialog.component';
import { StatusType, ProfessionalStatus, ChangeStatus } from './../../models/lm-professional.model';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Service, StatusTransfer, Campaigns, Provinces } from '../../../lm-transfers/models/lm-transfers.model';
import { OperationService } from '../../../lm-transfers/services/operation.service';
import { Utilities } from 'src/app/shared/utilities/utilities';
import { TransferService } from '../../../lm-transfers/services/transfers.service';
import { RequestService } from '../../../lm-transfers/services/request.service';
import { IFilters, IFiltersData, IOperationsRequestsFilters, IServiceCoverageFilters, IServicesFilters, IServiceType } from '../../models/lm-professional.model';
import { ProfessionalService } from '../../services/professional.service';

@Component({
  selector: 'app-lm-filters-professional-details',
  templateUrl: './lm-filters-professional-details.component.html',
  styleUrls: ['../../../utils/form-search.scss', '../../../utils/dropdown.scss', './lm-filters-professional-details.component.scss']
})
export class LmFiltersProfessionalDetailsComponent implements OnInit {

  dialogRef: MatDialogRef<ProfessionalStatusDialogComponent>;

  _translateSubscription: Subscription;
  form: FormGroup;
  isUserLeroy: boolean;
  isUserHogami: boolean;
  typeId: string;
  transferType: string = 'op';
  services: Service[] = [];
  statusFilters: StatusTransfer[] = [];
  campaigns: Campaigns[] = [];
  serviceSelected: number = null;
  provinces: Provinces[] = [];
  serviceTypes: IServiceType[];
  professionalStatusResponse: ProfessionalStatus[];
  statusAlloweds: StatusType[];
  currentStatus: StatusType;
  dropdownSettingsServices = {};
  dropdownSettingsStates = {};
  dropdownSettingCampaigns = {};
  dropdownSettingStores = {};
  infoFilters: IFilters;
  operationsRequestsFilters: IOperationsRequestsFilters;
  servicesFilters: IServicesFilters;
  serviceCoverageFilters: IServiceCoverageFilters;
  textButtonSent: string;
  iconButtonSent: string;
  msgSuccces: string;
  msgError: string;
  titleForm: string;
  submitted: boolean = false;
  @Input() filtersData: IFiltersData;
  @Input() loading: boolean = false;
  @Input() filterType: string;
  @Output() filtersApply: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private _operationServices: OperationService,
    private _transferService: TransferService,
    private _requestServices: RequestService,
    private _translateService: TranslateService,
    private _professionalService: ProfessionalService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.isUserLeroy = Utilities.isUserLeroy();
    this.isUserHogami = Utilities.isUserHogami();

    this._translateSubscription = this._translateService.get('lm-professional.lm-filters-professional-details').subscribe(res => {
      this.textButtonSent = res['search'];
      this.titleForm = res['title'];
      if (this.filterType === "professionalStatusHistories") {
        this.titleForm = res['title-change-status'];
      }
      this.iconButtonSent = 'search';
    });

    if (this.filterType === "professionalOperations" || this.filterType === "professionalRequests") {
      this.loadServices(() => { });
      this.loadProvinces();
      if (this.filterType === "professionalOperations") {
        this.loadOperationStatus();
      } else if (this.filterType === "professionalRequests") {
        this.loadRequestStatus();
      }
      this.loadCampaigns();
      this.createOperationsRequestsForm();
    }
    if (this.filterType === "professionalServices") {
      this.loadServiceTypes();
      this.createServicesForm();
    }
    if (this.filterType === "professionalServiceCoverageDetails") {
      this.loadProvinces();
      this.createServiceCoverageForm();
    }

    if (this.filterType === "professionalStatusHistories") {
      this._translateSubscription = this._translateService.get('lm-professional.lm-filters-professional-details.change').subscribe((res: string) => {
        this.textButtonSent = res;
        this.iconButtonSent = 'save';
      });
      this.loadProfessionalStatuses();
      this.createFormStatusHistory();
    }

    this.loadDropdownService();
    this.loadDrodownState();
    this.loadDropdownCampaigns();
    this.sendFilters();

    this.showFormChangeStatus();

  }

  get f() { return this.form.controls; }

  createOperationsRequestsForm() {
    this.form = new FormGroup({
      dateSinceStarttime: new FormControl(''),
      dateUntilStarttime: new FormControl(''),
      operationDateStartTime: new FormControl(''),
      operationDateEndTime: new FormControl(''),
      dateSinceEndtime: new FormControl(''),
      dateUntilEndtime: new FormControl(''),
      serviceSelected: new FormControl(''),
      provinceSelected: new FormControl(''),
      stateSelected: new FormControl(''),
      idType: new FormControl(''),
      campaignSelected: new FormControl(''),
      couponCode: new FormControl('')
    });
  }


  createServicesForm() {
    this.form = new FormGroup({
      isActive: new FormControl(null),
      typeSelected: new FormControl('')
    });
  }

  createServiceCoverageForm() {
    this.form = new FormGroup({
      provinceSelected: new FormControl('')
    });
  }

  createFormStatusHistory() {
    this.form = new FormGroup({
      newStatus: new FormControl(null, Validators.required),
      comments: new FormControl(null, Validators.required)
    });
  }

  submitForm() {
    this.sendFilters();
  }


  resetFilters() {
    this.form.reset();
  }

  sendFilters() {
    if (this.filterType === "professionalOperations" || this.filterType === "professionalRequests") {
      this.buildOperationsRequestsFilters();
    }
    if (this.filterType === "professionalServices") {
      this.buildServicesFilters();
    }
    if (this.filterType === "professionalServiceCoverageDetails") {
      this.buildServiceCoverageFilters();
    }
    if (this.filterType === "professionalStatusHistories") {
      if (this.form.valid) {
        this.openModalConfirmationChangeStatustype();
      }
    }
  }

  click() {
    this.submitted = true;
    if (this.form.valid) {
      this.submitted = false;
    }
  }

  async openModalConfirmationChangeStatustype() {
    const modalData = await this.dataToModalChangeStatus(this.form['controls']['newStatus']['value']);
    this.dialogRef = this.dialog.open(ProfessionalStatusDialogComponent, {
      width: '600px',
      height: 'auto',
      disableClose: true,
      data: {
        value: modalData
      }
    });

    this.dialogRef.afterClosed().subscribe(result => {
      console.debug('The dialog was closed');
    });

    this.dialogRef.componentInstance.submitClicked.subscribe(result => {
      if (result === 'submit') {
        let changeStatus: ChangeStatus = {
          emailProfessional: this.filtersData.professionalEmail,
          newStatus: this.form['controls']['newStatus']['value'],
          comment: this.form['controls']['comments']['value']
        }
        this.changeProfessionalStatus(changeStatus);
      } else {
        this.dialogRef.close();
        window.location.reload();
      }
    });
  }

  changeProfessionalStatus(changeStatus: ChangeStatus) {
    this._professionalService.changeProfessionalStatus(changeStatus).subscribe({
      next: res => {
        const { code, data: status } = res;
        this.msgError = '';
        if (code === '200') {
          this.msgSuccces = status;
        }
        this.dialogRef.componentInstance.myf(
          { hasResponse: true },
          { msgResponse: this.messageAfterChangeStatus(status, changeStatus.newStatus) },
          { typeError: true }
        );
      },
      error: err => {
        this.msgSuccces = '';
        this.handleError(err, changeStatus);
      }
    });
  }

  handleError(err: any, changeStatus: ChangeStatus): void {
    this.msgSuccces = '';
    this.msgError = err['error']['message'];
    let errorCode = err['error']['code'];
    if (errorCode === 'SPRO-400-22') {
      this.msgError = this._translateService.instant('lm-professional.lm-professional-status.reponse.error_not_implement');
    }
    if (errorCode === 'SPRO-400-21') {
      let msga = this._translateService.instant('lm-professional.lm-professional-status.reponse.error_not_allowed_a');
      let msgb = this._translateService.instant('lm-professional.lm-professional-status.reponse.error_not_allowed_b');
      let msgc = this._translateService.instant('lm-professional.lm-professional-status.reponse.error_not_allowed_c');

      let statusNew = this.professionalStatusResponse[0].professionalStatusEndRest.filter(item => item.code === changeStatus.newStatus);
      if (statusNew === undefined || statusNew.length <= 0) {
        this.msgError = msga + this.professionalStatusResponse[0].professionalStatusStartRest.statusDescription + msgb + statusNew[0]['statusDescription'] + msgc;
      }

      this.msgError = msga + this.professionalStatusResponse[0].professionalStatusStartRest.statusDescription + msgb + statusNew[0]['statusDescription'] + msgc;
    }
    this.dialogRef.componentInstance.myf({ hasResponse: true }, { msgResponse: this.msgError }, { typeError: false });
  }

  messageAfterChangeStatus(status: string, statusToChange: string): string {
    let msgReturn: string = status;
    const statusMap = {
      'BANNED': 'user_banned',
      'INACTIVE': 'user_inactivated',
      'ACTIVE': 'user_activated',
      'BLOCKED': 'user_blocked'
    };
  
    if (statusMap[statusToChange]) {
      this._translateSubscription = this._translateService.get('lm-professional.lm-professional-status.reponse').subscribe((res: any) => {
        msgReturn = res[statusMap[statusToChange]];
      });
    }
    return msgReturn;
  }

  async dataToModalChangeStatus(status: String): Promise<any | undefined> {
    try {
      this.loading = true;
      const statusComplete: StatusType = this.statusAlloweds.find((s) => s.code === status);
      const statusOperationsResponse = await this._professionalService.getAllowedOperationByStatus(status).toPromise();
      let statusOperations: { description: string, allowed: boolean }[] = [];
      if (statusOperationsResponse.status === 'Success') {
        if (statusOperationsResponse.data.permittedProfessionalActionRest) {
          statusOperationsResponse.data.permittedProfessionalActionRest.forEach(permission => {
            statusOperations.push({ description: permission.description, allowed: true });
          });
        }
        if (statusOperationsResponse.data.forbiddenProfessionalActionRest) {
          statusOperationsResponse.data.forbiddenProfessionalActionRest.forEach(permission => {
            statusOperations.push({ description: permission.description, allowed: false });
          });
        }
      }
      this.loading = false;
      return {
        ...statusComplete,
        statusOperations
      };
    } catch (error) {
      console.error(error);
      this.loading = false;
    }
  }

  buildOperationsRequestsFilters() {
    this.operationsRequestsFilters = {
      operationDateStartTime: (this.form.controls['operationDateStartTime'].value == null || this.form.controls['operationDateStartTime'].value == "") ? null : Utilities.getDate(this.form.controls['operationDateStartTime'].value),
      operationDateEndTime: (this.form.controls['operationDateEndTime'].value == null || this.form.controls['operationDateEndTime'].value == "") ? null : Utilities.getDateEnd(this.form.controls['operationDateEndTime'].value),
      // start time
      startDateStartTime: (this.form.controls['dateSinceStarttime'].value == null || this.form.controls['dateSinceStarttime'].value == "") ? null : Utilities.getDate(this.form.controls['dateSinceStarttime'].value),
      endDateStartTime: (this.form.controls['dateUntilStarttime'].value == null || this.form.controls['dateUntilStarttime'].value == "") ? null : Utilities.getDateEnd(this.form.controls['dateUntilStarttime'].value),
      // end time
      startDateEndTime: (this.form.controls['dateSinceEndtime'].value == null || this.form.controls['dateSinceEndtime'].value == "") ? null : Utilities.getDate(this.form.controls['dateSinceEndtime'].value),
      endDateEndTime: (this.form.controls['dateUntilEndtime'].value == null || this.form.controls['dateUntilEndtime'].value == "") ? null : Utilities.getDateEnd(this.form.controls['dateUntilEndtime'].value),

      professional: this.filtersData.professionalEmail,
      services: (this.form.controls['serviceSelected'].value == null || this.form.controls['serviceSelected'].value == "") ? [] : this.proccessServices(this.form.controls['serviceSelected'].value),
      province: (this.form.controls['provinceSelected'].value !== null && (Number(this.form.controls['provinceSelected'].value) || !this.form.controls['provinceSelected'].value.includes(',')) ? this.form.controls['provinceSelected'].value : "0"),
      concatenatedProvince: (this.form.controls['provinceSelected'].value !== null && (this.form.controls['provinceSelected'].value != 0 && this.form.controls['provinceSelected'].value.includes(',')) ? this.form.controls['provinceSelected'].value : null),
      jobsStatus: (this.form.controls['stateSelected'].value == null || this.form.controls['stateSelected'].value == "") ? [] : this.proccessStates(this.form.controls['stateSelected'].value),
      id: (this.form.controls['idType'].value == null || this.form.controls['idType'].value == "") ? 0 : this.form.controls['idType'].value,

      campaigns: (this.form.controls['campaignSelected'].value == null || this.form.controls['campaignSelected'].value == "") ? [] : this.proccessCampaigns(this.form.controls['campaignSelected'].value),
      couponCode: (this.form.controls['couponCode'].value == null || this.form.controls['couponCode'].value == "") ? null : this.form.controls['couponCode'].value.trim(),
    }
    this.infoFilters =
    {
      isFilterBuilt: true,
      operationsRequestsFilters: this.operationsRequestsFilters
    }

    this.filtersApply.emit(this.infoFilters);
  }

  buildServicesFilters() {
    this.servicesFilters = {
      status: (this.form.controls['isActive'].value),
      type: (this.form.controls['typeSelected'].value !== null && (this.form.controls['typeSelected'].value) ? this.form.controls['typeSelected'].value : null),
    }
    this.infoFilters = {
      isFilterBuilt: true,
      servicesFilters: this.servicesFilters
    }
    this.filtersApply.emit(this.infoFilters);

  }

  buildServiceCoverageFilters() {
    this.serviceCoverageFilters = {
      provinces: (this.form.controls['provinceSelected'].value !== null && (this.form.controls['provinceSelected'].value) ? [this.form.controls['provinceSelected'].value] : null),
    }
    this.infoFilters = {
      isFilterBuilt: true,
      serviceCoverageFilters: this.serviceCoverageFilters
    }
    this.filtersApply.emit(this.infoFilters);
  }

  loadProvinces() {
    this._operationServices.getProvinces().subscribe(
      res => {
        this.provinces = this.mergeZipCodes(res.data);
      }
    )
  }

  loadServices(callback) {
    this._operationServices.getSearchServices().subscribe(
      async res => {
        this.services = await res.data;
        this.services.sort((a, b) => a.description.localeCompare(b.description));
        this.services.forEach(element => {
          let fullDescrition = element.description + ' - ' + element.code;
          element.fullDescrition = fullDescrition;
        });
        if (callback) { callback(); }
      }
    );
  }

  loadOperationStatus() {
    this._operationServices.getOperationStatus().subscribe(
      res => {
        this.statusFilters = res.data;
      }
    );
  }

  loadRequestStatus() {
    this._requestServices.getRequestStatus().subscribe(
      res => {
        this.statusFilters = res.data;
      }
    );
  }

  loadCampaigns() {
    this._transferService.getAllCampaigns().subscribe(res => {
      this.campaigns = res.data;
      this.campaigns.push({
        id: 0,
        descriptionCampaign: '',
        codeCampaign: 'WEB'
      });
      this.campaigns.sort((a, b) => a.id.toString().localeCompare(b.id.toString()));
    });
  }

  loadServiceTypes() {
    this._transferService.getServiceTypes().subscribe(
      res => {
        this.serviceTypes = this.translateTypes(res);
      }
    )
  }

  loadProfessionalStatuses() {
    this._professionalService.getProfessionalStatusAllowedByCode(this.filtersData.professionalObject['professionalStatus']['code'])
      .subscribe(res => {
        this.professionalStatusResponse = res['data'];
        this.statusAlloweds = this.professionalStatusResponse[0].professionalStatusEndRest;
        this.currentStatus = this.professionalStatusResponse[0].professionalStatusStartRest;
      })
  }

  private translateTypes(res: any): IServiceType[] {
    return res.data.map(t => {
      switch (t.type) {
        case "CONFIGURATOR":
          t.description = "Configurador";
          break;
        case "MANITAS":
          t.description = "Manitas";
          break;
        case "MANITAS-N2":
          t.description = "Hibridos";
          break;
        case "CONFIGURATOR-N2":
          t.description = "Configurador N2";
          break;
      }
      return t;
    });
  }

  mergeZipCodes(d = []) {
    let arr = []
    d.map(item => {
      const index = arr.findIndex(a => a.name === item.name)
      if (index === -1) {
        arr.push({ name: item.name, zipCode: "" + item.zipCode })
      } else {
        arr[index] = { name: item.name, zipCode: arr[index].zipCode + "," + item.zipCode }
      }
    })
    return arr;
  }

  /**
   * This function validate string in input phone
   * @param e 
   */
  onChangePhoneClent(e: any) {
    const key = e.key;
    if (key < "0" || key > "9") {
      e.preventDefault();
    }
  }

  /**
   * This function processes the array of services to construct an array of integers.
   * @param arrayServices 
   * @returns 
   */
  proccessServices(arrayServices: any) {
    let finalService = [];
    arrayServices.forEach(element => {
      finalService.push(element.id)
    });
    return finalService;
  }

  /**
   * This function processes the array of states to construct an array of integers.
   * @param arrayServices 
   * @returns 
   */
  proccessStates(arrayStates: any) {
    let finalStates = [];
    arrayStates.forEach(element => {
      finalStates.push(element.id)
    });
    return finalStates;
  }

  /**
   * This function processes the array of campaigns to construct an array of integers.
   * @param arrayCampaign 
   */
  proccessCampaigns(arrayCampaign: any) {
    let finalCampaigns = [];
    arrayCampaign.forEach(element => {
      finalCampaigns.push(element.id);
    });
    return finalCampaigns;
  }

  /**
   * This function processes the array of stores to construct an array of integers.
   * @param arrayCampaign 
   */
  proccessStores(arrayStores: any) {
    let finalStores = [];
    arrayStores.forEach(element => {
      finalStores.push(element.leroyId);
    });
    return finalStores;
  }

  /**
   * This function loads the configuration of the service dropdown.
   */
  loadDropdownService() {
    const textSelection = this._translateService.instant('lm-professional.lm-filters-professional-details.text');
    this.dropdownSettingsServices = {
      singleSelection: false,
      idField: 'id',
      textField: 'fullDescrition',
      selectAllText: textSelection.selectAllText,
      unSelectAllText: textSelection.unSelectAllText,
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  /**
   * This function loads the configuration of the states dropdown.
   */
  loadDrodownState() {
    const textSelection = this._translateService.instant('lm-professional.lm-filters-professional-details.text');
    this.dropdownSettingsStates = {
      singleSelection: false,
      idField: 'id',
      textField: 'description',
      selectAllText: textSelection.selectAllText,
      unSelectAllText: textSelection.unSelectAllText,
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  loadDropdownCampaigns() {
    const textSelection = this._translateService.instant('lm-professional.lm-filters-professional-details.text');
    this.dropdownSettingCampaigns = {
      singleSelection: false,
      idField: 'id',
      textField: 'codeCampaign',
      selectAllText: textSelection.selectAllText,
      unSelectAllText: textSelection.unSelectAllText,
      itemsShowLimit: 3,
      allowSearchFilter: true
    }


  }

  showFormChangeStatus(): boolean {
    let available: boolean = true;
    if (!this.isUserLeroy && this.filterType === 'professionalStatusHistories') {
      available = false;
    }
    return available;
  }

}
