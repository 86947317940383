import { startWith, map } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { ClientService } from '../../services/client.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Utilities } from 'src/app/shared/utilities/utilities';
import { IAddressType, IClientInformation, IClientType, InterfaceCoutriesRegex } from '../../models/lm-clients.model';
import { CountryPhone, InterfaceCountries } from 'src/app/shared/utilities/interface-countries';
import { Observable, BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { TransferService } from 'src/app/pages/lm-transfers/services/transfers.service';
import { MasterArea } from 'src/app/shared/models/shared.models';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-lm-update-client-data',
  templateUrl: './lm-update-client-data.component.html',
  styleUrls: ['./lm-update-client-data.component.scss', '../../../utils/form-search.scss']
})
export class LmUpdateClientDataComponent implements OnInit {
  @ViewChild("auto") autocomplete: MatAutocomplete;
  @ViewChild("autoBill") autocompleteBill: MatAutocomplete;
  loading: boolean = false;

  countriesLoaded = new BehaviorSubject(false);
  options: InterfaceCountries[] = [];
  optionsBill: InterfaceCountries[] = [];
  filteredOptions: Observable<InterfaceCountries[]>;
  filteredOptionsBill: Observable<InterfaceCountries[]>;
  countrySelected: InterfaceCountries;
  defaultCountry: string = '+34';
  defaultCountryBill: string = '+34';
  billCountrySelected: InterfaceCountries;
  countryPhones: CountryPhone[];
  masterArea: MasterArea = {};
  phoneMaxLenght: number = 9;
  phoneMinLenght: number = 9;
  billPhoneMaxLenght: number = 9;
  billPhoneMinLenght: number = 9;

  adresseTypes: IAddressType[];
  clientTypes: IClientType[];

  @Input() editClientData: boolean;
  @Input() clientInformation: IClientInformation;
  @Output() editClientDataChange = new EventEmitter<boolean>();
  @Output() editClientDataEvent = new EventEmitter<boolean>();

  clientBody: IClientInformation;

  form: FormGroup;
  documentsRegex: InterfaceCoutriesRegex[] = [];

  constructor(
    private _clientService: ClientService,
    private renderer: Renderer2,
    private _translateService: TranslateService,
    private _snackBar: MatSnackBar,
    private _transferService: TransferService
  ) {
  }

  ngOnInit() {
    this.countriesLoaded.subscribe();
    this.retrieveAllClientTypes();
    this.retrieveAllClientAddresTypes();
    this.createEditForm();
    this.loadCountries();
    this.retrieveDocumentRegex()
  }

  /**
   * Close current tab
   */
  closeTab() {
    this.editClientData = false;
    this.editClientDataEvent.emit(false);
    this.countriesLoaded.unsubscribe();
  }

  /**
   * Retrieve Countries
   */
  loadCountries(): void {
    this._transferService.getCountries().subscribe(res => {
      this.countryPhones = res.data.map(item => ({
        name: item.countryRest.countryName,
        shortName: '',
        countryCode: item.countryCode,
        flag: item.countryRest.countryIconKey,
        minNumberDigits: item.countryMinNumberDigits,
        maxNumberDigits: item.countryMaxNumberDigits
      }));
      this.options = [...this.options, ...this.countryPhones] as InterfaceCountries[];
      this.optionsBill = [...this.optionsBill, ...this.countryPhones] as InterfaceCountries[];
      this.countriesLoaded.next(true);
    });
  }

  /**
   * Retrieve all client types
   */
  retrieveAllClientTypes(): void {
    this._clientService.retrieveAllClientTypes()
      .then(res => {
        this.clientTypes = res['data'].map(type => {
          switch (type.name) {
            case 'PART':
              return { ...type, name: 'PARTICULAR' };
            case 'FREE':
              return { ...type, name: 'FREELANCE' };
            case 'COMP':
              return { ...type, name: 'COMPANY' };
            default:
              return type;
          }
        });
      });
  }

  /**
   * Retrieve all client address types
   */
  retrieveAllClientAddresTypes(): void {
    this._clientService.retrieveAllClientAddressTypes()
      .then(res => {
        this.adresseTypes = res['data'].map(type => {
          switch (type.name) {
            case 'BILL':
              return { ...type, name: 'FACTURACIÓN' };
            case 'RESD':
              return { ...type, name: 'RESIDENCIA' };
            case 'OPER':
              return { ...type, name: 'OPERACION' };
            default:
              return type;
          }
        });
      });
  }

  get f() {
    return this.form.controls;
  }

  /**
   * Create form to edit information
   */
  createEditForm(): void {
    this.form = new FormGroup({
      name: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.pattern(Utilities.patterns.email)]),
      documentType: new FormControl('', Validators.required),
      documentNumber: new FormControl('', Validators.required),
      phoneId: new FormControl('', Validators.required),
      phoneCountryCode: new FormControl('', Validators.required),
      phoneNumber: new FormControl(''),
      addressId: new FormControl(''),
      address: new FormControl(''),
      complementAddress: new FormControl(''),
      zipCode: new FormControl(''),
      city: new FormControl(''),
      province: new FormControl(''),
      addressTypeId: new FormControl(''),
      billId: new FormControl(''),
      billName: new FormControl(''),
      billLastName: new FormControl(''),
      billPhone: new FormControl(''),
      billDocumentType: new FormControl(''),
      billDocumentNumber: new FormControl(''),
      billCountryCode: new FormControl('', Validators.required),
      billClientTypeId: new FormControl(''),
    });
    this.setFormInformation();
  }

  /**
   * Set information in form
   */
  setFormInformation(): void {
    this.countriesLoaded.subscribe(value => {
      if (value) {
        this.countrySelected = this.findCountryByCode(this.defaultCountry);
        this.billCountrySelected = this.findCountryByCodeBill(this.defaultCountryBill);
      }
    });

    if (this.clientInformation.phones) {
      const phone = this.clientInformation.phones[0];
      this.form.controls['phoneId'].setValue(phone.id);
      this.form.controls['phoneNumber'].setValue(phone.number + '');
      this.form.controls['phoneCountryCode'].setValue(phone.countryCode);
      this.countriesLoaded.subscribe(value => {
        if (value) {
          this.countrySelected = this.findCountryByCode(phone.countryCode);
          this.phoneMaxLenght = this.countrySelected.maxNumberDigits;
          this.phoneMinLenght = this.countrySelected.minNumberDigits;
        }
      });
    }

    this.form.controls['name'].setValue(this.clientInformation.name);
    this.form.controls['lastName'].setValue(this.clientInformation.lastName);
    this.form.controls['email'].setValue(this.clientInformation.email);
    this.form.controls['documentNumber'].setValue(this.clientInformation.documentNumber);
    this.form.controls['documentType'].setValue(this.clientInformation.clientDocumentType.id);

    if (this.clientInformation.addresses && this.clientInformation.addresses.length > 0) {
      const address = this.clientInformation.addresses[0];
      this.form.controls['addressId'].setValue(address.id);
      this.form.controls['address'].setValue(address.address);
      this.form.controls['complementAddress'].setValue(address.complementAddress);
      this.form.controls['zipCode'].setValue(address.zipCode);
      this.form.controls['city'].setValue(address.city);
      this.form.controls['province'].setValue(address.province);
      this.form.controls['addressTypeId'].setValue(address.addressTypeRest.id);
    }

    if (this.clientInformation.billingData) {
      const billingData = this.clientInformation.billingData;
      this.form.controls['billId'].setValue(billingData.billId);
      this.form.controls['billName'].setValue(billingData.name);
      this.form.controls['billLastName'].setValue(billingData.lastName);
      this.form.controls['billDocumentType'].setValue(billingData.clientDocumentType.id);
      this.form.controls['billDocumentNumber'].setValue(billingData.documentNumber);
      if (billingData.clientType) {
        this.form.controls['billClientTypeId'].setValue(billingData.clientType.id);
      }

      if (this.billCountrySelected == this.findCountryByCodeBill(this.defaultCountryBill)) {
        this.form.controls['billCountryCode'].setValue(billingData.countryCode);
        this.form.controls['billPhone'].setValue(billingData.phone);
        this.countriesLoaded.subscribe(value => {
          if (value) {
            this.billCountrySelected = this.findCountryByCodeBill(billingData.countryCode);
            this.billPhoneMaxLenght = this.billCountrySelected.maxNumberDigits;
            this.billPhoneMinLenght = this.billCountrySelected.minNumberDigits;
          }
        });
      }
    }
  }


  /**
   * Submit form
   */
  submitForm(): void {
    if (this.form.valid) {
      this.loading = true;
      const formBody = this.form.controls;
      const phoneId = this.form.controls['phoneId'].value;
      const phoneCountryCode = this.form.controls['phoneCountryCode'].value;
      const phoneNumber = this.form.controls['phoneNumber'].value;
      const addressId = this.form.controls['addressId'].value;
      const address = this.form.controls['address'].value;
      const complementAddress = this.form.controls['complementAddress'].value;
      const zipCode = this.form.controls['zipCode'].value;
      const city = this.form.controls['city'].value;
      const province = this.form.controls['province'].value;
      const addressTypeId = this.form.controls['addressTypeId'].value;
      const billId = this.form.controls['billId'].value;
      const billName = this.form.controls['billName'].value;
      const billLastName = this.form.controls['billLastName'].value;
      const billDocumentNumber = this.form.controls['billDocumentNumber'].value;
      const billCountryCode = this.form.controls['billCountryCode'].value;
      const billPhone = this.form.controls['billPhone'].value;
      const billClientTypeId = this.form.controls['billClientTypeId'].value;

      this.clientBody = {
        id: this.clientInformation.id,
        name: formBody['name'].value,
        lastName: formBody['lastName'].value,
        email: formBody['email'].value,
        documentNumber: formBody['documentNumber'].value,
        clientDocumentType: {
          id: formBody['documentType'].value
        },
        phones: [{
          id: phoneId,
          countryCode: phoneCountryCode,
          number: phoneNumber
        }],
        addresses: [{
          id: addressId,
          address: address,
          complementAddress: complementAddress,
          zipCode: zipCode,
          city: city,
          province: province,
          addressTypeRest: {
            id: addressTypeId
          }
        }],
        billingData: {
          billId: billId,
          name: billName,
          lastName: billLastName,
          documentNumber: billDocumentNumber,
          countryCode: billCountryCode,
          phone: billPhone,
          clientType: {
            id: billClientTypeId
          },
          clientDocumentType: {
            id: formBody['billDocumentType'].value
          }
        }
      };

      this._clientService.updateClient(this.clientBody)
        .subscribe(res => {
          if (res['code'] === '200') {
            this.loading = false;
            this._snackBar.open(this._translateService.instant('lm-client.lm-client-update-basic-data.form.response.success'),
              this._translateService.instant('lm-client.lm-client-update-basic-data.form.buttons.ok'), { duration: 1500 });
            this.closeTab();
          }
        }, error => {
          this.loading = false;
          this._snackBar.open(this._translateService.instant('lm-client.lm-client-update-basic-data.form.response.error'),
            this._translateService.instant('lm-client.lm-client-update-basic-data.form.buttons.ok'), { duration: 1500 });
        });
    }
  }

  /**
   * Display in input Autocomplete
   * @param country 
   * @returns 
   */
  displayFn(country: InterfaceCountries): any {
    return country;
  }

  /**
   * Selected country
   * @param item 
   */
  getOption(item) {
    this.countrySelected = this.findCountryByCode(item);
    this.phoneMaxLenght = this.countrySelected.maxNumberDigits;
    this.phoneMinLenght = this.countrySelected.minNumberDigits;
    this.renderer.selectRootElement('#phoneNumber').focus();
  }

  /**
   * Selected country
   * @param item 
   */
  getBillOption(item) {
    this.billCountrySelected = this.findCountryByCodeBill(item);
    this.billPhoneMaxLenght = this.billCountrySelected.maxNumberDigits;
    this.billPhoneMinLenght = this.billCountrySelected.minNumberDigits;
    this.renderer.selectRootElement('#billPhone').focus();
  }

  /**
   * Find item by value
   * @param name
   */
  private _filter(name: string): InterfaceCountries[] {
    const filterValue = name.toLowerCase();

    return this.options.filter(option =>
      option.name.toLowerCase().includes(filterValue) || option.countryCode.includes(filterValue)
    );
  }

  private _filterBill(name: string): InterfaceCountries[] {
    const filterValue = name.toLowerCase();

    return this.optionsBill.filter(option =>
      option.name.toLowerCase().includes(filterValue) || option.countryCode.includes(filterValue)
    );
  }

  /**
   * Start Autocomplete
   */
  startAutocomplete() {
    this.filteredOptions = this.form.controls['phoneCountryCode'].valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value.name;
        return name ? this._filter(name as string) : this.options.slice();
      }),
    );
  }

  /**
   * Start Autocomplete
   */
  startAutocompleteBill() {
    this.filteredOptionsBill = this.form.controls['billCountryCode'].valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value.name;
        return name ? this._filterBill(name as string) : this.optionsBill.slice();
      }),
    );
  }

  /**
   * Find country by code
   * @param countryCode
   */
  findCountryByCode(countryCode: string): InterfaceCountries {
    return this.options.find(item => item['countryCode'] === countryCode);
  }

  findCountryByCodeBill(countryCode: string): InterfaceCountries {
    return this.optionsBill.find(item => item['countryCode'] === countryCode);
  }

  /**
   * Blur Inpu Country
   * @param event 
   */
  blurCountry(event) {
    if (!this.autocomplete.isOpen) {
      const value = event.target.value;
      const country = this.findCountryByCode(value);
      if (country) {
        this.countrySelected = country;
        this.form.controls['phoneCountryCode'].setValue(this.countrySelected.countryCode);
      }
    }
  }


  /**
   * Blur Inpu Country
   * @param event 
   */
  blurCountryBill(event) {
    if (!this.autocompleteBill.isOpen) {
      const value = event.target.value;
      const country = this.findCountryByCodeBill(value);
      if (country) {
        this.billCountrySelected = country;
        this.form.controls['billCountryCode'].setValue(this.billCountrySelected.countryCode);
      }
    }
  }

  /**
   * Blur Inpu ZipCode
   * @param event 
   */
  blurFindProvinceCity(event): void {
    let zipCode = event.target.value;
    this._transferService.retrieveMasterAreaByZipCode(zipCode).subscribe({
      next: this.handleResponse.bind(this),
      error: this.handleError.bind(this)
    });
  }

  handleResponse(response: any): void {
    this.masterArea = response['data'];
    this.form.controls['city'].setValue(this.masterArea.city);
    this.form.controls['province'].setValue(this.masterArea.provinceName);
  }

  handleError(error: any): void {
    this.form.controls['zipCode'].setErrors({ format: false });
    let city = this.form.controls['city'].value;
    let province = this.form.controls['province'].value;
    if (city == '' && province == '') {
      this.form.controls['city'].setValue('');
      this.form.controls['province'].setValue('');
    }
  }

  /**
   * Click in input autocomplete
   */
  clickEvent() {
    this.startAutocomplete();
  }

  clickEventBill() {
    this.startAutocompleteBill();
  }

  /**
   * Retrieve Documents Regex
   */
  retrieveDocumentRegex(): void {
    this._clientService.retrieveDocumentTypes()
      .then(res => {
        this.documentsRegex = res['data'];
      });
  }

  /**
   * Set regex on input
   * @param event 
   */
  captionRegex(event: any): void {
    const regexDocument = this.documentsRegex.find(item => item.id === event);
    const regex = regexDocument?.regex;
    const documentNumberControl = this.form.controls['documentNumber'];
    if (regex) {
      documentNumberControl.setValidators([Validators.required, Validators.pattern(new RegExp(regex))]);
    } else {
      documentNumberControl.setValidators([Validators.required]);
    }
    documentNumberControl.updateValueAndValidity();
    this.renderer.selectRootElement('#documentNumber').focus();
  }


  /**
   * Set regex on input
   * @param event 
   */
  billCaptionRegex(event: any): void {
    const regexDocument = this.documentsRegex.find(item => item.id === event);
    const regex = regexDocument?.regex;
    const documentNumberControl = this.form.controls['billDocumentNumber'];
    if (regex) {
      documentNumberControl.setValidators([Validators.required, Validators.pattern(new RegExp(regex))]);
    } else {
      documentNumberControl.clearValidators();
      documentNumberControl.setValidators([Validators.required]);
    }
    documentNumberControl.updateValueAndValidity();
    this.renderer.selectRootElement('#billDocumentNumber').focus();
  }

}
