import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IClientInformation } from '../../models/lm-clients.model';

@Component({
  selector: 'app-lm-client-basic-data',
  templateUrl: './lm-client-basic-data.component.html',
  styleUrls: ['./lm-client-basic-data.component.scss']
})
export class LmClientBasicDataComponent implements OnInit {

  @Output() editClientDataEvent = new EventEmitter<boolean>();
  @Input() clientInformation: IClientInformation;

  isEditInfo: boolean;

  constructor( ) { }

  ngOnInit() {
  }

  /**
   * Go to tab Update Client Data
   */
  openUpdateClientData(): void {
    this.editClientDataEvent.emit(true);
  }

}
