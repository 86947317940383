import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LmTransferSepaComponent } from './component/lm-transfer-sepa.component';
import { LmTransferSepaRoutingModule } from './lm-transfer-sepa-routing.component';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';

@NgModule({
	declarations: [
		LmTransferSepaComponent
	],
	imports: [
        LmTransferSepaRoutingModule,
        FormsModule,
        CommonModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        MatInputModule,
        MatFormFieldModule,
        MatButtonModule,
        MatCardModule,
        MatIconModule,
        TranslateModule
	],
	exports: [
		FormsModule,
		ReactiveFormsModule
	]
})

export class LmTransferSepaModule { }
