import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { Utilities } from 'src/app/shared/utilities/utilities';
import { BillingService } from "src/app/pages/lm-transfers/services/billing.service";
import { OperationService } from "src/app/pages/lm-transfers/services/operation.service";
import { AuthenticationService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  loading: boolean = true;
  isUserLeroy: boolean;


  page = 0;
  pageSize = 500;

  data: any = [];
  opError: boolean = false;
  opMessage: string = '';
  loadingOp: boolean = false;
  totalElements: number = 0;
  billingOperationsByStatus: any = [];
  billingOperationsByService: any = [];

  loadingApp: boolean = false;
  appointmentsData: any[];
  totalAppointmentsElements: number = 0;
  appointmentsByStatus: any = [];
  appointmentsByService: any = [];

  displayedColumnsAppointmentsTable: string[] = ['id', 'operationStatus', 'totalCost', 'operationOfferedServices'];
  displayedColumnsOperationsTable: string[] = ['id', 'totalPaid', 'operationOfferedServices'];
  displayedColumnsBOS: string[] = ['status', 'count', 'value'];
  displayedColumnsBOSer: string[] = ['service', 'count', 'value'];
  displayedColumnsAppS: string[] = ['status', 'count', 'value'];
  displayedColumnsAppSer: string[] = ['service', 'count', 'value'];



  /** Based on the screen size, switch from standard to one column per row */
  cardLayout = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(({ matches }) => {
      if (matches) {
        return {
          columns: 1,
          miniCard: { cols: 1, rows: 1 },
          chart: { cols: 1, rows: 2 },
          table: { cols: 1, rows: 3 },
        };
      }

      return {
        columns: 4,
        miniCard: { cols: 1, rows: 1 },
        chart: { cols: 2, rows: 2 },
        table: { cols: 4, rows: 3 },
      };
    })
  );


  constructor(
    private breakpointObserver: BreakpointObserver,
    private billingService: BillingService,
    private operationService: OperationService,
    private _auth: AuthenticationService
  ) { }

  ngOnInit() {
    if (this._auth.isSessionExpired()) {
      this._auth.setSessionExpire('true');
    }
    this.getOperations();
    this.getAppointments();
    this.isUserLeroy = Utilities.isUserHogami();

    if (this.isUserLeroy) {
      this.displayedColumnsAppointmentsTable = ['id', 'operationStatus', 'totalCost', 'operationOfferedServices'];
      this.displayedColumnsOperationsTable = ['id', 'totalPaid', 'operationOfferedServices'];
      this.displayedColumnsBOS = ['status', 'count', 'value'];
      this.displayedColumnsBOSer = ['service', 'count', 'value'];
      this.displayedColumnsAppS = ['status', 'count', 'value'];
      this.displayedColumnsAppSer = ['service', 'count', 'value'];
    } else {
      this.displayedColumnsAppointmentsTable = ['id', 'operationStatus', 'operationOfferedServices'];
      this.displayedColumnsOperationsTable = ['id', 'operationOfferedServices'];
      this.displayedColumnsBOS = ['status', 'count'];
      this.displayedColumnsBOSer = ['service', 'count'];
      this.displayedColumnsAppS = ['status', 'count'];
      this.displayedColumnsAppSer = ['service', 'count'];
    }

  }


  /* Get operations closed yesterday */
  getOperations() {
    this.loadingOp = true;
    this.data = []
    this.totalElements = 0;
    let page = 0;
    let pageSize = 500;
    let filters = {}

    let yesterdayDate = new Date(new Date().setHours(0, 0, 0))
    yesterdayDate.setDate(yesterdayDate.getDate() - 1)
    let todayDate = new Date(new Date().setHours(0, 0, 0))

    filters = {
      startTimeStart: null,
      startTimeEnd: null,
      endTimeStart: Utilities.getDate(yesterdayDate.toString()),
      endTimeEnd: Utilities.getDate(todayDate.toString()),
      operationId: null,
      operationBillable: true,
      operationNoBillable: false,
      operationInternal: false,
      operationExternal: true
    }


    this.billingService.getOperations(page, pageSize, filters).subscribe(response => {
      const data = response.data
      this.loadingOp = false;
      if (data.totalElements === 0) {
        this.opError = true
        this.opMessage = 'No data'
      } else {
        this.opError = false
        this.data = data.content || []
        this.billingOperationsByStatus = this.groupBillingOperationsByStatus(this.data)
        this.billingOperationsByService = this.groupBillingOperationsByService(this.data)
        this.totalElements = data.totalElements
      }
    }, error => {
      this.loadingOp = false;
      this.opError = true
      this.opMessage = 'No data'
    })

  }
  getCountBOp() {
    return this.data.length;
  }

  getTotalCostBOp() {
    return this.data.map(t => t.paymentClient).reduce((acc, value) => acc + value, 0);
  }

  getAppointments() {
    this.loadingApp = true;
    this.appointmentsData = []
    this.totalAppointmentsElements = 0;

    let filters = {}

    let todayStart = new Date(new Date().setHours(0, 0, 0))
    todayStart.setDate(todayStart.getDate())
    let todayEnds = new Date(new Date().setHours(23, 59, 59))

    filters = {
      transferType: "op",
      client: null,
      concatenatedProvince: null,
      startDateEndTime: null,
      endDateEndTime: null,
      startDateStartTime: Utilities.getDate(todayStart.toString()),
      endDateStartTime: Utilities.getDate(todayEnds.toString()),
      id: 0,
      jobsStatus: [],
      operationDateEndTime: null,
      operationDateStartTime: null,
      professional: null,
      province: "",
      services: [],
      phoneClient: null,
      nameAndLastNameClient: null
    }


    this.operationService.getOperations(this.page, this.pageSize, filters).subscribe(response => {
      const data = response.data
      this.loadingApp = false;
      if (data.totalElements === 0) {
        this.opError = true
        this.opMessage = 'No data'
      } else {
        this.opError = false
        this.appointmentsData = data.content || []
        this.totalAppointmentsElements = data.totalElements
        this.appointmentsByStatus = this.groupAppointmentsByStatus(this.appointmentsData)
        this.appointmentsByService = this.groupAppointmentsByService(this.appointmentsData);
      }
    }, error => {
      this.loadingApp = false;
      this.opError = true
      this.opMessage = 'No data'
    })
  }

  getCountApp() {
    return this.appointmentsData.length;
  }

  getTotalCostApp() {
    return this.appointmentsData.map(t => t.totalCost).reduce((acc, value) => acc + value, 0);
  }


  getPaginatorData(event) {
    this.page = event['pageIndex'];
    this.pageSize = event['pageSize'];
    this.getAppointments();
  }


  groupAppointmentsByStatus(input: { totalCost: number, operationStatus: { description: string, propertyStatus: any } }[]) {
    const filteredInput = input.filter(it => it.totalCost);
    const byStatus = Utilities.groupBy(filteredInput, it => it.operationStatus.description);

    const result = Object.entries(byStatus).map(([name, items]: [string, { totalCost: number, operationStatus: { propertyStatus: any } }[]]) => {
      const count = items.length;
      const sum = items.reduce((acc, it) => acc + it.totalCost, 0);
      const propStatus = items.length > 0 ? items[0].operationStatus.propertyStatus : undefined;

      return {
        propertyStatus: propStatus,
        status: name,
        count,
        value: sum
      };
    });
    return result;
  }

  groupAppointmentsByService(input: { totalCost: number, operationOfferedServices: { title: string }[] }[]) {
    const filteredInput = input.filter(it => it.totalCost);
    const byService = Utilities.groupBy(filteredInput, it => it.operationOfferedServices[0].title);

    const result = Object.entries(byService).map(([name, items]: [string, { totalCost: number }[]]) => {
      const count = items.length;
      const sum = items.reduce((acc, it) => acc + it.totalCost, 0);

      return {
        service: name,
        count,
        value: sum
      };
    });

    return result;
  }

  groupBillingOperationsByStatus(input) {
    const filteredInput = input.filter(it => it.operationBilling);
    const byStatus = Utilities.groupBy(filteredInput, it => it.operationStatusRest.description);

    const result = Object.entries(byStatus).map(([name, items]: [string, { paymentClient: number, operationStatusRest: { propertyStatus: any } }[]]) => {
      const count = items.length;
      const sum = items.reduce((acc, it) => acc + it.paymentClient, 0);
      const propStatus = items.length > 0 ? items[0].operationStatusRest.propertyStatus : undefined;
      return {
        propertyStatus: propStatus,
        status: name,
        count: count,
        value: sum
      };
    });
    return result
  }

  groupBillingOperationsByService(input) {
    const byService = Utilities.groupBy(input.filter(it => it.operationBilling), it => it.offeredServiceOperationRest[0].title)
    const result = Object.keys(byService).map(name => {
      const count = byService[name].reduce((acc, it) => acc + 1, 0)
      const sum = byService[name].reduce((acc, it) => acc + it.paymentClient, 0)
      return {
        service: name,
        count: count,
        value: sum
      }
    })
    return result
  }

  getLocalNumber(number, decimals: number = 2) {
    return new Intl.NumberFormat('es-ES', {
      maximumFractionDigits: decimals,
      minimumFractionDigits: decimals
    }).format(number)
  }

}
