import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { LmOfferedServiceService } from '../../services/lm-offered-service.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-lm-delete-offered-service-modal',
  templateUrl: './lm-delete-offered-service-modal.component.html',
  styleUrls: ['./lm-delete-offered-service-modal.component.scss']
})
export class LmDeleteOfferedServiceModalComponent implements OnInit, OnDestroy {
  _translateSubscription: Subscription;
  fail: boolean = false;
  success: boolean = false;
  submitted: boolean = false
  _buttons_label: {[key: string]: string}; 
  constructor(
    public dialogRef: MatDialogRef<LmDeleteOfferedServiceModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private offeredSerService: LmOfferedServiceService,
    private _translateService: TranslateService
    ) { 
      this._translateSubscription = this._translateService.get("lm-offered-services.lm-delete-offered-service-modal.buttons")
        .subscribe((result: {[key: string]: string}) => {
          this._buttons_label = result;
        });
    }
  ngOnDestroy(): void {
    this._translateSubscription.unsubscribe();
  }

  ngOnInit() {

  }

  dismiss() {
    this.dialogRef.close(this.success);
  }

  submit() {
    this.submitted = true;
    this.offeredSerService.deleteVisibleContent(this.data.province.offeredServiceId, this.data.province.provinceId).then(res => {
      this.success = true;
    }).catch(err => {
      this.submitted = false;
      this.fail = true
    })
  }

}
