import { Subscription } from 'rxjs';
import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { MaintenanceService } from "../../../core/services/mant.service";
import { Utilities } from "../../../../app/shared/utilities/utilities";
import { get as _get } from "lodash";
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../../../core/services/auth.service';

@Component({
  selector: "app-lm-create-user",
  templateUrl: "./lm-create-user.component.html",
  styleUrls: ["./lm-create-user.component.scss"],
})
export class LmCreateUserComponent implements OnInit {
  _translateSubscription: Subscription;
  form: FormGroup;
  message: string;
  isError: boolean = false;
  isDisabled: boolean = false;
  submitted: boolean = false;
  isUserLeroy: boolean;
  _messages: { [key: string]: string };
  constructor(private _mant: MaintenanceService,
    private _router: Router,
    private _translateService: TranslateService,
    private _auth: AuthenticationService) {
    this._translateSubscription = this._translateService.get('lm-create-user.logic')
      .subscribe((res: { [key: string]: string }) => {
        this._messages = res;
      });
  }

  ngOnInit() {
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl(null, Validators.required),
    });
    this.isUserLeroy = Utilities.isUserHogami();

    this._auth.isNewTokenExpired();
  }

  get f() { return this.form.controls; }


  createUser(user) {
    this.isDisabled = true;
    this.message = "";
    this.submitted = true;
    this._mant.createNewUser(user.email, Utilities.encodeBase64(user.password)).subscribe({
      next: this.handleResponse.bind(this),
      error: this.handleError.bind(this)
    });
  }

  handleResponse(response: any): void {
    this.message = this._messages['user_created'];
    this.isError = false;
    this.isDisabled = false;
  }

  handleError(error: any): void {
    this.isDisabled = false;
    this.isError = true;
    this.message = this._messages['error_please_try_again'] + " (" + error.error + ")";
  }

  goBack() {
    this._router.navigate(["/home"]);
  }
}
