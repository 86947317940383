<app-lm-filters
  [dataSolic]="dataSolic"
  [datag]="datag"
  [transferType]="transferType"
  [operaciones]="operaciones"
  [solicitudes]="solicitudes"
  [loading]="loading"
  [loadExcel]="loadExcel"
  (filtersApply)="getFiltersToShow($event)"
  (filterstoExcel)="exportToExcel($event)">
</app-lm-filters>

<div class="content">
  <mat-progress-bar *ngIf="((!operaciones || !solicitudes) && loading ) || loadExcel" mode="indeterminate" value="140"></mat-progress-bar>
<!--  OPERATION RESULT TABLE -->
  <div class="example-container"  *ngIf="datag !== undefined && !solicitudes && !loading" >
    <mat-table  mat-table [dataSource]='datag'class="mat-elevation-z8" >
      <ng-container matColumnDef="id" sticky>
        <th mat-header-cell *matHeaderCellDef>ID</th>
        <td mat-cell *matCellDef="let element"><strong>Op: {{element.id}}</strong><br/>Sol: {{element.requestId}} </td>
      </ng-container>

      <ng-container matColumnDef="operationStatus" class="spacing">
        <th mat-header-cell *matHeaderCellDef>ESTADO</th>
        <td mat-cell *matCellDef="let element">
          <div class="status_name status_{{ element.operationStatus?.name | lowercase}}">{{element.operationStatus?.name}} </div>
          <div class="status_description">{{element.operationStatus?.description}}</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="operationOfferedServices" class="spacing">
        <th mat-header-cell *matHeaderCellDef>SERVICIOS</th>
        <td mat-cell *matCellDef="let element">
          <div *ngFor="let p of element.operationOfferedServices ; first as isFirst">
            <span *ngIf="isFirst">
                <div><span class="service_code">{{p?.code}}</span> : <span class="service_name">{{p?.title}}</span></div>
                <div class="service_price">Precio: {{p?.price}}  {{p?.currency}} (Margen: {{p?.profitMargin}} %)</div>
                <div class="service_tax">{{p?.taxDetails?.taxRate}}  {{p?.taxDetails?.taxType}}</div>
                <div class="service_date">Agregado el: {{p?.createDate}}</div>
            </span>
          </div>
          <div *ngIf="element.operationOfferedServices?.length > 1">
            <button mat-stroked-button (click)="showMore()">{{stringShowMore}}</button>
            <div  *ngIf="buttonShowMore">
              <div *ngFor="let p of element.operationOfferedServices | slice:1; let i = index;" class="upperline">
                <div><span class="service_code">{{p['code']}}</span> : <span class="service_name">{{p['title']}}</span></div>
                <div class="service_price">Precio: {{p['price']}}  {{p['currency']}} (Margen: {{p['profitMargin']}} %)</div>
                <div class="service_tax">{{p['taxDetails']['taxRate']}}  {{p['taxDetails']['taxType']}}</div>
                <div class="service_date">Agregado el: {{p['createDate']}}</div>
              </div>
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="clientDetails" class="spacing">
        <th mat-header-cell *matHeaderCellDef>CLIENTE</th>
        <td mat-cell *matCellDef="let element">
          <div class="client_name">{{element.clientDetails?.name}} {{element.clientDetails?.lastName}}</div>
          <div class="client_email">{{element.clientDetails?.email}}</div>
          <div>Tel:
            <span *ngFor="let p of element.clientDetails.phones">
              <span>{{p?.number}} </span>
            </span>
          </div>
          <div>Datos de Facturación</div>
          <div>Documento: {{element.billingData?.documentNumber}}</div>
          <div>Nombre: {{element.billingData?.name}} {{element.billingData?.lastName}}</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="startTime" class="spacing">
        <th mat-header-cell *matHeaderCellDef>FECHA CITA</th>
        <td mat-cell *matCellDef="let element"> {{element.startTime | date:'fullDate'}},{{element.startTime | date:'shortTime'}} </td>
      </ng-container>

      <ng-container matColumnDef="endTime" class="spacing">
        <th mat-header-cell *matHeaderCellDef>FECHA FINAL</th>
        <td mat-cell *matCellDef="let element"> {{element.endTime | date:'fullDate'}},{{element.endTime | date:'shortTime'}} </td>
      </ng-container>

      <ng-container matColumnDef="professionalDetails" class="spacing">
        <th mat-header-cell *matHeaderCellDef>PROFESIONAL</th>
        <td mat-cell *matCellDef="let element">
          <div>
            <span class="professional_name">{{element.professionalDetails?.name}} {{element.professionalDetails?.lastName}}</span>
            <span *ngIf="element.professionalDetails?.available"  matTooltipPosition="right" matTooltip="Activo" >
              <i  class="material-icons">visibility</i>
            </span>
            <span *ngIf="!element.professionalDetails?.available" matTooltipPosition="right" matTooltip="Inactivo" >
              <i  class="material-icons">visibility_off</i>
            </span>
          </div>
          <div class="professional_email">{{element.professionalDetails?.email}}</div>
          <div class="professional_document">{{element.professionalDetails?.document}}</div>
          <div class="professional_mora_info"><button mat-stroked-button (click)="showAdditionalData( element.professionalId )">Ver más info.</button></div>
        </td>
      </ng-container>

      <ng-container matColumnDef="totalCost" class="spacing">
        <th mat-header-cell *matHeaderCellDef>COSTE TOTAL</th>
        <td mat-cell *matCellDef="let element"> {{element.totalCost}} {{element.currency}} </td>
      </ng-container>

      <ng-container matColumnDef="totalPaid" class="spacing">
        <th mat-header-cell *matHeaderCellDef>TOTAL PAGADO</th>
        <td mat-cell *matCellDef="let element"> {{element.totalPaid}} {{element.currency}} </td>
      </ng-container>

      <ng-container matColumnDef="operationAddress" class="spacing">
        <th mat-header-cell *matHeaderCellDef>DIRECCIÓN DEL SERVICIO</th>
        <td mat-cell *matCellDef="let element">
          <div>{{element.operationAddress?.address}}</div>
          <div>{{element.operationAddress?.complementAddress}}</div>
          <div>{{element.operationAddress?.zipCode}}</div>
          <div *ngIf="element.operationAddress?.city">{{element.operationAddress?.city}}</div>
          <div *ngIf="element.operationAddress?.province">{{element.operationAddress?.province}}</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="products">
        <th mat-header-cell *matHeaderCellDef>PRODUCTOS</th>
        <td mat-cell *matCellDef="let element">
          <div *ngFor="let p of element.products ; first as isFirst">
          <span *ngIf="isFirst">
              <div><span class="service_name">{{p?.productDescription}}</span></div>
              <div class="service_price">Precio: {{p?.price}}  {{p?.currency}}</div>
              <div class="service_tax">{{p?.taxDetails?.taxRate}}  {{p?.taxDetails?.taxType}}</div>
              <div class="service_date">Agregado el: {{p?.createDate}}</div>
          </span>
          </div>
          <div *ngIf="element.products?.length > 1">
            <button mat-stroked-button (click)="showMore()">{{stringShowMore}}</button>
            <div  *ngIf="buttonShowMore">
              <div *ngFor="let p of element.products | slice:1; let i = index;" class="upperline">
                <div><span class="service_name">{{p['productDescription']}}</span></div>
                <div class="service_price">Precio: {{p['price']}}  {{p['currency']}}</div>
                <div class="service_tax">{{p['taxDetails']['taxRate']}}  {{p['taxDetails']['taxType']}}</div>
                <div class="service_date">Agregado el: {{p['createDate']}}</div>
              </div>
            </div>
          </div>
        </td>
      </ng-container>


      <ng-container matColumnDef="images" class="spacing">
        <th mat-header-cell *matHeaderCellDef>IMAGENES</th>
        <td mat-cell *matCellDef="let element">
          <div *ngIf="element.images.length > 0">
            <label>Al iniciar trabajo:</label>
            <div *ngFor="let p of element.images">
              <div *ngIf="p.type == 'START' " (click)="openImagesDialog(p.url, p.type)" ><mat-icon>broken_image</mat-icon></div>
            </div>
          </div>
          <div *ngIf="element.images.length > 0">
            <label>Al finaliza trabajo:</label>
            <div *ngFor="let p of element.images">
              <div *ngIf="p.type == 'END' " (click)="openImagesDialog(p.url, p.type)" ><mat-icon>image</mat-icon></div>
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="totalDuration" class="spacing">
        <th mat-header-cell *matHeaderCellDef>DURACIÓN</th>
        <td mat-cell *matCellDef="let element"> {{element.totalDuration}} </td>
      </ng-container>

      <ng-container matColumnDef="rejectionMessage" class="spacing">
        <th mat-header-cell *matHeaderCellDef>RECHAZADO POR</th>
        <td mat-cell *matCellDef="let element"> {{element.rejectionMessage}} </td>
      </ng-container>

      <ng-container matColumnDef="unit" class="spacing">
        <th mat-header-cell *matHeaderCellDef>UNIDAD</th>
        <td mat-cell *matCellDef="let element"> {{element.unit}} </td>
      </ng-container>

      <ng-container matColumnDef="fill">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef></td>
      </ng-container>

      <ng-container matColumnDef="actions" class="spacing" stickyEnd>
        <th mat-header-cell *matHeaderCellDef>ACCIONES</th>
        <td mat-cell *matCellDef="let element">
          <button mat-stroked-button title="Chat" (click)="openDialog(element.chatId, element.professionalDetails, element.clientDetails)">
              <mat-icon>question_answer</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" ></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </mat-table>
    <div class="mat-more-container" *ngIf="datag !== undefined && operaciones && !solicitudes && !loading && !isFiltered">
      <button mat-raised-button [disabled]="loading" (click)="getOperations()">Ver más operaciones</button>
    </div>
    <div class="mat-more-container" *ngIf="datag !== undefined && operaciones && !solicitudes && !loading && isFiltered">
      <button mat-raised-button [disabled]="loading" (click)="paginationToFiltersAplly()">Ver más operaciones</button>
    </div>
  </div>

<!--  REQUEST RESULT TABLE -->
  <div class="example-container" *ngIf="dataSolic !== undefined && !operaciones && !loading">
    <mat-table  mat-table [dataSource]='dataSolic'  class="mat-elevation-z8">

      <ng-container matColumnDef="id" sticky>
        <th mat-header-cell *matHeaderCellDef>ID</th>
        <td mat-cell *matCellDef="let element"><strong>Sol: {{element.id}}</strong></td>
      </ng-container>

      <ng-container matColumnDef="requestStatus" class="spacing">
        <th mat-header-cell *matHeaderCellDef>ESTADO</th>
        <td mat-cell *matCellDef="let element">
          <div class="status_name status_{{ element.operationStatus?.name | lowercase}}">{{element.requestStatus}} </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="offeredService" class="spacing">
        <th mat-header-cell *matHeaderCellDef>SERVICIO</th>
        <td mat-cell *matCellDef="let element">
          <div><span class="service_code">{{element.offeredService?.code}}</span> : <span class="service_name">{{element.offeredService?.title}}</span></div>
          <div class="service_price">Precio: {{element.offeredService?.price}}  {{element.offeredService?.currency}} (Margen: {{element?.profitMargin}} %)</div>
          <div class="service_tax">IVA: {{element.offeredService?.taxDetails?.taxType}}  {{element.offeredService?.taxDetails?.taxRate}}</div>
          <div class="service_duration">Duración: {{element.offeredService?.duration}}</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="clientDetails" class="spacing">
        <th mat-header-cell *matHeaderCellDef>CLIENTE</th>
        <td mat-cell *matCellDef="let element">
          <div class="client_name">{{element.clientDetails?.name}} {{element.clientDetails?.lastName}}</div>
          <div class="client_email">{{element.clientDetails?.email}}</div>
          <div>Tel:
            <span *ngFor="let p of element.clientDetails.phones">
            <span>{{p?.number}} </span>
          </span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="createDate" class="spacing">
        <th mat-header-cell *matHeaderCellDef>FECHA CREACIÓN</th>
        <td mat-cell *matCellDef="let element"> {{element.createDate | date:'fullDate': undefined : 'en-EN'}},{{element.createDate | date:'shortTime'}} </td>
      </ng-container>

      <ng-container matColumnDef="operationDate" class="spacing">
        <th mat-header-cell *matHeaderCellDef>FECHA CITA</th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="element.operationDate" >{{element.operationDate | date:'fullDate': undefined : 'en-EN'}},{{element.operationDate | date:'shortTime'}}</span>
          <span *ngIf="!element.operationDate">-</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="endDate" class="spacing">
        <th mat-header-cell *matHeaderCellDef>FECHA FINAL</th>
        <td mat-cell *matCellDef="let element"> {{element.endDate | date:'fullDate': undefined : 'en-EN'}},{{element.endDate | date:'shortTime'}} </td>
      </ng-container>


      <ng-container matColumnDef="professionalDetails" class="spacing">
        <th mat-header-cell *matHeaderCellDef>PROFESIONAL</th>
        <td mat-cell *matCellDef="let element">
          <div>
            <span class="professional_name">{{element.professionalDetails?.name}} {{element.professionalDetails?.lastName}}</span>
            <span *ngIf="element.professionalDetails?.available"  matTooltipPosition="right" matTooltip="Activo" >
              <i  class="material-icons">visibility</i>
            </span>
            <span *ngIf="!element.professionalDetails?.available" matTooltipPosition="right" matTooltip="Inactivo" >
              <i  class="material-icons">visibility_off</i>
            </span>
          </div>
          <div class="professional_email">{{element.professionalDetails?.email}}</div>
          <div class="professional_document">{{element.professionalDetails?.document}}</div>
          <div class="professional_mora_info"><button mat-stroked-button (click)="showAdditionalData( element.professionalId )">Ver más info.</button></div>
        </td>
      </ng-container>

      <ng-container matColumnDef="requestAddress" class="spacing">
        <th mat-header-cell *matHeaderCellDef>DIRECCIÓN DEL SERVICIO</th>
        <td mat-cell *matCellDef="let element">
          <div>{{element.operationAddress?.address}}</div>
          <div>{{element.operationAddress?.complementAddress}}</div>
          <div>{{element.operationAddress?.zipCode}}</div>
          <div *ngIf="element.operationAddress?.city">{{element.operationAddress?.city}}</div>
          <div *ngIf="element.operationAddress?.province">{{element.operationAddress?.province}}</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="fill">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef></td>
      </ng-container>

      <ng-container matColumnDef="actionss" class="spacing" stickyEnd>
        <th mat-header-cell *matHeaderCellDef>ACCIONES</th>
        <td mat-cell *matCellDef="let element">
          <button mat-stroked-button title="Ver Chat" (click)="openDialog(element.chatId, element.professionalDetails, element.clientDetails)">
            <mat-icon>question_answer</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedSolColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedSolColumns;"></tr>
    </mat-table>
    <div class="mat-more-container" *ngIf="dataSolic !== undefined && solicitudes && !operaciones && !loading && !isFiltered" (click)="getRequest()">
      <button mat-raised-button [disabled]="loading">Ver más solicitudes</button>
    </div>
    <div class="mat-more-container" *ngIf="dataSolic !== undefined && solicitudes && !operaciones && !loading && isFiltered" (click)="paginationToFiltersAplly()">
      <button mat-raised-button [disabled]="loading">Ver más solicitudes</button>
    </div>
  </div>

  <!--  PROFESSIONAL DATA  -->
<div *ngIf="dataProfessional !== undefined">
  <table mat-table [dataSource]='dataProfessional' class="mat-elevation-z8">
  <ng-container matColumnDef="additionalInfo" class="spacing">
    <th mat-header-cell *matHeaderCellDef>INFORMACIÓN DEL PROFESIONAL</th>
    <td mat-cell *matCellDef="let element">
      <mat-card class="card">
        <mat-card-header>
          <div mat-card-avatar class="professional_image"><img mat-card-image src="{{element.avatar}}" alt=""></div>
          <mat-card-title>{{element.name}} {{element.lastName}}</mat-card-title>
          <mat-card-subtitle>{{element.email}}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>

          <div>document: {{element.additionalInfo?.document}}</div>
          <div>
            <span *ngIf="element.additionalInfo?.active"  matTooltipPosition="right" matTooltip="Activo" >
              <i  class="material-icons">visibility</i>
            </span>
                  <span *ngIf="!element.additionalInfo?.active" matTooltipPosition="right" matTooltip="Inactivo" >
              <i  class="material-icons">visibility_off</i>
            </span>
            <span class=""> Visible / No Visible</span>
          </div>

          <div>
            <span *ngIf="element.additionalInfo?.isActive"  matTooltipPosition="right" matTooltip="Activo" >
              <i  class="material-icons">visibility</i>
            </span>
            <span *ngIf="!element.additionalInfo?.isActive" matTooltipPosition="right" matTooltip="Inactivo" >
              <i  class="material-icons">visibility_off</i>
            </span>
            <span class=""> ¿Esta Activo?</span>
          </div>

          <div>
            <span *ngIf="element.additionalInfo?.acceptTerms"  matTooltipPosition="right" matTooltip="Activo" >
              <i  class="material-icons">check_circle</i>
            </span>
            <span *ngIf="!element.additionalInfo?.acceptTerms" matTooltipPosition="right" matTooltip="Inactivo" >
              <i  class="material-icons">unpublished</i>
            </span>
            <span class=""> Aceptado Terminos y Condiciones</span>
          </div>
          <div>

          <h3>Sobre la empresa</h3>
            <div><strong>CIF:</strong> {{element.additionalInfo?.companyCif}}</div>
            <div><strong>Razón Social:</strong> {{element.additionalInfo?.socialReasonName}}</div>
            <div><strong>IBAN:</strong> {{element.additionalInfo?.iban}}</div>
            <div><strong>Descripción:</strong> {{element.additionalInfo?.professionalDescription}}</div>
            <div><strong>Slogan:</strong> {{element.additionalInfo?.slogan}}</div>
          </div>

          <div>
            <h3 style="cursor: default;">Teléfonos</h3>
            <div>{{element.additionalInfo?.phoneNumber}}</div>
            <div>{{element.additionalInfo?.phoneNumberSecondary}}</div>
          </div>

          <div>
            <h3 style="cursor: default;">Dirección Postal</h3>
            <div>{{element.additionalInfo?.addressPostal}}</div>
            <div>{{element.additionalInfo?.complementAddressPostal}}</div>
            <div>{{element.additionalInfo?.cityPostal}}</div>
            <div>{{element.additionalInfo?.provincePostal}}</div>
            <div>{{element.additionalInfo?.country}}</div>
          </div>

          <div>
            <h3 style="cursor: default;">Dirección fiscal</h3>
            <div>{{element.additionalInfo?.addressFiscal}}</div>
            <div>{{element.additionalInfo?.complementAddressFiscal}}</div>
            <div>{{element.additionalInfo?.zipCodeFiscal}}</div>
            <div>{{element.additionalInfo?.cityFiscal}}</div>
            <div>{{element.additionalInfo?.provinceFiscal}}</div>
            <div>{{element.additionalInfo?.country}}</div>
          </div>

          <div>
          <h3>Documentación</h3>
            <div><strong>Copia del DNI:</strong> {{element.additionalInfo?.dniPhotocopy}}</div>
            <div><strong>Certificado de riesgos laborales:</strong> {{element.additionalInfo?.certificateLaboralRisks}}</div>
            <div><strong>Certificado de propiedad:</strong> {{element.additionalInfo?.certificateOwnership}}</div>
            <div><strong>Responsabilidad Civil:</strong> {{element.additionalInfo?.civilResponsibilityName}}</div>
            <div><strong>Constitución de la Sociedad:</strong> {{element.additionalInfo?.docConstitutionSociety}}</div>
            <div><strong>Ultimo Pago de Autónomo:</strong> {{element.additionalInfo?.lastAutonomousPayment}}</div>
          </div>

        </mat-card-content>
        <mat-card-actions>
        </mat-card-actions>
      </mat-card>

    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedProfessionalColums"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedProfessionalColums;"></tr>
</table>
</div>
</div>

<div class="content" *ngIf="dataProfessional == undefined && datag == undefined && dataSolic == undefined && !loading" >
  <iframe width="100%" height="600px" src="https://storage.googleapis.com/proimagesingapur/backoffice/home_iframe.html" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
</div>
