import { Component, OnInit } from '@angular/core';
import { get as _get } from 'lodash';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

}
